import React, {useState, useEffect} from "react";
import DashboardAdmin from "../DashboardAdmin";
import {Backdrop, CircularProgress} from "@material-ui/core";
import UserIcon from "../../../images/user-icon.svg";
import {makeStyles} from "@material-ui/core/styles";
import PatientEdition from "../../patients/PatientEdition";


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export default function ManagePatientProfile()  {

    const [open, setOpen] = useState(false);
    const classes = useStyles();

    //Used by Backdrop to unlock the screen
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <DashboardAdmin
            sectionTitle="Manage Patient (Edition)"
            description="Edit an existing Patient, Modify the profile"
            icon={UserIcon}
        >
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <>
                <PatientEdition source={"ADMIN"}/>
            </>
        </DashboardAdmin>
    );
}