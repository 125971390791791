import React,{useEffect,useMemo,useState} from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Link} from '@material-ui/core';
import Paper from "@material-ui/core/Paper";
import PaymentIcon from "@material-ui/icons/Payment";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory, useLocation} from "react-router-dom";
import Axios from "axios";
import UseUserState from "../hooks/useUserState";
import CheckoutStripeWrapper from "../payments/CheckoutStripeWrapper";
import PaypalButton from "../payments/PaypalButton";
import {handlePaymentSuccess} from "../../services/paymentService";

const useStyles = makeStyles((theme) => ({
    paper:{
        marginTop:theme.spacing(8),
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        padding:"30px",
        backgroundColor:"white",
        boxShadow:"5px 5px 16px 1px #1b3342",
        borderRadius:"10px",
        maxWidth:600,
    },
    avatar:{
        margin:theme.spacing(1),
        backgroundColor:theme.palette.primary.main,
    },
    form:{
        width:'100%', // Fix IE 11 issue.
        marginTop:theme.spacing(1),
    },
    submit:{
        margin:theme.spacing(3,0,2),
    },
}));

const PaymentCheckoutForm = () => {
    const classes = useStyles();

    const [cardNumber,setCardNumber] = useState('');
    const [expiryDate,setExpiryDate] = useState('');
    const [cvv,setCVV] = useState('');
    const [nameOnCard,setNameOnCard] = useState('');
    const history = useHistory();

    const [clientSecret, setClientSecret] = useState(null)
    const [open, setOpen] = useState(false)
    const memClientSecret = useMemo(() => clientSecret, [clientSecret])
    const userState = UseUserState();

    const {id, price, title} = useLocation().state;
    const handlePayment = (e) => {
        e.preventDefault();
        history.push('/patient/registration/includeFamilyMember');
    };

    const createPaymentIntent = async () => {

        try {
            const createIntentResponse = await Axios.post(
                `${process.env.REACT_APP_API_URL_BASE}/payments/create-payment-intent`,
                {
                    "items": [
                        {
                            // "id": appointmentId.toString(),
                            "amount": price * 100 // for $14 => 1400
                        }
                    ]
                },
                {
                    headers: {Authorization: "Bearer " + userState[0].jwt},
                }
            )

            console.log("createIntentResponse", createIntentResponse)

            return createIntentResponse.data

        } catch (e) {
            console.log("Create Intent Error", e)
        }
    }


    // useEffect(() => {
    //     setOpen(true);
    //     if(memClientSecret !== null){
    //         setOpen(false);
    //         return
    //     }
    //     createPaymentIntent()
    //         .then(res => setClientSecret(res.clientSecret))
    //         .catch(errors => {
    //             setOpen(false)
    //             console.log("createIntentErr", errors)
    //         })
    //
    //     setOpen(false);
    //
    // }, []);

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline/>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <Paper className={classes.paper}>
                    <Avatar
                        // src={PaymentImage}
                        alt="Payment" className={classes.avatar}>
                        <PaymentIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        <strong>Payment Checkout</strong>
                    </Typography>
                    <form className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="cardNumber"
                                    label="Card Number"
                                    name="cardNumber"
                                    autoComplete="cc-number"
                                    onChange={(e) => setCardNumber(e.target.value)}
                                />
                            </Grid>
                            <CheckoutStripeWrapper clientSecret={memClientSecret}/>
                            {/*<Grid item xs={6}>*/}
                            {/*    <TextField*/}
                            {/*        variant="outlined"*/}
                            {/*        required*/}
                            {/*        fullWidth*/}
                            {/*        id="expiryDate"*/}
                            {/*        label="Expiry Date"*/}
                            {/*        name="expiryDate"*/}
                            {/*        autoComplete="cc-exp"*/}
                            {/*        onChange={(e) => setExpiryDate(e.target.value)}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={6}>*/}
                            {/*    <TextField*/}
                            {/*        variant="outlined"*/}
                            {/*        required*/}
                            {/*        fullWidth*/}
                            {/*        id="cvv"*/}
                            {/*        label="CVV"*/}
                            {/*        name="cvv"*/}
                            {/*        autoComplete="cc-csc"*/}
                            {/*        onChange={(e) => setCVV(e.target.value)}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={12}>*/}
                            {/*    <TextField*/}
                            {/*        variant="outlined"*/}
                            {/*        required*/}
                            {/*        fullWidth*/}
                            {/*        id="nameOnCard"*/}
                            {/*        label="Name on Card"*/}
                            {/*        name="nameOnCard"*/}
                            {/*        autoComplete="cc-name"*/}
                            {/*        onChange={(e) => setNameOnCard(e.target.value)}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="title"
                                    label="Title"
                                    name="title"
                                    autoComplete="title"
                                    value={title}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="price"
                                    label="Price"
                                    name="price"
                                    autoComplete="price"
                                    value={`$${price}`}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid className="d-flex" item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox color="primary"/>}
                                    label="Save card for future payments"
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handlePayment}
                        >
                            Pay Now
                        </Button>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link href="#" variant="body2">
                                    Cancel Payment
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Box>
            <Box mt={5}>{/* Add any additional components or text here */}</Box>
        </Container>
    );
};

export default PaymentCheckoutForm;
