import React, { useState, useEffect } from "react";
import UserIcon from "../../../images/user-icon.svg";
import TextField from "@material-ui/core/TextField";
import {
  Backdrop,
  Button,
  CircularProgress,
  MenuItem,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useHistory, useLocation } from "react-router-dom";
import Axios from "axios";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import UseUserState from "../../hooks/useUserState";
import DashboardAdmin from "../../administrator/DashboardAdmin";
import { Switch, FormControlLabel, FormHelperText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function UserEdition(props) {
  const localState = UseUserState();
  let history = useHistory();
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newRoleCode, setNewRoleCode] = useState("");
  const [roleCollections, setRoleCollections] = useState([]);
  const [disableSave, setDisableSave] = useState(false);
  const [checked, setChecked] = useState(false);
  const [roleName, setRoleName] = useState("");

  //State that is received by react dom for the push method
  const location = useLocation();

  const { email, status_user_id, rol_id, id } = location.state;

  const handleBack = () => {
    history.goBack();
  };

  //Used by Backdrop to unlock the screen
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(true);

    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/role`, {
      headers: {
        Authorization: "Bearer " + localState[0].jwt, //the jwt is a variable which holds the token
      },
    })
      .then((res) => {
        setOpen(false);
        setRoleCollections(res.data);
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
      });
  }, []);

  const handleUpdateRole = () => {
    if (newRoleCode === "") {
      swal("Alert!", "Please Select new Role", "warning");
      return false;
    }
    setLoading(true);
    const params = {
      rol_id: newRoleCode,
      user_id: id,
    };
    Axios.put(
      `${process.env.REACT_APP_API_URL_BASE}/users/changeRole`,
      params,
      {
        headers: {
          Authorization: "Bearer " + localState[0].jwt, //the jwt is a variable which holds the token
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setDisableSave(true);
        swal("Success!", "Role updated!", "success");
      })
      .catch((err) => console.log(err));
  };

  const translateRole = (idRole) => {
    switch (idRole) {
      case 1:
        return "Administrator";
      case 2:
        return "Patient";
      case 3:
        return "Healthcare Provider";
      case 4:
        return "Nurse";
      case 5:
        return "Broker";
      default:
        return "User do not have role assigned";
    }
  };

  useEffect(() => {
    if (status_user_id === 1) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, []);

  const handleChangeStatus = async (event) => {
    const statusSelected = event.target.checked;
    const parseStatus = statusSelected ? "1" : "2";

    try {
      setOpen(true);
      const response = await Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/users/changeStatus`,
        {
          email,
          status_user_id: parseStatus,
          rol_id: rol_id.toString(),
        },
        {
          headers: {
            Authorization: "Bearer " + localState[0].jwt, //the jwt is a variable which holds the token
          },
        }
      );
      setOpen(false);
      setChecked(statusSelected);
      swal("Success!", "Result: " + response.data.message, "success");
    } catch (error) {
      swal("Atention!", "Error: " + error, "error");
    }
  };

  return (
    <DashboardAdmin
      sectionTitle="Manage User (Edition)"
      description="Edit an existing user, set a Role or disable access"
      icon={UserIcon}
    >
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="shadow-sm mb-5 bg-white rounded mt-3">
        <div className="card-header">User Edition</div>

        <div className="container-fluid d-flex flex-column justify-content-center">
          <div className="form-group col-md-6">
            <TextField
              margin="normal"
              label="User Email"
              variant="outlined"
              id="user-email"
              fullWidth
              disabled
              value={email || ""}
            />
          </div>

          <div className="form-group col-md-6">
            <TextField
              margin="normal"
              label="User Role"
              variant="outlined"
              id="user-role"
              fullWidth
              disabled
              value={translateRole(rol_id)}
            />
          </div>
          {/* Section for Field edition */}
          <div className="form-group col-md-6">
            <TextField
              required
              fullWidth
              select
              margin="normal"
              label="New Role"
              variant="outlined"
              placeholder="Select new Role"
              id="new-role"
              onChange={(e) => setNewRoleCode(e.target.value)}
            >
              {roleCollections.length > 0 &&
                roleCollections.map((role) => {
                  return (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  );
                })}
              {roleCollections.length === 0 && (
                <MenuItem value="">{"No data available"}</MenuItem>
              )}
            </TextField>
          </div>
          <div className="form-group col-md-6">
            <FormControlLabel
              label="Enabled"
              control={
                <Switch
                  checked={checked}
                  onChange={handleChangeStatus}
                  inputProps={{ "aria-label": "controlled" }}
                  name="User Status"
                />
              }
            />
            <FormHelperText>
              Be careful, this component change between status automatically
            </FormHelperText>
          </div>
          {/* Buttons */}
          <div className="form-group col-md-10">
            <Button variant="contained" onClick={handleBack} className="m-1">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="m-1"
              startIcon={<SaveIcon />}
              onClick={handleUpdateRole}
              disabled={disableSave}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </DashboardAdmin>
  );
}
