import React, { useState, useEffect } from "react";
import DashboardPatient from "../dashboard/DashboardPatient";
import ProviderCard from "../widgets/w-providers/ProviderCard";
import SearchModule from "./SearchModule";
import SunshineSVG from "../../images/sunshine.svg";
import Axios from "axios";
import { useSelector } from "react-redux";
import Loading from "../utils/Loading";
import { resizeByLink } from "./../utils/ResizeLinkCloudinary";
import swal from "sweetalert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import useUserState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";
import {Box} from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function MainPatient() {
  const [providers, setProviders] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [specialties, setSpecialties] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const userLocalState = useUserState();
  const jwtSession = userLocalState[0].jwt;

  const { t, changeDirectLanguage } = useBDocTranslator();

  //this method manage the backdrop status
  const handleClose = () => {
    setOpen(false);
  };



  //Method for search all providers
  const fetchAllProviders = () => {
    try {
      setOpen(true);
      Axios.get(`${process.env.REACT_APP_API_URL_BASE}/provider/enabled`, {
        headers: { Authorization: "Bearer " + jwtSession }, //the jwt is a variable which holds the token
      })
        .then((response) => {
          setOpen(false);
          setLoadingData(false);
          setProviders(response.data);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  //Effect to fetch providers
  useEffect(() => {
    fetchAllProviders();
    changeDirectLanguage(userLocalState[0]?.language || "en");
  }, []);

  //Effect to fetch Specialty data
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/specialty/findAll`, {
      headers: { Authorization: "Bearer " + jwtSession }, //the jwt is a variable which holds the token
    })
      .then((response) => {
        setLoadingData(false);
        setSpecialties(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSearch = (country, specialty, provider) => {
    const countryParam = country?.code;
    const specialtyParam = specialty?.speciality_name;
    const providerParam = provider?.firstName; // consider the lastname as well

    if (!countryParam && !specialtyParam && !providerParam) {
      fetchAllProviders();
    } else {
      setOpen(true);

      Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/customSearch/provider?country=${
          countryParam || ""
        }&specialty=${specialtyParam || ""}&providerName=${
          providerParam || ""
        }`,
        {
          headers: { Authorization: "Bearer " + jwtSession }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          setOpen(false);

          setProviders(response.data);
        })
        .catch((err) => {
          console.error(err);
          setOpen(false);

          swal({
            title: "Failed!",
            text: "Error fetching Data for Selected Country",
            icon: "error",
            button: false,
          });
        });
    }
  };

  const parseSpecialty = (toParse) => {
    const specialtyArr = toParse.specialty && toParse.specialty.split(",");
    if (specialtyArr && specialtyArr.length > 0) {
      const result = specialtyArr.map((item) => {
        if (item.length > 2) {
          return item;
        }
      });
      return result;
    }
  };

  return (
    <DashboardPatient>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="d-flex flex-column">
          {/*make like a banner with box */}
        <Paper elevation={1} style={{  padding: '1rem', borderRadius: '1px', width: '100%', marginBottom: '1rem' }} >
          <Box>
            <Typography variant="h4" component="h1" style={{ fontWeight: 'bold' }}>
              {t("welcome.to.your.dashboard")}!
              <Box component="span" style={{ marginLeft: '0.5rem' }}>
                <img src={SunshineSVG} alt="sunshine" style={{ verticalAlign: 'middle' }} sizes={'2'} width={'32'} height={'32'}/>
              </Box>
            </Typography>
            <Typography variant="body1">
              {t("welcome.dashboard.message")}
            </Typography>
          </Box>
        </Paper>
        <div>
          <SearchModule
            handleSearch={handleSearch}
            specialties={specialties}
            providers={providers}
            language={userLocalState[0]?.language || "en"}
          />
        </div>
        <div>
          {loadingData && <Loading color="#3788d8d4" />}
          {providers &&
            providers.map((item) => {
              if (item.status_provider.status_name === "APPROVED") {
                const fullname = `${item.firstName} ${item.lastname}`;
                let profileImageURL;

                if (item.user?.user_images?.length > 0) {
                  profileImageURL = resizeByLink(
                    item.user.user_images.at(-1).path_resource,
                    "upload"
                  );
                }

                const specialitiesParsed = Array.of(
                  item.medical_speciality?.speciality_name
                );
                return (
                  <ProviderCard
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    fullname={fullname}
                    profileImage={profileImageURL}
                    specialty={specialitiesParsed}
                    providerId={item.id}
                    country={item.country}
                    providerImg={item.profile_image}
                    summary={item.summary}
                    providerUserId={item?.user?.id}
                    rating={item.rating_avg}
                    available={item?.availability}
                    plans={item?.provider_plans}
                  />
                );
              }
            })}
        </div>
      </div>
    </DashboardPatient>
  );
}

export default MainPatient;
