import React, { useEffect, useState } from "react";
import ProfileImg from "../../images/profile-2.svg";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import swal from "sweetalert";
import { useHistory, useParams } from "react-router-dom";
import StarsComponent from "../utils/StarsComponent";
import Switch from "@material-ui/core/Switch";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Axios from "axios";
import LoadingSpin from "../utils/Loading";
import ProviderProfile from "./ProviderProfile";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DashboardMUI from "../dashboard/DashboardMui";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import providerIcon from "../../images/doctor.svg";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import CheckIcon from "@material-ui/icons/Check";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import FormHelperText from "@material-ui/core/FormHelperText";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import IconButton from "@material-ui/core/IconButton";
import UseUserState from "../hooks/useUserState";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { setUserImageProfile } from "../../actions";
import { add } from "date-fns";
import { calendarFormat } from "moment";

function ProviderEditProfile(props) {
  const storeState = UseUserState();
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    visible: false,
    msgClass: "",
  });
  const [newIssuedArray, setNewIssuedArray] = useState([]);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [newExperience, setNewExperience] = useState(false);
  const [yearEnd, setYearEnd] = useState("");
  const [yearStart, setYearStart] = useState("");
  const [monthEnd, setMonthEnd] = useState("");
  const [monthStart, setMonthStart] = useState("");
  const [job, setJob] = useState("");
  const [institute, setInstitute] = useState("");
  const [expSummary, setExpSummary] = useState("");
  const [edit, setEdit] = useState(false);

  const [studies, setStudies] = useState({
    carrera: "",
    colegio: "",
    fechaInicio: "",
    fechaFinal: "",
  });
  const [experience, setExperience] = useState([]);
  const [lenguajes, setLenguajes] = useState([
    {
      lenguaje: "",
      level: "",
    },
    {
      lenguaje: "",
      level: "",
    },
  ]);

  //Distpach of Redux
  const distpach = useDispatch();
  //Extract the state from Session Storage

  //Extract the userState to Redux Glabal State
  const userState = storeState[0];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const useStyles = makeStyles((theme) => ({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(2),
    },
    textField: {
      width: "100%",
    },
    specialty: {
      width: "100%",
    },
    experience: {
      color: "#999999",
      fontSize: "20px",
    },
    menuItem: {
      height: "200px",
    },
  }));

  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: "#e33371",
      },
    },
  });
  const classes = useStyles();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const clearFromExperience = (e) => {
    setYearEnd("");
    setYearStart("");
    setMonthEnd("");
    setMonthStart("");
    setJob("");
    setInstitute("");
    setExpSummary("");
  };
  const handleExperience = async (
    job,
    institute,
    expSummary,
    monthStart,
    yearStart,
    monthEnd,
    yearEnd
  ) => {
    let startDate = monthStart + " " + yearStart;
    let endDate = monthEnd + " " + yearEnd;
    let inExperience = {
      job: job,
      institute: institute,
      startDate: startDate,
      endDate: endDate,
      expSummary: expSummary,
    };
    props.handleChangeInfo(inExperience, "experience");
    clearFromExperience();
    setNewExperience(false);
  };

  const handleEditExperience = (id) => {
    let inExperience = props.experience[id];
    let dateEnd = inExperience.endDate.split(" ");
    let dateStart = inExperience.startDate.split(" ");
    if (inExperience) {
      setJob(inExperience.job);
      setInstitute(inExperience.institute);
      setExpSummary(inExperience.expSummary);
      setMonthStart(dateStart[0]);
      setYearStart(dateStart[1]);
      setMonthEnd(dateEnd[0]);
      setYearEnd(dateEnd[1]);
    }
    console.log(inExperience);
    console.log(props.experience);
  };

  const handleDeleteExperience = (id) => {
    let inExperience = props.experience;
    inExperience.splice(id);
    console.log(inExperience);
    props.handleChangeInfo(inExperience, "experienceDelete");
    console.log(props.experience);
  };

  return (
    <>
      <div className="col-md-9">
        <form className="row" id="patient-form">
          <div className="form-group col-md-6">
            <TextField
              margin="normal"
              label="First Name"
              variant="outlined"
              id="firstName"
              fullWidth
              onChange={(e) =>
                props.handleChangeInfo(e.target.value, "firstName")
              }
              required
              value={props.firstName || ""}
            />
          </div>
          <div className="form-group col-md-6">
            <TextField
              required
              fullWidth
              margin="normal"
              label="Last Name"
              variant="outlined"
              id="lastname"
              onChange={(e) =>
                props.handleChangeInfo(e.target.value, "lastname")
              }
              value={props.lastname || ""}
            />
          </div>
          <div className="form-group col-md-12">
            <TextField
              required
              margin="normal"
              label="Your Email"
              variant="outlined"
              id="email"
              value={props.email || ""}
              autoComplete="email"
              fullWidth
              placeholder="name@example.com"
              onChange={(e) => props.handleChangeInfo(e.target.value, "email")}
            />
          </div>
          <div className="form-group col-md-8">
            <TextField
              required
              fullWidth
              margin="normal"
              label="Phone Number"
              variant="outlined"
              id="phoneNumber"
              value={props.phone || ""}
              placeholder="888823456"
              onChange={(e) => props.handleChangeInfo(e.target.value, "phone")}
            />
          </div>
          <div className="form-group col-md-4 ">
            <TextField
              id="country"
              required
              select
              fullWidth
              variant="outlined"
              margin="normal"
              placeholder="Select"
              label="Country"
              value={props.country || "select"}
              onChange={(e) =>
                props.handleChangeInfo(e.target.value, "country")
              }
            >
              {props.countries &&
                props.countries.map((country) => {
                  return (
                    <MenuItem value={country.code}>{country.name}</MenuItem>
                  );
                })}
            </TextField>
          </div>
          <div className="form-group col-md-3">
            <TextField
              id="identificationType"
              required
              select
              fullWidth
              variant="outlined"
              margin="normal"
              placeholder="Select"
              label="Identification Type"
              onChange={(e) =>
                props.handleChangeInfo(e.target.value, "identificationType")
              }
              value={props.identificationType || "select"}
            >
              <MenuItem value="DNI">DNI</MenuItem>
              <MenuItem value="PASSPORT">Passport</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </div>

          <div className="form-group col-md-9">
            <TextField
              required
              fullWidth
              margin="normal"
              label="Identification/DNI"
              variant="outlined"
              id="identification"
              onChange={(e) =>
                props.handleChangeInfo(e.target.value, "identification")
              }
              value={props.identification || ""}
            />
          </div>
          <div className="form-group col-md-4">
            <TextField
              variant="outlined"
              required
              fullWidth
              margin="normal"
              id="dateOfBirth"
              name="dateOfBirth"
              InputLabelProps={{
                shrink: true,
              }}
              type="date"
              label="Date Of Birth"
              onChange={(e) =>
                props.handleChangeInfo(e.target.value, "dateOfBirth")
              }
              value={props.dateOfBirth || ""}
            />
          </div>
          <div className="form-group col-md-8">
            <FormControl variant="outlined" className={classes.form}>
              <InputLabel required id="gender">
                Gender
              </InputLabel>
              <Select
                labelId="gender"
                id="gender"
                value={props.gender}
                onChange={(e) =>
                  props.handleChangeInfo(e.target.value, "gender")
                }
                label="Gender"
              >
                <MenuItem value={""}>None</MenuItem>
                <MenuItem value={"MALE"}>Male</MenuItem>
                <MenuItem value={"FEMALE"}>Female</MenuItem>
                <MenuItem value={"OTHER"}>Other</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="form-group col-md-12">
            <TextField
              id="summary"
              label="Summary"
              multiline
              fullWidth
              rows={5}
              value={props.summary}
              variant="outlined"
              onChange={(e) =>
                props.handleChangeInfo(e.target.value, "summary")
              }
            />
          </div>
          <div className="form-group col-md-6">
            <TextField
              required
              fullWidth
              margin="normal"
              label="Medical Licence"
              variant="outlined"
              id="licence"
              value={props.licence || ""}
              placeholder="888823456"
              onChange={(e) =>
                props.handleChangeInfo(e.target.value, "licence")
              }
            />
          </div>
          <div className="form-group col-md-6">
            <TextField
              required
              fullWidth
              select
              margin="normal"
              label="Title"
              variant="outlined"
              placeholder="Select"
              id="title"
              value={props.title || "Select"}
              onChange={(e) => props.handleChangeInfo(e.target.value, "title")}
            >
              <MenuItem value="Mr.">Mr.</MenuItem>
              <MenuItem value="Mrs.">Mrs.</MenuItem>
              <MenuItem value="Ms.">Ms.</MenuItem>
              <MenuItem value="Dr.">Dr.</MenuItem>
            </TextField>
          </div>
          <div className="form-group col-md-5 pr-md-0">
            <TextField
              id="issuedBy"
              required
              fullWidth
              variant="outlined"
              margin="normal"
              label="Issued by"
              onKeyDown={(e) =>
                e.key === "Enter"
                  ? props.handleIssuedBy(props.issuedBy, props.issuedSaved)
                  : ""
              }
              onChange={(e) => props.handleChangeInfo(e.target.value, "issued")}
              value={props.issuedBy || ""}
            />
          </div>
          {props.width >= 690 ? (
            <div className="form-group px-md-0 d-flex align-content-center justify-content-center col-md-1">
              <IconButton
                onClick={(e) =>
                  props.handleIssuedBy(props.issuedBy, props.issuedSaved)
                }
                color="primary"
                aria-label="add to shopping cart"
              >
                <DoubleArrowIcon />
              </IconButton>
            </div>
          ) : (
            ""
          )}
          {props.width == 0 ? (
            <div className="form-group px-md-0 d-flex align-content-center justify-content-center col-md-1"></div>
          ) : (
            ""
          )}
          {props.width < 690 && props.width != 0 ? (
            <div className="form-group px-md-0 d-flex align-content-center justify-content-center col-md-1">
              <IconButton
                onClick={(e) =>
                  props.handleIssuedBy(props.issuedBy, props.issuedSaved)
                }
                color="primary"
                aria-label="add to shopping cart"
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </div>
          ) : (
            ""
          )}
          <div className="form-group col-md-6 pl-md-0 pt-md-3 pb-md-2">
            <div className={classes.issuedStyle + " issued p-1"}>
              {props.issuedSaved &&
                props.issuedSaved.map((item, index) => (
                  <Chip
                    className="m-2"
                    label={item}
                    onDelete={(e) =>
                      props.handleDeleteIssued(props.issuedSaved, index)
                    }
                    key={index}
                  />
                ))}
            </div>
          </div>
          <div className="form-group col-md-12">
            <div className="issued px-3 pt-1 pb-3">
              <FormControl className={classes.specialty}>
                <InputLabel id="specialty-label">Specialty*</InputLabel>
                <Select
                  required
                  labelId="specialty-label"
                  id="specialty"
                  multiple
                  value={props.selectedSpecialty}
                  onChange={(e) => {
                    props.handleChangeInfo(e.target.value, "specialty");
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className="selectOverflow">
                      {props.selectedSpecialty &&
                        props.selectedSpecialty.map((item, index) =>
                          item != "" ? (
                            <Chip className="m-2" label={item} key={index} />
                          ) : (
                            ""
                          )
                        )}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {props.specialty &&
                    props.specialty.map((el) => (
                      <MenuItem
                        key={el.id}
                        value={el.speciality_name}
                        //style={getStyles(name, personName, theme)}
                      >
                        {el.speciality_name}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  To deleted select the specialty again
                </FormHelperText>
              </FormControl>
            </div>
          </div>
          <div className="form-group col-md-12  pt-md-3 pb-md-2">
            <div className="issued p-3">
              <div className="row">
                <div className="d-flex col-12 my-2 mr-3 justify-content-between">
                  <div className="d-flex align-content-center">
                    <p className={classes.experience + " my-auto experience"}>
                      Experience
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <Button
                      className="p-2"
                      variant="contained"
                      color="secondary"
                      startIcon={<AddIcon />}
                      onClick={(e) => setNewExperience(true)}
                    >
                      Add New
                    </Button>
                  </div>
                </div>
                {newExperience && (
                  <>
                    <div className="d-flex mt-1 mb-0 col-12">
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="Job"
                        variant="outlined"
                        id="job"
                        value={job || ""}
                        onChange={(e) => setJob(e.target.value)}
                      />
                    </div>
                    <div className="d-flex mt-1 mb-0 col-12">
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="Institute"
                        variant="outlined"
                        id="institute"
                        value={institute || ""}
                        onChange={(e) => setInstitute(e.target.value)}
                      />
                    </div>
                    <div className="d-flex mt-1 mb-1 col-12">
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="Summary"
                        variant="outlined"
                        id="expSummary"
                        value={expSummary || ""}
                        onChange={(e) => setExpSummary(e.target.value)}
                      />
                    </div>
                    <div className="d-flex mt-3 mb-0 col-12">
                      <p className={classes.experience + " my-auto experience"}>
                        Start Date
                      </p>
                    </div>
                    <div className="col-6 my-2">
                      <TextField
                        required
                        fullWidth
                        select
                        margin="normal"
                        label="Month"
                        variant="outlined"
                        id="monthS"
                        value={monthStart || ""}
                        onChange={(e) => setMonthStart(e.target.value)}
                      >
                        {months &&
                          months.map((mon, id) => (
                            <MenuItem key={id} value={`${mon}`}>
                              {mon}
                            </MenuItem>
                          ))}
                      </TextField>
                    </div>
                    <div className="col-6 my-2">
                      <TextField
                        required
                        fullWidth
                        select
                        margin="normal"
                        label="Year"
                        variant="outlined"
                        id="yearS"
                        value={yearStart || ""}
                        onChange={(e) => setYearStart(e.target.value)}
                      >
                        {props.years &&
                          props.years.map((year, id) => (
                            <MenuItem key={id} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                      </TextField>
                    </div>
                    <div className="d-flex mt-3 mb-0 col-12">
                      <p className={classes.experience + " my-auto experience"}>
                        End Date
                      </p>
                    </div>
                    <div className="col-6 my-2">
                      <TextField
                        required
                        fullWidth
                        select
                        margin="normal"
                        label="Month"
                        variant="outlined"
                        id="monthS"
                        value={monthEnd || ""}
                        onChange={(e) => setMonthEnd(e.target.value)}
                      >
                        {months &&
                          months.map((mon, id) => (
                            <MenuItem key={id} value={`${mon}`}>
                              {mon}
                            </MenuItem>
                          ))}
                      </TextField>
                    </div>
                    <div className="col-6 my-2">
                      <TextField
                        required
                        fullWidth
                        select
                        margin="normal"
                        label="Year"
                        variant="outlined"
                        id="yearS"
                        value={yearEnd || ""}
                        onChange={(e) => setYearEnd(e.target.value)}
                      >
                        {props.years &&
                          props.years.map((year, id) => (
                            <MenuItem key={id} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                      </TextField>
                    </div>
                    <div className="d-flex col-12 mt-3 justify-content-center align-items-center">
                      <Button
                        className="p-2 m-2"
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        onClick={(e) => {
                          handleExperience(
                            job,
                            institute,
                            expSummary,
                            monthStart,
                            yearStart,
                            monthEnd,
                            yearEnd
                          );
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        className="p-2 m-2"
                        variant="contained"
                        color="secondary"
                        startIcon={<CancelIcon />}
                        onClick={(e) => {
                          setNewExperience(false);
                          clearFromExperience();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                )}
                {edit && (
                  <>
                    <div className="d-flex mt-1 mb-0 col-12">
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="Job"
                        variant="outlined"
                        id="job"
                        value={job || ""}
                        onChange={(e) => setJob(e.target.value)}
                      />
                    </div>
                    <div className="d-flex mt-1 mb-0 col-12">
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="Institute"
                        variant="outlined"
                        id="institute"
                        value={institute || ""}
                        onChange={(e) => setInstitute(e.target.value)}
                      />
                    </div>
                    <div className="d-flex mt-1 mb-1 col-12">
                      <TextField
                        required
                        fullWidth
                        margin="normal"
                        label="Summary"
                        variant="outlined"
                        id="expSummary"
                        value={expSummary || ""}
                        onChange={(e) => setExpSummary(e.target.value)}
                      />
                    </div>
                    <div className="d-flex mt-3 mb-0 col-12">
                      <p className={classes.experience + " my-auto experience"}>
                        Start Date
                      </p>
                    </div>
                    <div className="col-6 my-2">
                      <TextField
                        required
                        fullWidth
                        select
                        margin="normal"
                        label="Month"
                        variant="outlined"
                        id="monthS"
                        value={monthStart || ""}
                        onChange={(e) => setMonthStart(e.target.value)}
                      >
                        {months &&
                          months.map((mon, id) => (
                            <MenuItem key={id} value={`${mon}`}>
                              {mon}
                            </MenuItem>
                          ))}
                      </TextField>
                    </div>
                    <div className="col-6 my-2">
                      <TextField
                        required
                        fullWidth
                        select
                        margin="normal"
                        label="Year"
                        variant="outlined"
                        id="yearS"
                        value={yearStart || ""}
                        onChange={(e) => setYearStart(e.target.value)}
                      >
                        {props.years &&
                          props.years.map((year, id) => (
                            <MenuItem key={id} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                      </TextField>
                    </div>
                    <div className="d-flex mt-3 mb-0 col-12">
                      <p className={classes.experience + " my-auto experience"}>
                        End Date
                      </p>
                    </div>
                    <div className="col-6 my-2">
                      <TextField
                        required
                        fullWidth
                        select
                        margin="normal"
                        label="Month"
                        variant="outlined"
                        id="monthS"
                        value={monthEnd || ""}
                        onChange={(e) => setMonthEnd(e.target.value)}
                      >
                        {months &&
                          months.map((mon, id) => (
                            <MenuItem key={id} value={`${mon}`}>
                              {mon}
                            </MenuItem>
                          ))}
                      </TextField>
                    </div>
                    <div className="col-6 my-2">
                      <TextField
                        required
                        fullWidth
                        select
                        margin="normal"
                        label="Year"
                        variant="outlined"
                        id="yearS"
                        value={yearEnd || ""}
                        onChange={(e) => setYearEnd(e.target.value)}
                      >
                        {props.years &&
                          props.years.map((year, id) => (
                            <MenuItem key={id} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                      </TextField>
                    </div>
                    {/* <div className="form-group col-md-12  pt-md-3 pb-md-2">
                        <div className="issued p-3">
                            <div className="row">
                                <div className="d-flex col-12 my-2 mr-3 justify-content-between">
                                    <div className="d-flex align-content-center">
                                        <p className={classes.experience + " my-auto experience"}>Experience</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <Button
                                            className="p-2"
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<AddIcon />}
                                            onClick={(e) => setNewExperience(true)}
                                        >
                                            Add New
                                        </Button>
                                    </div>
                                </div>
                                {newExperience &&
                                <>
                                    <div className="d-flex mt-1 mb-0 col-12">
                                    <TextField
                                        required
                                        fullWidth
                                        margin="normal"
                                        label="Job"
                                        variant="outlined"
                                        id="job"
                                        value={job || ""}
                                        onChange={(e) => 
                                            setJob(e.target.value)
                                        }
                                    />                                    
                                    </div>
                                    <div className="d-flex mt-1 mb-0 col-12">
                                    <TextField
                                        required
                                        fullWidth
                                        margin="normal"
                                        label="Institute"
                                        variant="outlined"
                                        id="institute"
                                        value={institute || ""}
                                        onChange={(e) => 
                                            setInstitute(e.target.value)
                                        }
                                    />                                    
                                    </div>
                                    <div className="d-flex mt-1 mb-1 col-12">
                                    <TextField
                                        required
                                        fullWidth
                                        margin="normal"
                                        label="Summary"
                                        variant="outlined"
                                        id="expSummary"
                                        value={expSummary || ""}
                                        onChange={(e) => 
                                            setExpSummary(e.target.value)
                                        }
                                    />                                    
                                    </div>
                                    <div className="d-flex mt-3 mb-0 col-12">
                                        <p className={classes.experience + " my-auto experience"}>Start Date</p>
                                    </div>
                                    <div className="col-6 my-2">
                                        <TextField
                                            required
                                            fullWidth
                                            select
                                            margin="normal"
                                            label="Month"
                                            variant="outlined"
                                            id="monthS"
                                            value={monthStart || ""}
                                            onChange={(e) => 
                                                setMonthStart(e.target.value)
                                            }
                                        >
                                        {months &&
                                        months.map((mon, id)=>(
                                            <MenuItem key={id} value={`${mon}`}>{mon}</MenuItem>
                                        ))}
                                        </TextField>
                                    </div>
                                    <div className="col-6 my-2">
                                        <TextField
                                            required
                                            fullWidth
                                            select
                                            margin="normal"
                                            label="Year"
                                            variant="outlined"
                                            id="yearS"
                                            value={yearStart || ""}
                                            onChange={(e) => 
                                                setYearStart(e.target.value)
                                            }
                                        >
                                        {props.years &&
                                        props.years.map((year, id)=>(
                                            <MenuItem key={id} value={year}>{year}</MenuItem>
                                        ))}
                                        </TextField>
                                    </div>
                                    <div className="d-flex mt-3 mb-0 col-12">
                                        <p className={classes.experience + " my-auto experience"}>End Date</p>
                                    </div>
                                    <div className="col-6 my-2">
                                        <TextField
                                            required
                                            fullWidth
                                            select
                                            margin="normal"
                                            label="Month"
                                            variant="outlined"
                                            id="monthS"
                                            value={monthEnd || ""}
                                            onChange={(e) => 
                                                setMonthEnd(e.target.value)
                                            }
                                        >
                                        {months &&
                                        months.map((mon, id)=>(
                                            <MenuItem key={id} value={`${mon}`}>{mon}</MenuItem>
                                        ))}
                                        </TextField>
                                    </div>
                                    <div className="col-6 my-2">
                                        <TextField
                                            required
                                            fullWidth
                                            select
                                            margin="normal"
                                            label="Year"
                                            variant="outlined"
                                            id="yearS"
                                            value={yearEnd || ""}
                                            onChange={(e) => 
                                                setYearEnd(e.target.value)
                                            }
                                        >
                                        {props.years &&
                                        props.years.map((year, id)=>(
                                            <MenuItem key={id} value={year}>{year}</MenuItem>
                                        ))}
                                        </TextField>
                                    </div>
                                    <div className="d-flex col-12 mt-3 justify-content-center align-items-center">
                                        <Button
                                            className="p-2 m-2"
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<AddIcon />}
                                            onClick={(e) => {
                                                handleExperience(
                                                    job, 
                                                    institute, 
                                                    expSummary, 
                                                    monthStart, 
                                                    yearStart, 
                                                    monthEnd, 
                                                    yearEnd
                                                )}}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            className="p-2 m-2"
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<CancelIcon />}
                                            onClick={(e) => {setNewExperience(false); clearFromExperience()}}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                                }
                                {edit &&
                                <>
                                    <div className="d-flex mt-1 mb-0 col-12">
                                    <TextField
                                        required
                                        fullWidth
                                        margin="normal"
                                        label="Job"
                                        variant="outlined"
                                        id="job"
                                        value={job || ""}
                                        onChange={(e) => 
                                            setJob(e.target.value)
                                        }
                                    />                                    
                                    </div>
                                    <div className="d-flex mt-1 mb-0 col-12">
                                    <TextField
                                        required
                                        fullWidth
                                        margin="normal"
                                        label="Institute"
                                        variant="outlined"
                                        id="institute"
                                        value={institute || ""}
                                        onChange={(e) => 
                                            setInstitute(e.target.value)
                                        }
                                    />                                    
                                    </div>
                                    <div className="d-flex mt-1 mb-1 col-12">
                                    <TextField
                                        required
                                        fullWidth
                                        margin="normal"
                                        label="Summary"
                                        variant="outlined"
                                        id="expSummary"
                                        value={expSummary || ""}
                                        onChange={(e) => 
                                            setExpSummary(e.target.value)
                                        }
                                    />                                    
                                    </div>
                                    <div className="d-flex mt-3 mb-0 col-12">
                                        <p className={classes.experience + " my-auto experience"}>Start Date</p>
                                    </div>
                                    <div className="col-6 my-2">
                                        <TextField
                                            required
                                            fullWidth
                                            select
                                            margin="normal"
                                            label="Month"
                                            variant="outlined"
                                            id="monthS"
                                            value={monthStart || ""}
                                            onChange={(e) => 
                                                setMonthStart(e.target.value)
                                            }
                                        >
                                        {months &&
                                        months.map((mon, id)=>(
                                            <MenuItem key={id} value={`${mon}`}>{mon}</MenuItem>
                                        ))}
                                        </TextField>
                                    </div>
                                    <div className="col-6 my-2">
                                        <TextField
                                            required
                                            fullWidth
                                            select
                                            margin="normal"
                                            label="Year"
                                            variant="outlined"
                                            id="yearS"
                                            value={yearStart || ""}
                                            onChange={(e) => 
                                                setYearStart(e.target.value)
                                            }
                                        >
                                        {props.years &&
                                        props.years.map((year, id)=>(
                                            <MenuItem key={id} value={year}>{year}</MenuItem>
                                        ))}
                                        </TextField>
                                    </div>
                                    <div className="d-flex mt-3 mb-0 col-12">
                                        <p className={classes.experience + " my-auto experience"}>End Date</p>
                                    </div>
                                    <div className="col-6 my-2">
                                        <TextField
                                            required
                                            fullWidth
                                            select
                                            margin="normal"
                                            label="Month"
                                            variant="outlined"
                                            id="monthS"
                                            value={monthEnd || ""}
                                            onChange={(e) => 
                                                setMonthEnd(e.target.value)
                                            }
                                        >
                                        {months &&
                                        months.map((mon, id)=>(
                                            <MenuItem key={id} value={`${mon}`}>{mon}</MenuItem>
                                        ))}
                                        </TextField>
                                    </div>
                                    <div className="col-6 my-2">
                                        <TextField
                                            required
                                            fullWidth
                                            select
                                            margin="normal"
                                            label="Year"
                                            variant="outlined"
                                            id="yearS"
                                            value={yearEnd || ""}
                                            onChange={(e) => 
                                                setYearEnd(e.target.value)
                                            }
                                        >
                                        {props.years &&
                                        props.years.map((year, id)=>(
                                            <MenuItem key={id} value={year}>{year}</MenuItem>
                                        ))}
                                        </TextField>
                                    </div>
                                    <div className="d-flex col-12 mt-3 justify-content-center align-items-center">
                                        <Button
                                            className="p-2 m-2"
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<AddIcon />}
                                            onClick={(e) => {
                                                handleExperience(
                                                    job, 
                                                    institute, 
                                                    expSummary, 
                                                    monthStart, 
                                                    yearStart, 
                                                    monthEnd, 
                                                    yearEnd
                                                )}}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            className="p-2 m-2"
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<CancelIcon />}
                                            onClick={(e) => {setEdit(false); clearFromExperience()}}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                                }
                                {props.experience &&
                                props.experience.map((item, idx) => (
                                <div key={idx} className="col-11 d-flex my-3 ml-3 justify-content-between issued">
                                    <div className="flex-column p-3">
                                    <Typography variant="h6" gutterBottom>
                                        {"• " + item.job}
                                    </Typography>
                                    <Typography className="ml-1" variant="subtitle1" gutterBottom>
                                        {item.institute}
                                    </Typography>
                                    <Typography className="ml-1" variant="subtitle1" gutterBottom>
                                        {item.expSummary}
                                    </Typography>
                                    </div>
                                    <div className="flex-column p-3 justify-content-between">
                                    <Typography variant="subtitle1" gutterBottom>
                                        {item.startDate + " - " + item.endDate}
                                    </Typography>
                                    <div className="d-flex justify-content-end mt-4">
                                    <IconButton onClick={(e)=> {setEdit(true); handleEditExperience(idx);}} color="primary" aria-label="edit">
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={(e)=> handleDeleteExperience(idx)} aria-label="delete" color="primary">
                                        <DeleteIcon />
                                    </IconButton>
                                    </div>
                                    </div>
                                </div>
                                ))
                                }
                            </div>
                        </div>
                    </div> */}
                    <div className="d-flex col-12 mt-3 justify-content-center align-items-center">
                      <Button
                        className="p-2 m-2"
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        onClick={(e) => {
                          handleExperience(
                            job,
                            institute,
                            expSummary,
                            monthStart,
                            yearStart,
                            monthEnd,
                            yearEnd
                          );
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        className="p-2 m-2"
                        variant="contained"
                        color="secondary"
                        startIcon={<CancelIcon />}
                        onClick={(e) => {
                          setEdit(false);
                          clearFromExperience();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                )}
                {props.experience &&
                  props.experience.map((item, idx) => (
                    <div
                      key={idx}
                      className="col-11 d-flex my-3 ml-3 justify-content-between issued"
                    >
                      <div className="flex-column p-3">
                        <Typography variant="h6" gutterBottom>
                          {"• " + item.job}
                        </Typography>
                        <Typography
                          className="ml-1"
                          variant="subtitle1"
                          gutterBottom
                        >
                          {item.institute}
                        </Typography>
                        <Typography
                          className="ml-1"
                          variant="subtitle1"
                          gutterBottom
                        >
                          {item.expSummary}
                        </Typography>
                      </div>
                      <div className="flex-column p-3 justify-content-between">
                        <Typography variant="subtitle1" gutterBottom>
                          {item.startDate + " - " + item.endDate}
                        </Typography>
                        <div className="d-flex justify-content-end mt-4">
                          <IconButton
                            onClick={(e) => {
                              setEdit(true);
                              handleEditExperience(idx);
                            }}
                            color="primary"
                            aria-label="edit"
                          >
                            <EditIcon />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <Grid container justify="flex-start" className="m-3">
            <Grid item>
              <Link
                href={"/user/login/recover/" + storeState[0].jwt}
                variant="body2"
              >
                Change Password
              </Link>
            </Grid>
          </Grid>
          <div className="col-md-12 mt-3 d-flex justify-content-center ">
            {props.errorMessage.visible && (
              <div className={props.errorMessage.msgClass} role="alert">
                {props.errorMessage.error}
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
}

export default ProviderEditProfile;
