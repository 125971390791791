import React, {useState} from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {makeStyles} from "@material-ui/core/styles";
import userIcon from "../../../../images/user-icon.svg";
import useUserState from "../../../hooks/useUserState";
import useBDocTranslator from "../../../hooks/useBDocTranslator";
import TextField from "@material-ui/core/TextField";
import {Button, MenuItem} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import InformationComp from "../../../utils/InformationComp";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import {AffliationsLogic} from "./AffliationsLogic";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: "whitesmoke",
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "900px",
        height: "600px",
    },
}));

export default function AffiliationModalEdit({data, open, handleClose}) {
    const classes = useStyles();
    const [responseMessage, setResponseMessage] = useState("");
    const userLocalState = useUserState();
    const [successFlag, setSuccessFlag] = useState(false);
    const {t} = useBDocTranslator();
    const {updateAffiliation} = AffliationsLogic()
    const [affiliationToEdit, setAffiliationToEdit] = useState({
        id: '',
        status: ''
    })

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    {
                        <div className={classes.paper}>
                            <div className="row col-md-12 mb-4">
                                <div className="col-md-4">
                                    <img src={userIcon} alt="rx icon" className="w-30-custom"/>
                                </div>
                                <div className="col-md-8">
                                    <h3>{t("Affiliation Edition")}</h3>
                                </div>
                                <div className="col-md-12">
                                    <p
                                        className={`font-weight-bold  ${
                                            responseMessage?.includes("ERROR")
                                                ? "text-danger"
                                                : "text-success"
                                        } text-center`}
                                    >
                                        {t(responseMessage)}
                                    </p>
                                </div>
                            </div>
                            {/* Form */}
                            <div className="row col-md-12 justify-content-center">
                                {/*    Form here to Edit the plan information when the user click on edit button in the table, the data will be received by props */}
                                <div className="row col-md-12 my-3">

                                    <div className="col-md-4">
                                        <TextField
                                            id="outlined-basic"
                                            label="Plan Name"
                                            variant="outlined"
                                            className="w-100-custom"
                                            value={data?.name}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <TextField
                                            id="outlined-basic"
                                            label="Plan Name"
                                            variant="outlined"
                                            className="w-100-custom"
                                            value={data?.plan_name}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <TextField
                                            id="outlined-basic"
                                            label="Plan Price"
                                            variant="outlined"
                                            className="w-100-custom"
                                            value={data?.price}
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="row col-md-12 my-3">

                                    <div className="col-md-4">
                                        <TextField
                                            id="outlined-basic"
                                            label="Affiliation Date"
                                            variant="outlined"
                                            className="w-100-custom"
                                            value={data?.affiliation_dt_start}
                                            disabled={true}
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <TextField
                                            id="outlined-basic"
                                            label="Plan Status"
                                            variant="outlined"
                                            className="w-100-custom"
                                            fullWidth
                                            select
                                            value={data?.status}
                                            onChange={console.log}
                                            disabled={true}
                                        >
                                            <MenuItem value={"pending"}>
                                                Pending
                                            </MenuItem>
                                            <MenuItem value={"active"}>
                                                Active
                                            </MenuItem>
                                            <MenuItem value={"inactive"}>
                                                Inactive
                                            </MenuItem>

                                        </TextField>
                                    </div>


                                </div>
                                {/*divisor line */}
                                <hr className="w-100"/>
                                <div className="col-md-12 my-3">
                                    <div className="mb-4">
                                        <InformationComp
                                            title={t("Importante")}
                                            description={t(
                                                "Seleccione un nuevo estado para el Plan del Paciente Seleccionado, una vez cambiado el paciente no contara con los beneficios del Plan."
                                            )}/>
                                    </div>
                                    <div className="row col-md-12">
                                        <TextField
                                            id="outlined-basic"
                                            label="Plan Status"
                                            variant="outlined"
                                            className="w-100-custom"
                                            fullWidth
                                            select
                                            value={affiliationToEdit?.status}
                                            onChange={(e) => setAffiliationToEdit({
                                                id: data?.id,
                                                status: e.target.value
                                            })}
                                        >
                                            <MenuItem value={"pending"}>
                                                Pending
                                            </MenuItem>
                                            <MenuItem value={"active"}>
                                                Active
                                            </MenuItem>
                                            <MenuItem value={"inactive"}>
                                                Inactive
                                            </MenuItem>

                                        </TextField>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 d-flex justify-content-center ">
                                            <div className="p-3">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    startIcon={<CheckCircleOutlineIcon/>}
                                                    onClick={()=>{
                                                        if(data?.status === affiliationToEdit.status) {
                                                            setResponseMessage("Seleccione un Status Diferente al Actual")
                                                            return
                                                        }
                                                        updateAffiliation(affiliationToEdit).then(r => console.log(r))
                                                    }}
                                                >
                                                    {t("Save")}
                                                </Button>
                                            </div>

                                            <div className="p-3">
                                                <Button
                                                    variant="contained"
                                                    color="default"
                                                    startIcon={<CancelIcon/>}
                                                    onClick={handleClose}
                                                >
                                                    {t("Cancel")}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    }
                </Fade>
            </Modal>
        </div>
    );
}
