import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function useBDocTranslator() {
  // To manage translations
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("en");

  // Func to change the language on demand
  const onChangeLanguaje = () => {
    if (language === "es") {
      setLanguage("en");
    } else {
      setLanguage("es");
    }
    i18n.changeLanguage(language);
  };

  const changeDirectLanguage = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return {
    language,
    onChangeLanguaje,
    t,
    setLanguage,
    changeDirectLanguage,
  };
}
