import React, { useState, useRef, useEffect } from "react";
import DashboardMUI from "../dashboard/DashboardMui";
import providerIcon from "../../images/doctor.svg";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import SignatureCanvas from "react-signature-canvas";
import ClearIcon from "@material-ui/icons/Clear";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Axios from "axios";
import userLocalState from "../hooks/useUserState";
import swal from "sweetalert";
import LoadingSpin from "../utils/Loading";
import { makeStyles } from "@material-ui/core/styles";
import ProviderProfileEdition from "./ProviderProfileEdition";
import useBDocTranslator from "../hooks/useBDocTranslator";
import { FormLabel, TextField, FormControl } from "@material-ui/core";
import CropImage from "./settings/CropImage";

export default function ProviderUserSettings() {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }));
  const classes = useStyles();

  const [signatureURL, setSignatureURL] = useState(null);
  const localState = userLocalState();
  const [loading, setLoading] = useState(false);
  const { t, changeDirectLanguage } = useBDocTranslator();
  const [currentSignature, setCurrentSignature] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [currentStamp, setCurrentStamp] = useState(null);
  const [cropedImg, setCropedImg] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [crop, setCrop] = useState();

  const sigCanvas = useRef({});
  const imageRef = useRef();

  const clear = () => {
    sigCanvas.current.clear();
  };

  const handleSignature = () => {
    setSignatureURL(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
  };

  const save = () => {
    if (signatureURL) {
      const url = `${process.env.REACT_APP_API_URL_BASE}/provider/update`;
      setLoading(true);
      Axios.put(
        url,
        {
          id: localState[0].extendedData.id,
          signature: signatureURL,
        },
        {
          headers: { Authorization: "Bearer " + localState[0].jwt }, //the jwt is a variable which holds the token
        }
      )
        .then((res) => {
          setLoading(false);
          swal(
            "Success!",
            t(
              "Signature saved!, Now your prescriptions will be signed with this signature"
            ),
            "success"
          );
        })
        .catch((error) => {
          console.log("Error Signature", error);
          setLoading(false);
          swal(
            "Error!",
            t(
              "Something went wrong saving signature, please try again later or contact Support"
            ),
            "error"
          );
        });
    } else {
      swal("Error!", t("Please sign inside the box"), "error");
    }
  };

  const saveStamp = () => {
    if (cropedImg) {
      const url = `${process.env.REACT_APP_API_URL_BASE}/provider/update`;
      setLoading(true);
      Axios.put(
        url,
        {
          id: localState[0].extendedData.id,
          stamp: cropedImg,
        },
        {
          headers: {
            Authorization: "Bearer " + localState[0].jwt,
          }, //the jwt is a variable which holds the token
        }
      )
        .then((res) => {
          setLoading(false);
          swal(
            "Success!",
            t(
              "Stamp saved!, Now your prescriptions will be stamped with this stamp"
            ),
            "success"
          );
        })
        .catch((error) => {
          console.log("Error Signature", error);
          setLoading(false);
          swal(
            "Error!",
            t(
              "Something went wrong saving stamp, please try again later or contact Support"
            ),
            "error"
          );
        });
    } else {
      swal("Error!", t("Please select a image of the stamp"), "error");
    }
  };

  useEffect(() => {
    changeDirectLanguage();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setFileName(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setFileName("");
    }
    console.log("File", file);
  };

  const onCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  const getCroppedImg = () => {
    const imageR = imageRef.current;
    const crop = completedCrop;

    const canvas = document.createElement("canvas");
    const scaleX = imageR.naturalWidth / imageR.width;
    const scaleY = imageR.naturalHeight / imageR.height;
    canvas.width = crop?.width;
    canvas.height = crop?.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      imageR,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const canvasImg = canvas.toDataURL("image/jpeg", 1);

    fetch(canvasImg)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "Filename.jpg", { type: "image/jpeg" });
        console.log("file on Fetch", file);
        setCropedImg(canvasImg);
      });
  };

  useEffect(() => {
    console.log("effect", !completedCrop || !imageRef.current);
    if (!completedCrop || !imageRef.current) {
      return;
    }
    getCroppedImg();
  }, [completedCrop]);

  return (
    <>
      <DashboardMUI
        sectionTitle={t("User Settings")}
        description={t("Set all your personal information and signature")}
        icon={providerIcon}
      >
        <ProviderProfileEdition
          setCurrentSignature={setCurrentSignature}
          setCurrentStamp={setCurrentStamp}
        />

        <div className="row">
          <div className="col-12">
            <div className="m-3 p-3 shadow rounded-lg-custom bg-white">
              <h4 className="title-dashboard-card pt-3 mb-0">
                {t("Add Your Signature")}
              </h4>
              {currentStamp && (
                <>
                  <div className="d-flex justify-content-center">
                    <p className="text-center text-secondary">
                      {t("Current Signature")}
                    </p>
                  </div>
                  <div className="col d-flex justify-content-center mb-5">
                    <img
                      src={currentStamp}
                      alt=""
                      width={200}
                      className="border"
                    />
                  </div>
                </>
              )}
              {/* <p className="text-center text-secondary">
                {t("Please sign inside the box")}
              </p> */}
              <div className="row d-flex justify-content-center mt-5">
                {/* <SignatureCanvas
                  onEnd={handleSignature}
                  ref={sigCanvas}
                  backgroundColor="white"
                  canvasProps={{
                    width: 500,
                    height: 400,
                    className: "rounded border border-info",
                  }}
                /> */}
                {fileName && (
                  <CropImage
                    src={fileName}
                    onComplete={onCropComplete}
                    imageRef={imageRef}
                    setCrop={setCrop}
                    crop={crop}
                  />
                )}
              </div>
              {/* Upload Image of Signature and Stamp with Crop Capabilities */}
              {/* <div className="d-flex justify-content-center">
                <div className="d-flex justify-content-center align-items-center mb-3">
                  <FormControl className="w-50">
                    <FormLabel htmlFor="fileInput">
                      {t("Select an image of your signature")}
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      type="file"
                      className="w-100"
                      id="fileInput"
                      aria-describedby="fileHelp"
                      onChange={handleFileChange}
                      inputProps={{
                        accept: "image/*",
                      }}
                    />
                  </FormControl>
                </div>
              </div> */}

              <div className="d-flex justify-content-center">
                {loading && (
                  <LoadingSpin type="spinningBubbles" color="#1d3557" />
                )}
              </div>
              {/* <div className="row d-flex justify-content-center p-3">
                <Button
                  className="m-1"
                  variant="outlined"
                  color="primary"
                  startIcon={<ClearIcon />}
                  onClick={clear}
                >
                  {t("Clear")}
                </Button>
                <Button
                  className="m-1"
                  variant="contained"
                  color="secondary"
                  startIcon={<CheckCircleOutlineIcon />}
                  onClick={save}
                >
                  {t("Save")}
                </Button>
              </div> */}
              {/* Stamp Upload field */}
              <div className="row d-flex justify-content-center">
                <div className="col-12">
                  <div className="form-group">
                    <div className="d-flex justify-content-center align-items-center mb-3">
                      <FormControl className="w-50">
                        <FormLabel htmlFor="fileInput">
                          {t("Select an image of your stamp")}
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          type="file"
                          className="w-100"
                          id="fileInput"
                          aria-describedby="fileHelp"
                          onChange={handleFileChange}
                          inputProps={{
                            accept: "image/*",
                          }}
                        />
                      </FormControl>
                    </div>

                    <small id="emailHelp" className="form-text text-muted">
                      {t(
                        "This stamp will be used in your prescriptions and documents"
                      )}
                    </small>

                    <div className="d-flex justify-content-center mt-2">
                      <Button
                        className="m-1"
                        variant="contained"
                        color="secondary"
                        startIcon={<CheckCircleOutlineIcon />}
                        onClick={saveStamp}
                      >
                        {t("Save")}
                      </Button>

                      {/* <Button
                        className="m-1"
                        variant="outlined"
                        color="secondary"
                        startIcon={<ClearIcon />}
                      >
                        {t("Delete")}
                      </Button> */}

                      <Button
                        className="m-1"
                        variant="outlined"
                        color="primary"
                        startIcon={<CloudDownloadIcon />}
                        href={currentStamp}
                        target="_blank"
                        download="stamp.png"
                      >
                        {t("Download")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row"></div>
      </DashboardMUI>
    </>
  );
}
