import React, { forwardRef, useState, useEffect } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function TableProviders(props) {
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    let providersList = [];
    if (props.data) {
      props.data.forEach((item) => {
        const {
          user: { email },
          status_provider: { status_name },
        } = item;
        providersList.push({ ...item, email, status_name });
      });
    }
    setProviders(providersList);
  }, []);

  const applicationStatus = (status) => {
    switch (status) {
      case "APPROVED":
        return <p style={{ color: "#009688", fontWeight: "bold" }}>{status}</p>;
      case "IN-REVIEW":
        return <p style={{ color: "#2196f3", fontWeight: "bold" }}>{status}</p>;
      case "REJECTED":
        return <p style={{ color: "#f44336", fontWeight: "bold" }}>{status}</p>;
      case "AWAITING":
        return <p style={{ color: "#ffc107", fontWeight: "bold" }}>{status}</p>;
      default:
        break;
    }
  };

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={[
          { title: "Id", field: "id" },
          { title: "Fistname", field: "firstName" },
          { title: "Last Name", field: "lastname" },
          { title: "E-Mail", field: "email" },
          { title: "Register date", field: "created_at", type: "datetime" },
          {
            title: "Application Status",
            field: "status_name",
            render: (rowData) => {
              return applicationStatus(rowData.status_name);
            },
          },
        ]}
        data={providers}
        title="Providers List"
        icons={tableIcons}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF",
          },
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: Edit,
            tooltip: "Edit",
            onClick: (e, row) => props.clickEvent(row),
          },
        ]}
      />
    </div>
  );
}

export default TableProviders;
