import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import BusinessIcon from "@material-ui/icons/Business";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import bdocIcon from "../../images/bdoc-providers.png";
import ToolSetting from "../../images/tools-settings.jpeg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  responsive: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  info: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  content: {
    flex: "1 0 auto",
  },
  Icon: {
    height: 15,
    width: 20,
  },
}));

export default function SettiingOption(props) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className="col-md-6 col-sm-12 mt-2">
      <Card className={classes.root}>
        <CardActionArea
          onClick={(e) => {
            history.push(props.link);
          }}
        >
          <CardMedia
            // className="img-fluid"
            image={props.banner}
            component="img"
            alt="Image for Setting"
            height="140"
          />
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography gutterBottom variant="h5" component="h2">
                {props.title}
              </Typography>
              <div className={classes.responsive + " row"}>
                <div className="col-1 pr-1 d-flex align-items-center justify-content-center">
                  <Typography variant="body2" color="primary" component="p">
                    <BusinessIcon className={classes.Icon} />
                  </Typography>
                </div>
                <div className="col-7 pl-2">
                  <Typography variant="body2" color="primary" component="p">
                    {props.optionTitle || "Calendar Slots Availability"} :
                  </Typography>
                </div>
                <div className="col-3 d-flex justify-content-center">
                  <Typography variant="body2" color="primary" component="p">
                    ON
                  </Typography>
                </div>
              </div>
            </CardContent>
          </div>
        </CardActionArea>
      </Card>
    </div>
  );
}
