import React from "react";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { Link } from "react-router-dom";

export default function ReportItem({ reportName, url }) {
  return (
    <div className="d-flex w-100 h-25 m-3 align-self-center">
      <AssessmentIcon style={{ fontSize: 50, color: "#0a8ed4" }} />
      <Link to={url} className="text-decoration-none ">
        <p className="font-weight-bold text-sm">{reportName}</p>
      </Link>
    </div>
  );
}
