import React from 'react';
import { Grid, Typography, Chip } from '@material-ui/core';

export const renderPlans = (providersPlans) => {
    if (!providersPlans || providersPlans.length === 0) {
        return null; // Return null if there are no plans
    }

    return (
        <Grid container justify="center" spacing={1}>
            <Grid item xs={12}>
                <Typography variant="body1" align="center">
                    Profesional también atiende en los Planes
                </Typography>
            </Grid>
            {providersPlans.map((element, index) => {
                if (element?.status === "active") {
                    return (
                        <Grid item key={index}>
                            <Chip
                                label={element?.plan?.name}
                                style={{
                                    margin: "0.1rem",
                                    color: "white",
                                    backgroundColor: "green"
                            }}
                            />
                        </Grid>
                    );
                }
                return null;
            })}
        </Grid>
    );
};
