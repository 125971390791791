import { useState } from "react";
import axios from "axios";
import useUserState from "./useUserState";

export default function useReport() {
  const userState = useUserState();
  const [loading, setLoading] = useState(false);

  const getReport = async (startDate, endDate) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/reports/appointments`,
        {
          headers: {
            Authorization: `Bearer ${userState[0].jwt}`,
          },
          params: {
            startDate,
            endDate,
          },
        }
      );
      setLoading(false);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
    return null;
  };

  return { getReport, loading };
}
