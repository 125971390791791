import React, { useEffect, useState } from "react";
import Axios from "axios";
import LoadingSpin from "../utils/Loading";
import DashboardMui from "../dashboard/DashboardMui";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import Grid from "@material-ui/core/Grid";
import PacienteSVG from "./../../images/paciente.svg";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import useUserState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    backgroundColor: "white",
    boxShadow: "5px 5px 16px 1px #1b3342",
    borderRadius: "10px",
  },
}));

function PatientsRegister() {
  const [email, setEmail] = useState("");
  const [phone1, setPhone1] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    visible: false,
    msgClass: "",
  });
  let history = useHistory();
  const classes = useStyles();
  const [jwt, setJwt] = useState("");
  const userState = useUserState();
  const { t, changeDirectLanguage } = useBDocTranslator();

  const clearForm = () => {
    document.getElementById("patient-form").reset();
    setDateOfBirth("");
    setEmail("");
    setGender("");
    setLastname("");
    setfirstName("");
    setPhone1("");
    setCountry("");
  };

  const handleClick = (e) => {
    // e.preventDefault();
    if (
      email &&
      phone1 &&
      firstName &&
      lastname &&
      dateOfBirth &&
      gender &&
      country
    ) {
      setLoading(true);
      Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/userPatient/createUserPatient`,
        {
          email,
          phone1,
          firstName,
          lastname,
          dateOfBirth,
          gender,
          password: "123456",
          country,
          rol_id: "2", // For Patients
          status_user_id: "1",
          created_by_userId: userState[0].user.id,
        },
        {
          headers: { Authorization: "Bearer " + userState[0].jwt }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          setLoading(false);
          setJwt(response.data.jwt);
          swal(
            "success",
            t("Patient Registered, an Email was sending to Patient"),
            "success"
          );
          setErrorMessage({
            error:
              "Success, Patient Registered, an Email was sending to Patient",
            visible: true,
            msgClass: "alert alert-success",
          });

          clearForm();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          setErrorMessage({
            error: error,
            visible: true,
            msgClass: "alert alert-danger",
          });
        });
    } else {
      setErrorMessage({
        error: "Error, Please fill the Form",
        visible: true,
        msgClass: "alert alert-danger",
      });
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    changeDirectLanguage();
  }, []);

  return (
    <DashboardMui
      sectionTitle={t("Patients")}
      description={t("Register new patient for your Practice")}
      icon={PacienteSVG}
    >
      <Container component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h6" variant="h6" className="mt-2">
            {errorMessage.visible && jwt === "" && (
              <p className="text-danger">{t("Please complete the fields")}</p>
            )}
            {jwt && <p className="text-success">{errorMessage.error}</p>}
          </Typography>
          <form
            className="row m-3 shadow-sm p-3 mb-5 bg-white rounded justify-content-center"
            id="patient-form"
          >
            <div className="form-group col-md-12">
              <TextField
                required
                margin="normal"
                label={t("Email")}
                variant="outlined"
                id="email"
                value={email || ""}
                autoComplete="email"
                fullWidth
                placeholder="name@example.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group col-md-6">
              <FormControl variant="outlined" className={classes.form}>
                <InputLabel required id="country">
                  {t("Country")}
                </InputLabel>
                <Select
                  labelId="country"
                  id="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  label={t("Country")}
                >
                  <MenuItem value="CRC">Costa Rica</MenuItem>
                  <MenuItem value="USA">USA</MenuItem>
                  <MenuItem value="JMC">Jamaica</MenuItem>
                  <MenuItem value="NIC">Nicaragua</MenuItem>
                  <MenuItem value="BAH">Bahamas</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="form-group col-md-6">
              <TextField
                required
                fullWidth
                margin="normal"
                label={t("Phone Number")}
                variant="outlined"
                id="phoneNumber"
                value={phone1 || ""}
                placeholder="888823456"
                onChange={(e) => setPhone1(e.target.value)}
              />
            </div>
            <div className="form-group col-md-6">
              <TextField
                margin="normal"
                label={t("First Name")}
                variant="outlined"
                id="firstName"
                fullWidth
                onChange={(e) => setfirstName(e.target.value)}
                required
                value={firstName || ""}
              />
            </div>
            <div className="form-group col-md-6">
              <TextField
                required
                fullWidth
                margin="normal"
                label={t("Last Name")}
                variant="outlined"
                id="lastname"
                onChange={(e) => setLastname(e.target.value)}
                value={lastname || ""}
              />
            </div>

            <div className="form-group col-md-6">
              <TextField
                variant="outlined"
                required
                fullWidth
                margin="normal"
                id="dateOfBirth"
                name="dateOfBirth"
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                label={t("Date Of Birth")}
                onChange={(e) => setDateOfBirth(e.target.value)}
                value={dateOfBirth || ""}
              />
            </div>
            <div className="form-group col-md-6">
              <FormControl variant="outlined" className={classes.form}>
                <InputLabel required id="gender">
                  {t("Gender")}
                </InputLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  label={t("Gender")}
                >
                  <MenuItem value={""}>{t("None")}</MenuItem>
                  <MenuItem value={"MALE"}>{t("Male")}</MenuItem>
                  <MenuItem value={"FEMALE"}>{t("Female")}</MenuItem>
                  <MenuItem value={"OTHER"}>{t("Other")}</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Grid className="m-3" container justify="flex-start">
              <Grid item>Default password: 123456</Grid>
            </Grid>
          </form>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center m-3">
            <div className="p-3">
              <Button
                variant="contained"
                color="primary"
                startIcon={<CheckCircleOutlineIcon />}
                onClick={handleClick}
              >
                {t("Save")}
              </Button>
            </div>

            <div className="p-3">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
              >
                {t("Cancel")}
              </Button>
            </div>
          </div>
          <div className="col-md-12">
            {loading && (
              <div className="d-flex justify-content-center mt-3">
                <LoadingSpin type="spokes" color="#1d3557" />
              </div>
            )}
          </div>
        </div>
      </Container>
    </DashboardMui>
  );
}

export default PatientsRegister;
