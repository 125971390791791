import React from "react";
import VerificationStep from "../patients/VerificationStep";
import VerificationStepProv from "../providers/VerificationStepProv";

function MainProviderVerification() {
  return (
      <div className="d-flex flex-column col-md-12">
        <VerificationStepProv />
      </div>
  );
}

export default MainProviderVerification;
