const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_CURRENT_USER":
      if (action.payload) {
        // take the user from session storage
        const localState = JSON.parse(
          window.sessionStorage.getItem("userState")
        );
        // clone the user object and add new element to user_images array with the new image
        const newUser = {
          ...localState.user,
          user_images: [...localState.user.user_images, action.payload],
        };
        state.user = newUser;
        localState.user = newUser;
        window.sessionStorage.setItem("userState", JSON.stringify(localState));
        return state;
      }
      break;
    default:
      return state;
  }
};

export default userUpdateReducer;
