import React, { useState, useEffect } from "react";
import IconConsult from "../../images/textConsult.svg";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MessageIcon from "@material-ui/icons/Message";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import DeleteIcon from "@material-ui/icons/Delete";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import "../../styles/consultText.css";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import MessageBody from "../providers/textconsultation/MessageBody";
import { db } from "../../config/firebase";
import swal from "sweetalert";
import useUserState from "../hooks/useUserState";
import DashboardPatient from "../dashboard/DashboardPatient";
import firebase from "firebase/app";
import "firebase/firestore";
import useMessages from "../hooks/useMessages";
import useBDocTranslator from "../hooks/useBDocTranslator";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "1000px",
    [theme.breakpoints.down("sm")]: {
      width: "800px",
    },
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: `calc(100% - ${drawerWidth}px)`,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: "white",
    width: drawerWidth,
    border: "2px solid #dfdfdf",
    borderRadius: "10px",
  },
  divider: {
    width: 220,
    backgroundColor: "#dfdfdf !important",
  },
  dividerList: {
    width: "90%",
    backgroundColor: "#dfdfdf !important",
    marginLeft: "20px",
  },
  listIcon: {
    color: "#8e8e8e !important",
  },
  deletedIcon: {
    color: "#f96d6d !important",
  },
  answeredIcon: {
    color: "#47cc68 !important",
  },
  receivedIcon: {
    color: "#0077B6 !important",
  },
  // necessary for content to be below app bar
  toolbar: {
    zIndex: "1",
  },
  message: {
    width: "100%",
    borderRadius: "5px",
  },
  dashboard: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    border: "2px solid #dfdfdf",
    borderRadius: "10px",
  },
  listMessage: {
    width: "100%",
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  messageHeader: {
    width: "100%",
    height: "40px",
  },
  button: {
    width: "100px",
  },
}));

function InboxPatient(props) {
  const classes = useStyles();
  const { window } = props;
  const theme = useTheme();
  const [dashboard, setDashboard] = useState("received");
  const [messageSelected, setMessageSelected] = useState([]);
  const [messageContent, setMessageContent] = useState("");
  const [messageResponse, setMessageResponse] = useState("");
  const [checked, setChecked] = useState([0]);
  const [messages, setMessages] = useState([]);
  const persistedMsg = JSON.parse(sessionStorage.getItem("msg"));
  const [showMessage, setShowMessage] = useState(false);
  const [senderName, setSenderName] = useState("");
  const [patientEmail, setPatientEmail] = useState("");
  const [replyMessageId, setReplyMessageId] = useState("");
  const { t, changeDirectLanguage } = useBDocTranslator();

  //Persisted User Hook
  const userLocalState = useUserState();

  //Persisted Messages Hook
  const { replyToMessage, loading, updateMessage } = useMessages();

  // const handleToggle = (value) => () => {
  //     const currentIndex = checked.indexOf(value);
  //     const newChecked = [...checked];

  //     if (currentIndex === -1) {
  //     newChecked.push(value);
  //     } else {
  //     newChecked.splice(currentIndex, 1);
  //     }

  //     setChecked(newChecked);
  // };

  const handleBack = () => {
    if (dashboard === "receivedA") {
      setMessageContent("");
      setDashboard("received");
      setShowMessage(!showMessage);
      setSenderName("");
    }
    if (dashboard === "readA") {
      setMessageContent("");
      setDashboard("read");
    }
    if (dashboard === "rejectedA") {
      setMessageContent("");
      setDashboard("rejected");
    }
    if (dashboard === "answeredA") {
      setMessageContent("");
      setDashboard("answered");
    }
    if (dashboard === "deletedA") {
      setMessageContent("");
      setDashboard("deleted");
    }
  };

  useEffect(() => {
    changeDirectLanguage();
    if (persistedMsg.length > 0) {
      const sortMessages = persistedMsg.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
      );

      setMessages(sortMessages);
    }
  }, []);

  const handleSetResponse = (message) => {
    setMessageResponse(message);
  };

  //Method for response the message
  // const handleResponseMessage = async () => {
  //   try {
  //     const msgObject = {
  //       send_by: userLocalState[0].user.email,
  //       send_to: patientEmail,
  //       createdAt: new Date(),
  //       status: "sended",
  //       content: messageResponse,
  //       sender_name: `${userLocalState[0].user.firstName} ${userLocalState[0].user.lastname}`,
  //     };
  //     if (messageResponse && replyMessageId && patientEmail) {
  //       //Update Patient Message Status to read by the Provider
  //       await db
  //         .collection("textConsultations-v2")
  //         .doc(replyMessageId)
  //         .update({
  //           modifiedAt: new Date(),
  //           action_request_status: "answered",
  //           action_response_status: "pending",
  //           response: firebase.firestore.FieldValue.arrayUnion(msgObject),
  //         });

  //       //cleaning params
  //       setMessageResponse("");
  //       setReplyMessageId("");
  //       setPatientEmail("");
  //       swal({
  //         title: "Success!",
  //         text: "Message sent",
  //         icon: "success",
  //         button: false,
  //       });
  //     } else {
  //       swal({
  //         title: "Failed!",
  //         text: "Plase write your message",
  //         icon: "error",
  //         button: false,
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     swal({
  //       title: "Failed!",
  //       text: "Something went wrong, please try againg later",
  //       icon: "error",
  //       button: false,
  //     });
  //   }
  // };

  const handleReply = async (send_to_userid) => {
    try {
      const msgObject = {
        send_by_userid: userLocalState[0].user.id,
        send_to_userid: send_to_userid,
        docId: replyMessageId,
        content: messageResponse,
        patient_receiver_status: "READ",
        provider_receiver_status: "UNREAD",
      };
      console.log(msgObject);
      if (messageResponse && replyMessageId) {
        //Update Patient Message Status to read by the Provider
        await replyToMessage(msgObject);

        //cleaning params
        setMessageResponse("");
        setReplyMessageId("");
        swal({
          title: t("Success!"),
          text: t("Message sent"),
          icon: "success",
          button: false,
        });
      } else {
        swal({
          title: "Error!",
          text: t("Plase write your message"),
          icon: "error",
          button: false,
        });
      }
    } catch (error) {
      console.error(error);
      swal({
        title: "Error!",
        text: t("Something went wrong, please try againg later"),
        icon: "error",
        button: false,
      });
    }
  };

  // const handleMarkRead = async () => {
  //   try {
  //     await db
  //       .collection(`${process.env.REACT_APP_MESSAGES_COLLECTION}`)
  //       .doc(replyMessageId)
  //       .update({
  //         patient_receiver_status: "READ",
  //       });
  //     swal({
  //       title: "Success!",
  //       text: "Message Marked as Read",
  //       icon: "success",
  //       button: false,
  //     });
  //   } catch (error) {
  //     swal({
  //       title: "Failed!",
  //       text: "Something went wrong, please try againg later",
  //       icon: "error",
  //       button: false,
  //     });
  //   }
  // };

  const handleMarkRead = async (send_to_userid) => {
    try {
      const msgObject = {
        send_to_userid: send_to_userid,
        docId: replyMessageId,
        patient_receiver_status: "READ",
      };

      await updateMessage(msgObject);

      swal({
        title: t("Success!"),
        text: t("Message Marked as Read"),
        icon: "success",
        button: false,
      });
    } catch (error) {
      swal({
        title: "Error!",
        text: t("Something went wrong, please try againg later"),
        icon: "error",
        button: false,
      });
    }
  };

  return (
    <DashboardPatient
      sectionTitle={t("Text Consultations")}
      description={t(
        "Here you can find the responses of your Text Consultations grouped in Conservations"
      )}
      icon={IconConsult}
    >
      <div id="drawerContainer">
        <div
          className={
            classes.root +
            " m-3 p-3 shadow w-30 rounded-lg-custom bg-white d-flex"
          }
        >
          <div className={classes.drawerPaper + " py-1 px-2"}>
            <List>
              <ListItem
                onClick={() => {
                  setDashboard("received");
                  setMessageContent("");
                }}
                button
                className={classes.toolbar + " lisItem"}
              >
                <ListItemIcon className={classes.receivedIcon}>
                  <MessageIcon />
                </ListItemIcon>
                <ListItemText primary={t("Received")} />
              </ListItem>

              <ListItem
                onClick={() => {
                  setDashboard("read");
                  setMessageContent("");
                }}
                button
                className={classes.toolbar + " lisItem"}
                disabled
              >
                <ListItemIcon className={classes.receivedIcon}>
                  <VisibilityIcon />
                </ListItemIcon>
                <ListItemText primary="Read" />
              </ListItem>

              <ListItem
                onClick={() => {
                  setDashboard("answered");
                  setMessageContent("");
                }}
                button
                className={classes.toolbar + " lisItem"}
                disabled
              >
                <ListItemIcon className={classes.answeredIcon}>
                  <CheckBoxIcon />
                </ListItemIcon>
                <ListItemText primary={t("Answered")} />
              </ListItem>

              <ListItem
                onClick={() => {
                  setDashboard("rejected");
                  setMessageContent("");
                }}
                button
                className={classes.toolbar + " lisItem"}
                disabled
              >
                <ListItemIcon className={classes.listIcon}>
                  <ThumbDownIcon />
                </ListItemIcon>
                <ListItemText primary={t("Rejected")} />
              </ListItem>
            </List>
            <Divider className={classes.divider} />
            <List>
              <ListItem
                onClick={() => {
                  setDashboard("deleted");
                  setMessageContent("");
                }}
                button
                className={classes.toolbar + " lisItem"}
                disabled
              >
                <ListItemIcon className={classes.deletedIcon}>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary={t("Deleted")} />
              </ListItem>
            </List>
          </div>
          {dashboard === "received" && (
            <div
              className={
                classes.dashboard +
                " mx-1 flex-column justify-content-start py-3"
              }
            >
              <List className={classes.listMessage}>
                {/* Received Messages List  */}
                {messages.length > 0 &&
                  messages.map((msg, idx) => {
                    /* const labelId = `checkbox-list-label-${value}`; */

                    return (
                      <>
                        <ListItem
                          key={idx}
                          role={undefined}
                          dense
                          button
                          onClick={() => {
                            setShowMessage(!showMessage);
                            setMessageContent(msg.content);
                            setSenderName(msg.sender_name);
                            setDashboard(`${dashboard}A`);
                            setPatientEmail(msg.send_by);
                            setReplyMessageId(msg.id);
                            setMessageSelected(msg);
                          }}
                          className={classes.message + " lisItem my-2 p-2"}
                        >
                          <ListItemIcon className={classes.receivedIcon}>
                            <OpenInNewIcon />
                          </ListItemIcon>
                          <div className="mr-2">
                            <span
                              className={`badge badge-${
                                msg?.patient_receiver_status === "UNREAD"
                                  ? "success"
                                  : "secondary"
                              }`}
                            >
                              {msg?.patient_receiver_status === "UNREAD"
                                ? t("new")
                                : t("read")}
                            </span>
                          </div>
                          <ListItemText
                            primary={`${t("From")}: (${
                              msg.other_user?.extendedData?.firstName
                            } ${
                              msg.other_user?.extendedData?.lastname
                            }) en (${new Date(
                              msg.createdAt
                            ).toUTCString()}) - ${t("Message Received")}`}
                          />
                        </ListItem>
                        <Divider className={classes.dividerList} />
                      </>
                    );
                  })}
              </List>
            </div>
          )}
          {showMessage && (
            <MessageBody
              handleBack={handleBack}
              message={messageContent}
              handleSetResponse={handleSetResponse}
              senderName={senderName}
              handleResponse={handleReply}
              messageSelected={messageSelected}
              handleMarkRead={handleMarkRead}
            />
          )}

          {/* For read Messages */}
          {dashboard === "read" && (
            <div
              className={
                classes.dashboard +
                " mx-1 flex-column justify-content-start py-3"
              }
            >
              <List className={classes.listMessage}>
                {[0, 1, 2, 3, 4, 5, 6].map((value) => {
                  return (
                    <>
                      <ListItem
                        key={value}
                        role={undefined}
                        dense
                        button
                        onClick={() => {
                          setMessageContent(`${value}`);
                          setDashboard(`${dashboard}A`);
                        }}
                        className={classes.message + " lisItem my-2 p-2"}
                      >
                        <ListItemIcon className={classes.receivedIcon}>
                          <VisibilityIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${t("From")}: (${t("User")}) - ${t(
                            "Message Read"
                          )} ${value}`}
                        />
                      </ListItem>
                      <Divider className={classes.dividerList} />
                    </>
                  );
                })}
              </List>
            </div>
          )}
          {dashboard === "rejected" && (
            <div
              className={
                classes.dashboard +
                " mx-1 flex-column justify-content-start py-3"
              }
            >
              <List className={classes.listMessage}>
                {[0, 1, 2, 3, 4, 5].map((value) => {
                  return (
                    <>
                      <ListItem
                        key={value}
                        role={undefined}
                        dense
                        button
                        onClick={() => {
                          setMessageContent(`${value}`);
                          setDashboard(`${dashboard}A`);
                        }}
                        className={classes.message + " lisItem my-2 p-2"}
                      >
                        <ListItemIcon className={classes.listIcon}>
                          <ThumbDownIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`From: (User) - Message rejected ${value}`}
                        />
                      </ListItem>
                      <Divider className={classes.dividerList} />
                    </>
                  );
                })}
              </List>
            </div>
          )}
          {dashboard === "answered" && (
            <div
              className={
                classes.dashboard +
                " mx-1 flex-column justify-content-start py-3"
              }
            >
              <List className={classes.listMessage}>
                {[0, 1, 2, 3, 4].map((value) => {
                  return (
                    <>
                      <ListItem
                        key={value}
                        role={undefined}
                        dense
                        button
                        onClick={() => {
                          setMessageContent(`${value}`);
                          setDashboard(`${dashboard}A`);
                        }}
                        className={classes.message + " lisItem my-2 p-2"}
                      >
                        <ListItemIcon className={classes.answeredIcon}>
                          <CheckBoxIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`From: (User) - Message Answered ${value}`}
                        />
                      </ListItem>
                      <Divider className={classes.dividerList} />
                    </>
                  );
                })}
              </List>
            </div>
          )}
          {dashboard === "deleted" && (
            <div
              className={
                classes.dashboard +
                " mx-1 flex-column justify-content-start py-3"
              }
            >
              <List className={classes.listMessage}>
                {[0, 1, 2].map((value) => {
                  return (
                    <>
                      <ListItem
                        key={value}
                        role={undefined}
                        dense
                        button
                        onClick={() => {
                          setMessageContent(`${value}`);
                          setDashboard(`${dashboard}A`);
                        }}
                        className={classes.message + " lisItem my-2 p-2"}
                      >
                        <ListItemIcon className={classes.deletedIcon}>
                          <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`From: (User) - Message Deleted ${value}`}
                        />
                      </ListItem>
                      <Divider className={classes.dividerList} />
                    </>
                  );
                })}
              </List>
            </div>
          )}
        </div>
      </div>
    </DashboardPatient>
  );
}

export default InboxPatient;
