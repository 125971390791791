import React from "react";
import { Avatar, makeStyles, Button } from "@material-ui/core";
import temporalLogo from "../../../images/best-doc-logo.png";
import Logo from "../../../images/logo-bdoc.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function ContentCardProfile() {
  const classes = useStyles();
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <Avatar
        alt="company logo"
        src={Logo}
        className="avatar-company-custom"
      />
      <div className="mt-3">
        <Button variant="contained" color="primary" disabled={true}>
          Update Profile
        </Button>
      </div>
    </div>
  );
}
