import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import swal from "sweetalert";
import "../../styles/customs.css";
import PatientProfile from "./PatientProfile";
//Redux
import {useSelector} from "react-redux";
import UseUserState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";

function PatientEdition(props) {
    // let history = useHistory();

    // const handleBack = () => {
    //   history.goBack();
    // };
    const history = useHistory();
    const params = useParams();
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [firstName, setfirstName] = useState("");
    const [lastname, setLastname] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [gender, setGender] = useState("");
    const [identification, setIdentification] = useState("");
    const [identificationType, setIdentificationType] = useState("");
    const person_type = "PAT";
    const [loading, setLoading] = useState(true);
    const [imgLoading, setImgLoading] = useState(true);
    const [country, setCountry] = useState("");
    const [nationality, setNationality] = useState("");
    const [patientId, setPatientId] = useState("");
    const [imgPatientLoading, setImgPatientLoading] = useState(true);
    const [imgProfile, setImgProfile] = useState({
        profile: "",
        exist: null,
    });
    const [countries, setCountries] = useState([]);
    const [insuranceProvider, setInsuranceProvider] = useState("");
    const [insurancePolicyNumber, setInsurancePolicyNumber] = useState("");
    const [patientData, setPatientData] = useState([]);
    const [healthPlan, setHealthPlan] = useState("");
    const [healthPlanQuestion, setHealthPlanQuestion] = useState("NO");

    const [data, setData] = useState("");
    const [openAlertImg, setOpenAlertImg] = useState(false);
    const [errorMessage, setErrorMessage] = useState({
        error: "",
        visible: false,
        msgClass: "",
    });

    const [userAvatar, setUserAvatar] = useState(null);

    const {t} = useBDocTranslator();

    // Hooks that retrieve the user data from LocalStorage
    const userState = UseUserState();

    const newUserImageState = useSelector((state) => state.newUserImage);

    const clearForm = () => {
        document.getElementById("patient-form").reset();
        setDateOfBirth("");
        setEmail("");
        setGender("");
        setLastname("");
        setfirstName("");
        setPhone("");
    };

    // ----------------- COUNTRIES ----------------------

    const findCountries = () => {
        Axios.get(`${process.env.REACT_APP_API_URL_BASE}/countries/findAll`)
            .then((res) => {
                setCountries(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // -------------------------- ALERT -----------------------------

    const handleAlertImg = () => {
        setOpenAlertImg(true);
    };

    const handleCloseAlertImg = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenAlertImg(false);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    useEffect(() => {
        async function fetchPatientInfo(patientId) {
            const response = await Axios.get(
                `${process.env.REACT_APP_API_URL_BASE}/patient/findById/${patientId}`,
                {
                    headers: {
                        Authorization: "Bearer " + userState[0].jwt,
                    },
                }
            );
            setPatientId(response.id);
            setPatientData(response.data);
            return response;
        }

        //Functions Calls
        async function fetchData(id) {
            const patientResult = await fetchPatientInfo(id);
            setUserAvatar(
                patientResult?.data?.user?.user_images.at(-1)?.path_resource
            );
            //Set Data to Controls
            loadDataToControls(patientResult.data);
        }

        // Fetching Data of Patient
        fetchData(params.id);
        // Fetching of Countries
        findCountries();
    }, []);

    // ----------------------- STYLES CLASSES --------------------

    const useStyles = makeStyles((theme) => ({
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(2),
        },
    }));

    const theme = createMuiTheme({
        palette: {
            secondary: {
                main: "#e33371",
            },
        },
    });
    const classes = useStyles();

    // ----------------------  FUNC SET STATES --------------------

    function loadDataToControls(data) {
        if (data) {
            const {
                user: {email},
                firstName,
                lastname,
                identification,
                dateOfBirth,
                gender,
                country,
                nationality,
                id,
                phone1,
                identificationType,
                insurance_policy_number,
                insurance_provider,
                affiliations,
                plan_name
            } = data;
            setEmail(email);
            setfirstName(firstName);
            setLastname(lastname);
            setIdentification(identification);
            setDateOfBirth(dateOfBirth && dateOfBirth.split("T")[0]);
            setGender(gender);
            setCountry(country);
            setNationality(nationality);
            setPatientId(id);
            setPhone(phone1);
            setIdentificationType(identificationType);
            setInsurancePolicyNumber(insurance_policy_number);
            setInsuranceProvider(insurance_provider);
            setHealthPlanQuestion(() => {
                if(affiliations.some(affiliation => affiliation.status === "active") || plan_name ){
                    return "YES"
                }
                return "NO"
            });
            setHealthPlan(()=>{
                return affiliations.find(affiliation => affiliation.status === "active")?.plan_name || plan_name || "PLAN NOT FOUND"
            })
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        if (
            phone &&
            firstName &&
            lastname &&
            dateOfBirth &&
            gender &&
            patientId &&
            identification &&
            country &&
            insuranceProvider &&
            insurancePolicyNumber
        ) {
            setLoading(true);
            Axios.put(
                `${process.env.REACT_APP_API_URL_BASE}/userPatient/updateAndConfirm`,
                {
                    phone1: phone,
                    firstName,
                    lastname,
                    dateOfBirth,
                    gender,
                    identificationType,
                    identification,
                    country,
                    nationality,
                    id: params.id,
                    insurance_policy_number: insurancePolicyNumber,
                    insurance_provider: insuranceProvider,
                    health_plan: healthPlan
                },
                {
                    headers: {Authorization: "Bearer " + userState[0].jwt}, //the jwt is a variable which holds the token
                }
            )
                .then((response) => {
                    setLoading(false);
                    swal({
                        tittle: "Success!",
                        text: "Changes Saved",
                        icon: "success",
                        button: false,
                        timer: 3000,
                    });
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    setErrorMessage({
                        error: error,
                        visible: true,
                        msgClass: "alert alert-danger d-flex justify-content-center",
                    });
                });
        } else {
            setErrorMessage({
                error: "Error, Please fill the Form",
                visible: true,
                msgClass: "alert alert-danger ",
            });
        }
    };

    return (
        <>
            <div>
                <div className={`shadow-lg p-3 mb-5 bg-white rounded ${props?.source !== 'ADMIN' && 'w-vw-100'}`}>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-4 d-flex justify-content-center">
                            {/* TODO: change this component to use in a different component */}
                            <PatientProfile
                                email={email}
                                imgLoading={imgLoading}
                                disableButton={true}
                                userImageURL={userAvatar}
                            />
                        </div>
                        <div className="col-md-9">
                            <form className="row" id="patient-form">
                                <div className="form-group col-md-6">
                                    <TextField
                                        margin="normal"
                                        label={t("First Name")}
                                        variant="outlined"
                                        id="firstName"
                                        fullWidth
                                        onChange={(e) => setfirstName(e.target.value)}
                                        required
                                        value={firstName || ""}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <TextField
                                        required
                                        fullWidth
                                        margin="normal"
                                        label={t("Last Name")}
                                        variant="outlined"
                                        id="lastname"
                                        onChange={(e) => setLastname(e.target.value)}
                                        value={lastname || ""}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <TextField
                                        required
                                        margin="normal"
                                        label={t("Your Email")}
                                        variant="outlined"
                                        id="email"
                                        value={email || ""}
                                        autoComplete="email"
                                        fullWidth
                                        disabled={email && true}
                                        placeholder="abc@def.com"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="form-group col-md-8">
                                    <TextField
                                        required
                                        fullWidth
                                        margin="normal"
                                        label={t("Phone Number")}
                                        variant="outlined"
                                        id="phoneNumber"
                                        value={phone || ""}
                                        placeholder="888823456"
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </div>
                                <div className="form-group col-md-4 ">
                                    <TextField
                                        id="country"
                                        required
                                        select
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        placeholder="Select"
                                        label={t("Country")}
                                        value={country || "select"}
                                        onChange={(e) => setCountry(e.target.value)}
                                    >
                                        {countries &&
                                            countries.map((country) => {
                                                return (
                                                    <MenuItem value={country.code}>
                                                        {country.name}
                                                    </MenuItem>
                                                );
                                            })}
                                    </TextField>
                                </div>
                                <div className="form-group col-md-3">
                                    <TextField
                                        id="identificationType"
                                        required
                                        select
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        placeholder="Select"
                                        label={t("Identification Type")}
                                        onChange={(e) => setIdentificationType(e.target.value)}
                                        value={identificationType || "select"}
                                    >
                                        <MenuItem value="DNI">{t("DNI")}</MenuItem>
                                        <MenuItem value="PASSPORT">{t("Passport")}</MenuItem>
                                        <MenuItem value="other">{t("Other")}</MenuItem>
                                    </TextField>
                                </div>

                                <div className="form-group col-md-9">
                                    <TextField
                                        required
                                        fullWidth
                                        margin="normal"
                                        label={t("Identification number")}
                                        variant="outlined"
                                        id="identification"
                                        onChange={(e) => setIdentification(e.target.value)}
                                        value={identification || ""}
                                    />
                                </div>

                                <div className="form-group col-md-4">
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        margin="normal"
                                        id="dateOfBirth"
                                        name="dateOfBirth"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="date"
                                        label={t("Date Of Birth")}
                                        onChange={(e) => setDateOfBirth(e.target.value)}
                                        value={dateOfBirth || ""}
                                    />
                                </div>
                                <div className="form-group col-md-8">
                                    <FormControl variant="outlined" className={classes.form}>
                                        <InputLabel required id="gender">
                                            {t("Gender")}
                                        </InputLabel>
                                        <Select
                                            labelId="gender"
                                            id="gender"
                                            value={gender}
                                            onChange={(e) => setGender(e.target.value)}
                                            label={t("Gender")}
                                        >
                                            <MenuItem value={""}>{t("None")}</MenuItem>
                                            <MenuItem value={"MALE"}>{t("Male")}</MenuItem>
                                            <MenuItem value={"FEMALE"}>{t("Female")}</MenuItem>
                                            <MenuItem value={"OTHER"}>{t("Other")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {/* Insurance Information */}
                                <div className="form-group col-md-4">
                                    <TextField
                                        required
                                        fullWidth
                                        margin="normal"
                                        label={t("Insurance Company")}
                                        variant="outlined"
                                        id="insurance-provider"
                                        onChange={(e) => setInsuranceProvider(e.target.value)}
                                        value={insuranceProvider || ""}
                                    />
                                </div>

                                <div className="form-group col-md-4">
                                    <TextField
                                        required
                                        fullWidth
                                        margin="normal"
                                        label={t("Insurance Policy Number")}
                                        variant="outlined"
                                        id="insurance-policy-number"
                                        onChange={(e) => setInsurancePolicyNumber(e.target.value)}
                                        value={insurancePolicyNumber || ""}
                                    />
                                </div>
                                {/* End Insurance Information */}

                                {/*Plan information */}
                                {/*<div className="form-group col-md-4">*/}
                                {/*  <TextField*/}
                                {/*      fullWidth*/}
                                {/*      margin="normal"*/}
                                {/*      label={t("Healthcare Plan")}*/}
                                {/*      variant="outlined"*/}
                                {/*      id="healthcare-plan"*/}
                                {/*      onChange={(e) => setHealthPlan(e.target.value)}*/}
                                {/*      value={healthPlan || ""}*/}
                                {/*      disabled={true}*/}
                                {/*  />*/}
                                {/*</div>*/}

                                <div className="form-group col-md-4">
                                    <FormControl variant="outlined" className={classes.form}
                                                 disabled={true}>
                                        <InputLabel id="healthcare-plan">
                                            {t("Healthcare Plan")}
                                        </InputLabel>
                                        <Select
                                            labelId="healthcare-plan"
                                            id="healthcare-plan"
                                            value={healthPlan || "None"}
                                            onChange={(e) => setHealthPlan(e.target.value)}
                                            label={t("Healthcare Plan")}
                                        >
                                            <MenuItem value={"PLAN NOT FOUND"}>{t("None")}</MenuItem>
                                            <MenuItem value={healthPlan}>{healthPlan}</MenuItem>

                                        </Select>
                                    </FormControl>
                                </div>
                                {
                                    props?.source !== "ADMIN" &&
                                    (
                                        <Grid container justify="flex-start" className="m-3">
                                            <Grid item>
                                                <Link
                                                    href={"/patient/login/recover/" + userState[0].jwt}
                                                    variant="body2"
                                                >
                                                    {t("Change Password")}
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                <div className="col-md-12 mt-3 d-flex justify-content-center ">
                                    {errorMessage.visible && (
                                        <div className={errorMessage.msgClass} role="alert">
                                            {t(errorMessage.error)}
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-center ">
                        <div className="p-3">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<CheckCircleOutlineIcon/>}
                                onClick={handleSave}
                            >
                                {t("Save")}
                            </Button>
                        </div>
                        <div className="p-3">
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<CancelIcon/>}
                                onClick={(e) => history.goBack()}
                            >
                                {t("Cancel")}
                            </Button>
                        </div>
                    </div>
                </div>
                <Snackbar
                    open={openAlertImg}
                    autoHideDuration={5000}
                    onClose={handleCloseAlertImg}
                >
                    <Alert onClose={handleCloseAlertImg} severity="success">
                        {t("Success Profile Image Updated")}
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
}

export default PatientEdition;
