export const setUserAction = (data) => {
  return {
    type: "SET_CURRENT_USER",
    payload: data,
  };
};

export const setUserImageProfile = (data) => {
  return {
    type: "SET_USER_IMAGE_PROFILE",
    payload: data,
  };
};
export const setNewUserImageProfile = (data) => {
  return {
    type: "SET_NEW_USER_IMAGE_PROFILE",
    payload: data,
  };
};

export const setEHRdata = (data) => {
  return {
    type: "SET_EHR_DATA",
    payload: data,
  };
};

export const updateCurrentUser = (data) => {
  return {
    type: "UPDATE_CURRENT_USER",
    payload: data,
  };
};

export const setAffiliateId = (data) => {
  return {
    type: "SET_AFFILIATE_ID",
    payload: data,
  };
};

export const setRegistrationData = (data) => {
    return {
        type: "SET_REGISTRATION_DATA",
        payload: data,
    };
};


export const setUpdateState = (data) => {
    return {
        type: "UPDATE_USER_STATE",
        payload: data,
    };
}