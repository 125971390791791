import React, { useEffect, useState } from "react";
import DashboardPatient from "../dashboard/DashboardPatient";
import MailSVG from "../../images/mail.svg";
import SearchModuleConsult from "../patient-portal/SearchModuleConsult";
import Axios from "axios";
import swal from "sweetalert";
import Loading from "../utils/Loading";
import { Button } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import UserLocalState from "./../hooks/useUserState";
import { useLocation } from "react-router-dom";
import useMessages from "../hooks/useMessages";
import useBDocTranslator from "../hooks/useBDocTranslator";

function TextConsultation() {
  const [providers, setProviders] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [specialties, setSpecialties] = useState([]);
  const [cancel, setCancel] = useState(false);
  const [consult, setConsult] = useState("");
  const [provider, setProvider] = useState(null);
  const [providerSelected, setProviderSelected] = useState({
    name: "",
    title: "",
  });

  const { createMessage, loading } = useMessages();

  const location = useLocation();
  const providerState = location.state;

  const { enqueueSnackbar } = useSnackbar();

  const useStyles = makeStyles((theme) => ({
    consultText: {
      width: "800px",
    },
    buttonStyle: {
      width: "100%",
    },
  }));
  const classes = useStyles();

  const userLocalState = UserLocalState();
  const { t, changeDirectLanguage } = useBDocTranslator();

  // filter the providers by providerId
  const filterProviders = (providerId, dataProvider) => {
    const filteredProviders = dataProvider.filter((provider) => {
      return provider.id === providerId;
    });
    return filteredProviders;
  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/provider/enabled`, {
      headers: { Authorization: "Bearer " + userLocalState[0].jwt }, //the jwt is a variable which holds the token
    })
      .then((response) => {
        setLoadingData(false);
        if (providerState?.providerId) {
          setProviders(
            filterProviders(providerState.providerId, response.data)
          );
        } else {
          setProviders(response.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/specialty/findAll`, {
      headers: { Authorization: "Bearer " + userLocalState[0].jwt }, //the jwt is a variable which holds the token
    })
      .then((response) => {
        setLoadingData(false);
        setSpecialties(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleState = (item) => {
    setProvider(item);
  };

  const handleRefresh = (e) => {
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/provider/enabled`, {
      headers: { Authorization: "Bearer " + userLocalState[0].jwt }, //the jwt is a variable which holds the token
    })
      .then((response) => {
        setLoadingData(false);
        setProviders(response.data);
      })
      .catch((err) => console.log(err));
  };

  const handleSearch = (country, specialty, provider) => {
    const countryParam = country?.code;
    const specialtyParam = specialty?.speciality_name;
    const providerParam = provider?.firstName; // consider the lastname aswell

    if (country || specialty || provider) {
      Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/customSearch/provider?country=${
          countryParam || ""
        }&specialty=${specialtyParam || ""}&providerName=${
          providerParam || ""
        }`,
        {
          headers: { Authorization: "Bearer " + userLocalState[0].jwt }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          setProviders(response.data);
        })
        .catch((err) => {
          console.error(err);
          swal({
            title: "Error!",
            text: t("Error fetching Data for Selected Country"),
            icon: "error",
            button: false,
          });
        });
    } else {
      handleRefresh();
    }
  };

  /** Notify provider when Message was sent */
  const notifyProvider = async (userId) => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/textConsultation/notifyProvider`,
        {
          userId,
        },
        {
          headers: { Authorization: "Bearer " + userLocalState[0].jwt }, //the jwt is a variable which holds the token
        }
      );
      console.log(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSend = async () => {
    try {
      const msgObject = {
        send_by_userid: userLocalState[0].user.id,
        send_to_userid: provider?.user_id,
        content: consult,
      };
      if (provider && consult) {
        await createMessage(msgObject);
        setConsult("");
        // enqueueSnackbar("Message was Sent!", {
        //   variant: "success",
        // });
        await notifyProvider(provider.user_id);
        swal({
          title: t("Success"),
          text: t("Message was Sent!"),
          icon: "success",
          button: false,
        });
      } else {
        enqueueSnackbar(t("Error, Please fill the Form"), {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "Something 's went wrong sending message, Contact support",
        {
          variant: "error",
        }
      );
    }
  };
  console.log("provider state", provider);
  return (
    <>
      <DashboardPatient>
        <div className="d-flex flex-column">
          <article className="m-3 p-3 shadow w-30 rounded-lg-custom bg-white">
            <h1 className="font-weight-bold">
              {t("Text Consultation")}{" "}
              <span>
                <img className="sunshine-icon" src={MailSVG} alt="mail" />
              </span>
            </h1>
          </article>

          <div>
            <div className="flex-column mt-4 shadow">
              <section className="p-3">
                <h3 className="m-2 font-weight-light">
                  {t("Secure Message")}:
                </h3>
                <p className="p-2">
                  {t(
                    "Please use the filters to select your prefered Healthcare Provider"
                  )}
                </p>
              </section>
              <div>
                <SearchModuleConsult
                  handleSearch={handleSearch}
                  handleRefresh={handleRefresh}
                  specialties={specialties}
                  providers={providers}
                  cancel={cancel}
                  setCancel={setCancel}
                  preselectedProvider={providerSelected}
                  setProvider={setProvider}
                  provider={provider}
                />
              </div>

              {provider && (
                <div className={classes.consultText + " d-flex ml-5 mb-5"}>
                  <h5>
                    {t("Cost")}: {t("Free")}
                  </h5>
                </div>
              )}
              <div className="d-flex m-4 px-3">
                <TextField
                  id="Consult"
                  placeholder={t("Write your Consult here")}
                  multiline
                  // className={classes.consultText}
                  fullWidth
                  rows={8}
                  value={consult}
                  variant="outlined"
                  onChange={(e) => setConsult(e.target.value)}
                  disabled={provider === null}
                />
              </div>
              <div
                className={
                  "d-flex justify-content-end pb-4 pt-3 " + classes.buttonStyle
                }
              >
                {provider && (
                  <Button
                    onClick={handleSend}
                    variant="contained"
                    color="primary"
                    className="mr-4 p-2"
                    disabled={consult === ""}
                  >
                    {t("Send")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </DashboardPatient>
    </>
  );
}

export default TextConsultation;
