import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import DashboardMui from "../dashboard/DashboardMui";
import PatientsTable from "../utils/TableUI";
import LoadingSpin from "../utils/Loading";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TablePatients from "../utils/TablePatients";
import useUserState from "../hooks/useUserState";
import HeadingResume from "./../widgets/HeadingResume";
import PacienteSVG from "./../../images/paciente.svg";
import useBDocTranslator from "../hooks/useBDocTranslator";

function PatientsList(props) {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const { t } = useBDocTranslator();

  //Action to send to Button
  const handleAddButton = () => {
    history.push("/admin/patientRegister");
  };

  //Function for Edit the selected Provider
  const handleClickEdit = (row) => {
    history.push("/admin/patient/edit/" + row.id);
  };

  //extracting the persisted data on Redux and localStorage
  const userLocalState = useUserState();

  useEffect(() => {
    setLoading(true);
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/patient/findByProviderId?providerId=${userLocalState[0].extendedData.id}&createdBy=${userLocalState[0].user.id}`,
      {
        headers: {
          Authorization: "Bearer " + userLocalState[0].jwt, //the jwt is a variable which holds the token
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <DashboardMui
      sectionTitle={t("Patients List")}
      icon={PacienteSVG}
      description={t("Details of Patients Registered on Practice or Tenant")}
    >
      <div className="d-flex flex-column col-md-12">
        <div className="d-flex justify-content-end col-md-12 m-3 ">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddButton}
          >
            {t("Add Patient")}
          </Button>
        </div>
        {data ? (
          <TablePatients
            data={data}
            clickEvent={handleClickEdit}
            translator={t}
          />
        ) : (
          <LoadingSpin type="spokes" color="#023E8A" />
        )}
      </div>
    </DashboardMui>
  );
}

export default PatientsList;
