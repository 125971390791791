import userReducer from "./userReducer";
import userImageReducer from "./userImageReducer";
import userNewImageReducer from "./userNewImageReducer";
import EHRReducer from "./EHRReducer";
import {combineReducers} from "redux";
import userUpdateReducer from "./userUpdateReducer";
import userUpdateAffiliateReducer from "./userAddAffiliateId";
import userRegistrationDataReducer from "./userRegistrationDataReducer";
import userUpdateState from "./userUpdateState";


const appReducers = combineReducers({
    currentUser:userReducer,
    userImage:userImageReducer,
    newUserImage:userNewImageReducer,
    ehr:EHRReducer,
    updateUser:userUpdateReducer,
    updateUserAffiliate:userUpdateAffiliateReducer,
    userRegistrationData:userRegistrationDataReducer,
    updateState : userUpdateState
});

export default appReducers;
