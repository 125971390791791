import React, { useState } from "react";
import AdminLoginForm from "./AdminLoginForm";
import Paper from "@material-ui/core/Paper";
import Axios from "axios";
import { useHistory } from "react-router-dom";
/** Redux resources */
import { setUserAction } from "../../../actions";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

export default function LoginAdministrator() {
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  //Distpach of Redux
  const distpach = useDispatch();

  const TenatLogo = process.env.REACT_APP_TENANT_LOGO;

  const handleLogin = (email, password) => {
    if (email && password) {
      setLoading(true);
      Axios.post(`${process.env.REACT_APP_API_URL_BASE}/session/admin/auth`, {
        email,
        password,
      })
        .then((response) => {
          if (response.status === 200) {
            //distpach values to main store
            setLoading(false);
            console.log(response);
            distpach(setUserAction(response.data));
            redirectUser(response?.data?.user);
          }
        })
        .catch((error) => {
          swal(
            "Something went wrong",
            "Description: " + error?.response?.data?.message,
            "error"
          );
          console.log(error?.response);
          setLoading(false);
        });
    } else {
      swal(
        "Atention",
        "Emptys field, please enter your email and password ",
        "error"
      );
    }
  };

  // if the user role is call-center redirect to /users/op/home if not redirect to /users/home
  const redirectUser = (user) => {
    if (user?.role?.name === "CALL-CENTER") {
      history.push("/users/op/home");
    } else {
      history.push("/users/home");
    }
  };

  return (
    <div className="container-fluid">
      <Paper elevation={2} className="row p-3 vh-100">
        <div className="col-md-4">
          <img
            className="img-responsive w-20-custom"
            src={TenatLogo}
            alt="company logo"
          />

          <h1 className="font-weight-bolder title-admin-form mt-3 mb-2">
            Sign In to your account
          </h1>
          <AdminLoginForm loading={loading} handleLogin={handleLogin} />
        </div>
        <div className="col-md-8 d-flex justify-content-center login-side-ads">
          {/* <img
            className="img-responsive rounded-lg-custom shadow-sm"
            src="https://res.cloudinary.com/brackets-it/image/upload/w_1100,h_750/v1627782792/walpaper-medical_jgtgk3.jpg"
            alt="demostration-side"
          /> */}
        </div>
      </Paper>
    </div>
  );
}
