import React, { useState, useEffect } from "react";
import DashboardPatient from "../dashboard/DashboardPatient";
import ProviderCard from "../widgets/w-providers/ProviderCard";
import SearchModule from "./SearchModule";
import Axios from "axios";
import Loading from "../utils/Loading";
import { resizeByLink } from "./../utils/ResizeLinkCloudinary";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import useUserState from "../hooks/useUserState";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function HealthCareProviders() {
  const [providers, setProviders] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [specialties, setSpecialties] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const userLocalState = useUserState();

  //this method manage the backdrop status
  const handleClose = () => {
    setOpen(false);
  };

  const jwtSession = userLocalState[0].jwt;

  //Method for search all providers
  const fetchAllProviders = () => {
    try {
      setOpen(true);
      Axios.get(`${process.env.REACT_APP_API_URL_BASE}/provider`, {
        headers: { Authorization: "Bearer " + jwtSession }, //the jwt is a variable which holds the token
      })
        .then((response) => {
          setOpen(false);
          setLoadingData(false);
          setProviders(response.data);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  //Effect to fetch providers
  useEffect(() => {
    fetchAllProviders();
  }, []);

  //Effect to fetch Specialty data
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/specialty/findAll`, {
      headers: { Authorization: "Bearer " + jwtSession }, //the jwt is a variable which holds the token
    })
      .then((response) => {
        setLoadingData(false);
        setSpecialties(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSearch = (country, specialty, provider) => {
    const countryParam = country?.code;
    const specialtyParam = specialty?.speciality_name;
    const providerParam = provider?.firstName; // consider the lastname as well

    if (!countryParam && !specialtyParam && !providerParam) {
      fetchAllProviders();
    } else {
      setOpen(true);

      Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/customSearch/provider?country=${
          countryParam || ""
        }&specialty=${specialtyParam || ""}&providerName=${
          providerParam || ""
        }`,
        {
          headers: { Authorization: "Bearer " + jwtSession }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          setOpen(false);

          setProviders(response.data);
        })
        .catch((err) => {
          console.error(err);
          setOpen(false);

          swal({
            title: "Failed!",
            text: "Error fetching Data for Selected Country",
            icon: "error",
            button: false,
          });
        });
    }
  };

  return (
    <DashboardPatient>
      <div>
        <SearchModule
          handleSearch={handleSearch}
          specialties={specialties}
          providers={providers}
        />
      </div>
      <div>
        {loadingData && <Loading color="#3788d8d4" />}
        {providers &&
          providers.map((item) => {
            if (item.status_provider.status_name === "APPROVED") {
              const fullname = `${item.firstName} ${item.lastname}`;
              let profileImageURL;

              if (item.user.user_images.length > 0) {
                profileImageURL = resizeByLink(
                  item.user.user_images[0].path_resource,
                  "upload"
                );
              }

              const specialitiesParsed = Array.of(
                item.medical_speciality?.speciality_name
              );
              return (
                <ProviderCard
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  fullname={fullname}
                  profileImage={profileImageURL}
                  specialty={specialitiesParsed}
                  providerId={item.id}
                  country={item.country}
                  providerImg={item.profile_image}
                  summary={item.summary}
                />
              );
            }
          })}
      </div>
    </DashboardPatient>
  );
}
