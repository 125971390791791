import React from "react";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function CheckoutResume(props) {
  const { t } = useBDocTranslator();

  return (
    <div className="col-md-6 col-sm-12">
      <section>
        <h2 className="font-weight-bold">{t("Order Resume")}</h2>
        <p>{t("Here you have the summary of your appontment to pay.")}</p>
        <div className="checkout-resume-table">
          <p className="mt-2">
            {t("Service")}
            {": "}
            <span className="font-weight-bold text-primary">
              {props.serviceType}
            </span>{" "}
          </p>
          <hr className="checkout-divider" />
          <p>
            {t("Duration")}
            {": "}
            <span className="font-weight-bold text-primary">
              {props.serviceDuration} min
            </span>{" "}
          </p>
          <hr className="checkout-divider" />

          <p>
            {t("Total to Pay")} (USD):{" "}
            <span className="font-weight-bold text-primary">
              ${props.price}
            </span>
          </p>
        </div>
      </section>
    </div>
  );
}
