import React,{useEffect,useState} from "react";
import DashboardPatient from "../dashboard/DashboardPatient";
import AppointmentCard from "../widgets/AppointmentCard";
import HeadingResume from "../widgets/HeadingResume";
import AppointmentIcon from "./../../images/appointment-icon.svg";
import Loading from "../utils/Loading";
import {resizeByLink} from "../utils/ResizeLinkCloudinary";
import moment from "moment";
import useUserState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";
import {useAppointmentsByPatient} from "../../services/services";

export default function AppointmentsList() {
  const [appointments, setAppointments] = useState([]);
  const userState = useUserState();
  const {getAppointmentsByPatient, loadingData} = useAppointmentsByPatient(userState[0]?.extendedData?.id, userState[0]?.jwt);
  const { t } = useBDocTranslator();

  //Options for manage the Format of dates
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const jwtSession = userState[0].jwt;

  useEffect(() => {
      const getAppointments = async () => {
        try{
          const appointments = await getAppointmentsByPatient();
          setAppointments(appointments);
        }catch(e){
            console.log(e);
        }
      }
        getAppointments();

  }, []);

  const lastOrderRegister = (serviceOrders) => {
    if (serviceOrders.length > 0) {
      return serviceOrders.sort((a, b) => b.id - a.id);
    }
    return serviceOrders;
  };

  return (
    <DashboardPatient>
      <div>
        <HeadingResume
          title={t("Your Appointments")}
          description={t(
            "In this section you can see your previous and next Appointments, on each appointment card you will be able to Join to Online Consultation"
          )}
          icon={AppointmentIcon}
        />
        <div className="row mx-auto">
          {loadingData && <Loading color="#3788d8d4" />}
          {appointments &&
            appointments.map((item) => {
              const profileImageURL = resizeByLink(
                item.provider.user?.user_images?.at(-1)?.path_resource,
                "upload"
              );

              const appt_start = moment(item.appt_date_start);
              const appt_end = moment(item.appt_date_end);

              const serviceName =
                item?.services_orders?.[0]?.service_type?.name;
              const apptPrice = item?.services_orders?.[0]?.price_total;
              const serviceDuration =
                item?.services_orders?.[0]?.service_type?.duration_minute;

              const serviceOrderStatus = lastOrderRegister(
                item.services_orders
              );

              return (
                <AppointmentCard
                  key={item.id}
                  event_code={item.event_code}
                  patient_fullname={
                    item.patient.firstName + " " + item.patient.lastname
                  }
                  password={item.event_code}
                  appt_date_start={appt_start.utc().format("yy-MM-DD HH:mm")}
                  appt_date_end={appt_end.utc().format("yy-MM-DD HH:mm")}
                  providerFullname={
                    item.provider.firstName + " " + item.provider.lastname
                  }
                  service_name={serviceName}
                  event_type={item.event_type}
                  appt_status_code={item.status_appointment.appt_status_code}
                  appt_status_name={item.status_appointment.description}
                  profileImage={profileImageURL}
                  appointmentId={item.id}
                  price={apptPrice}
                  service_duration={serviceDuration}
                  order_status={serviceOrderStatus[0]?.status}
                  new_price={apptPrice}
                  rating={item.rating_provider}
                />
              );
            })}
        </div>
      </div>
    </DashboardPatient>
  );
}
