import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import swal from "sweetalert";
import userLocalState from "./../hooks/useUserState";
import DashboardAdmin from "../administrator/DashboardAdmin";
import RoleEditionForm from "./RoleEditionForm";

function RoleEdit(props) {
  const [data, setData] = useState([]);
  const [modules, setModules] = useState([]);
  let { id } = useParams();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [roleStatus, setRoleStatus] = useState("");
  const [roleName, setRoleName] = useState("");
  const localState = userLocalState();

  useEffect(() => {
    setLoading(true);
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/role/${id}`, {
      headers: {
        Authorization: "Bearer " + localState[0].jwt, //the jwt is a variable which holds the token
      },
    })
      .then((res) => {
        setLoading(false);
        const userModules = parseAssignedModules(res.data.role_modules);
        setData(userModules);
        setRoleStatus(res.data.status);
        setRoleName(res.data.name);
      })
      .catch((err) => console.log(err));
  }, []);

  //Effect to Find All Platform Modules
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/modules/all`, {
      headers: {
        Authorization: "Bearer " + localState[0].jwt, //the jwt is a variable which holds the token
      },
    })
      .then((res) => {
        setModules(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleBack = () => {
    history.goBack();
  };

  const handleChangeModules = (val) => {
    setData(val);
  };

  const handleSave = () => {
    setLoading(true);
    const params = buildParams(id, data);
    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/role/attachModule/all`,
      params,
      {
        headers: {
          Authorization: "Bearer " + localState[0].jwt, //the jwt is a variable which holds the token
        },
      }
    )
      .then((res) => {
        setLoading(false);
        swal("Success!", "Role Saved!", "success");
      })
      .catch((err) => console.log(err));
  };

  const buildParams = (idRole, selectedModules) => {
    let params = [];
    const role_ID = idRole;
    selectedModules.forEach((dat) => {
      params.push({
        role_id: role_ID,
        module_id: dat.id,
        permission_id: 2,
        status: "enabled",
        modified_at: new Date(),
      });
    });
    console.log("params", params);
    return params;
  };

  const parseAssignedModules = (roleModules) => {
    let finalModules = [];
    if (roleModules.length > 0) {
      roleModules.forEach(({ module }) => {
        finalModules.push({ id: module.id, name: module.name });
      });
    }
    return finalModules;
  };

  return (
    <DashboardAdmin>
      <div className="card container-card-role mt-3">
        <div className="card-header">Role Edition</div>
        <RoleEditionForm
          modules={modules}
          assignedModules={data}
          handleChangeModules={handleChangeModules}
          handleBack={handleBack}
          handleSave={handleSave}
          roleName={roleName}
          roleStatus={roleStatus}
        />
      </div>
    </DashboardAdmin>
  );
}

export default RoleEdit;
