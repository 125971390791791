import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import LoadingSpinner from "../../utils/Loading";
import DashboardAdmin from "../DashboardAdmin";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import useUserState from "../../hooks/useUserState";
import swal from "sweetalert";

import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Container,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    backgroundColor: "white",
    boxShadow: "5px 5px 16px 1px #1b3342",
    borderRadius: "10px",
  },
  gender: {
    width: "100%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "transparent",
    borderRadius: 0,
    width: "60px",
    height: "60px",
    marginBottom: "10px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: 120,
    width: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function UserCreation() {
  const classes = useStyles();
  let history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    visible: false,
    msgClass: "",
  });
  const userState = useUserState();
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState("");

  const clearForm = () => {
    document.getElementById("user-form").reset();
    setEmail("");
    setPassword("");
    setRole("");
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (email && password && role) {
      setLoading(true);
      Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/users/createUser`,
        {
          email,
          password: password,
          rol_id: role,
          person_type: "SPUSR",
        },
        {
          headers: { Authorization: "Bearer " + userState[0].jwt }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          setLoading(false);
          if (response.data.jwt) {
            swal("Success!", "User registered successfully", "success");
            clearForm();
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          swal("Error!", "something went wrong", "error");
        });
    } else {
      setErrorMessage({
        error: "Error, Please complete the fields",
        visible: true,
        msgClass: "alert alert-danger",
      });
    }
  };

  // Fetch the stored roles
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/role`, {
      headers: {
        Authorization: "Bearer " + userState[0].jwt, //the jwt is a variable which holds the token
      },
    })
      .then((res) => {
        setRoles(res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <DashboardAdmin
      sectionTitle="User Creation"
      description="In this module you will be able to create a new special user"
    >
      <Container component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h6" className="mt-2">
            Register New Special User
          </Typography>
          <Typography component="h6" variant="h6" className="mt-2">
            {errorMessage.visible && (
              <p className="text-danger">Please complete the fields</p>
            )}
          </Typography>
          <form className={classes.form} noValidate id="user-form">
            <Grid container spacing={2} className="justify-content-center">
              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  autoComplete="email"
                  name="email"
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="User e-mail"
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  id="role"
                  required
                  select
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  placeholder="Role"
                  label="Role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  {roles.length > 0 &&
                    roles.map((r) => {
                      if (r.name !== "PATIENT" && r.name !== "PROVIDER") {
                        return (
                          <MenuItem key={r.id} value={r.id}>
                            {r.name}
                          </MenuItem>
                        );
                      }
                    })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  variant="outlined"
                  type="password"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  autoComplete="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <Alert severity="info">
                  <AlertTitle>Info</AlertTitle>
                  Users only can access in the Admin Portal —{" "}
                  <strong> let's go ahead!</strong>
                </Alert>
              </Grid>
            </Grid>
            {loading && (
              <div className="d-flex justify-content-center">
                <LoadingSpinner type="spinningBubbles" color="#1d3557" />
              </div>
            )}
          </form>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center ">
            <div className="p-3">
              <Button
                variant="contained"
                color="success"
                startIcon={<CheckCircleOutlineIcon />}
                onClick={handleClick}
              >
                Save
              </Button>
            </div>

            <div className="p-3">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CancelIcon />}
                onClick={(e) => history.goBack()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </DashboardAdmin>
  );
}
