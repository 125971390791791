import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DashboardPatient from "../dashboard/DashboardPatient";
import VideoFrame from "../videoconference/VideoFrame";

export default function LobbyPatient() {
  const location = useLocation();
  useEffect(() => {
    console.log(location.state);
  }, []);

  const {
    event_code,
    patient_fullname,
    password,
    providerFullname,
    appointmentId,
    profileImage,
    rating,
  } = location.state;

  return (
    <div className="col-md-12 col-sm-12">
      <VideoFrame
        eventCode={event_code}
        displayName={patient_fullname}
        providerFullname={providerFullname}
        appointmentId={appointmentId}
        profileImage={profileImage}
        rating={rating}
        // password={password}
      />
    </div>
  );
}
