import React, { useState, useEffect } from "react";
import { TextField, Button, Grid, Paper } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function SearchModule(props) {
    const [country, setCountry] = useState([]);
    const [specialty, setSpecialty] = useState([]);
    const [provider, setProvider] = useState("");
    const { t, changeDirectLanguage } = useBDocTranslator();

    useEffect(() => {
        changeDirectLanguage(props?.language);
    }, []);

    return (
        <Paper elevation={1} className="p-3 mb-4" variant={"elevation"} >
            <Grid container spacing={2} justifyContent="center">
                {/* Search by Country */}
                <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                        id="search-by-country"
                        onChange={(e, newValue) => setCountry(newValue)}
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label={t("Filter by Country")} variant="outlined" />
                        )}
                    />
                </Grid>

                {/* Search By Specialty */}
                <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                        id="search-by-specialty"
                        onChange={(e, newValue) => setSpecialty(newValue)}
                        options={props.specialties}
                        getOptionLabel={(option) => t(option.speciality_name)}
                        renderInput={(params) => (
                            <TextField {...params} label={t("Filter by Specialty")} variant="outlined" />
                        )}
                    />
                </Grid>

                {/* Search by Providers name */}
                <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                        id="search-by-name"
                        onChange={(e, newValue) => setProvider(newValue)}
                        options={props.providers}
                        getOptionLabel={(option) => option.firstName + " " + option.lastname}
                        renderInput={(params) => (
                            <TextField {...params} label={t("Filter by Provider's name")} variant="outlined" />
                        )}
                    />
                </Grid>

                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button
                        onClick={() => props.handleSearch(country, specialty, provider)}
                        variant="contained"
                        color="primary"
                    >
                        {t("Search")}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}

const countries = [
    { name: "United States", code: "USA" },
    { name: "Costa Rica", code: "CRC" },
    { name: "Bahamas", code: "BHA" },
    { name: "Jamaica", code: "JAM" },
    { name: "Nicaragua", code: "NIC" },
];
