import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {useAffiliateUser,useCheckout} from "../../services/services";
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import {setAffiliateId} from "../../actions";
import {useDispatch} from "react-redux";
import useUserState from "../hooks/useUserState";
import {FaWhatsappSquare} from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
    card:{
        margin:theme.spacing(1,0),
        maxWidth:380,
        borderWidth:1,
        borderStyle:'solid',
        borderColor:theme.palette.grey[500],
        borderRadius:0,
    },
    cardTitle:{
        fontSize:'1.5rem',
        marginBottom:theme.spacing(2),
    },
    cardDescription:{
        fontSize:'1rem',
        marginBottom:theme.spacing(2),
    },
    logoIcon:{
        marginRight:theme.spacing(1),
    },
    price:{
        fontSize:'1.5rem',
    },
    actionButton:{
        marginTop:theme.spacing(2),
    },
    divider:{
        height:'1px',
        background:theme.palette.grey[500],
        marginBottom:theme.spacing(3),
    },
    banner:{
        backgroundColor:theme.palette.error.main,
        color:'white',
        padding:theme.spacing(1),
        width:'fit-content',
        position:'relative',
        top:0,
        left:'50%',
        transform:'translate(20%, -20%)',
        zIndex:1,
        boxShadow:'0px 0px 2px 0px rgba(0,0,0,0.75)',
    },
    bannerEmpty:{
        marginBottom:theme.spacing(5),
        width:'fit-content',
        position:'relative',
        top:0,
        left:'50%',
        transform:'translate(20%, -20%)',
        zIndex:1,
        boxShadow:'0px 0px 2px 0px rgba(0,0,0,0.75)',
    },
    buyContainer:{
        display:'flex',
        alignItems:'center',
        gap:theme.spacing(2), // Adjust the space between elements
    },

    underlineText:{
        textDecoration:'underline',
        fontSize:'10px',
        color:theme.palette.grey[500],
        marginTop:theme.spacing(1),
    },

    whatsappIcon:{
        color:'green',
        fontSize:'28px',
        transition:'all 0.3s',
        '&:hover':{
            transform:'scale(1.2)',
        },
    },
}));

const PlanCard = ({id,name,description,price,beneficiary_qty,planAffiliated}) => {
    const classes = useStyles();
    const [userData] = useUserState();
    const JWT = userData?.jwt;

    const {affiliateUser} = useAffiliateUser(JWT);
    const {createSubscription} = useCheckout();
    const dispatch = useDispatch();

    const dataToAffiliate = {
        patientId:userData?.patientId,
        planId:id,
        status:"pending",
        affiliation_dt_start:new Date(),
    };

    const handleButtonClick = async() => {
        const afiliate = await affiliateUser(dataToAffiliate);
        const afiliateId = afiliate?.id;
        const parent_id = afiliate?.patient_id;
        const subscription = await createSubscription(afiliateId);
        const data = {afiliateId,parent_id,subscription:subscription?.session?.id};
        dispatch(setAffiliateId(data));
        window.location = subscription.session.url;
    };


    return (
        <Card className={classes.card}>
            {planAffiliated ? (
                <div className={classes.banner}>
                    <Typography variant="body3">Plan afiliado</Typography>
                </div>

            ) : <div className={classes.bannerEmpty}>
            </div>}
            <CardContent>
                <Typography variant="h5" className={classes.cardTitle}>
                    {name}
                </Typography>
                <div className={classes.divider}></div>
                <Typography variant="body1" className={classes.cardDescription}>
                    Aplica para el titular y {beneficiary_qty} beneficiarios.
                </Typography>
                <div>
                    <div className={classes.logoIcon}>
                        <LocalHospitalIcon
                            color="primary"
                            fontSize="large"
                        />
                    </div>
                    <Typography variant="body1" className={'mt-2'}>{description}</Typography>
                </div>
            </CardContent>
            <CardContent>
                <div>
                    <Typography variant="h6" className={classes.price}>
                        Aplica para el titular y {beneficiary_qty} beneficiarios.
                    </Typography>
                    <Typography variant="body1" className={classes.price}>
                        {`$${price}`}
                    </Typography>
                </div>
                <div className={classes.actionButton}>
                    <div className={classes.buyContainer}>
                        <Button
                            onClick={handleButtonClick}
                            variant="contained"
                            color="primary"
                            disabled={planAffiliated}
                        >
                            Comprar
                        </Button>
                        {
                            planAffiliated && (
                                <>
                                    <span className={classes.underlineText} >Contact Support for information</span>
                                    <a href={`https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}`} target="_blank" className={classes.whatsappIcon}
                                       rel="noreferrer">
                                        <FaWhatsappSquare/>
                                    </a>
                                </>
                            )
                        }
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default PlanCard;