// Component to Create a new Appointment
import React, { useEffect } from "react";
import { format } from "date-fns";
import { useState } from "react";
import Axios from "axios";
import LoadingSpin from "../utils/Loading";
import DashboardPatient from "../dashboard/DashboardPatient";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import swal from "sweetalert";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, MenuItem } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import moment from "moment";
import { dateToString } from "../utils/DateUtils";
import TimeSlots from "./TimeSlots";
import useUserState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";
import FileUpload from "../utils/FileUpload";

function PatientAppointment() {
  const localState = useUserState();
  const [currentUserId, setCurrentUserId] = useState(localState[0].user.id);
  const [patientId, setPatientId] = useState(localState[0].extendedData.id);

  const jwtSession = localState[0].jwt;

  const [service_open, setService_open] = React.useState(false);
  const [service_options, setService_options] = React.useState([]);
  const service_loading = service_open && service_options.length === 0;
  const [service_type_name, setService_type_name] = useState("");

  const [data, setData] = useState("");
  const [duration, setDuration] = useState("");
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const [event_type, setEvent_type] = useState("");
  const [appointment_type, setAppointment_type] = useState("");
  const [appt_date_start, setAppt_date_start] = useState();
  const [appt_date_end, setAppt_date_end] = useState(new Date());
  const [service_type, setService_type] = useState("");
  const [loading_page, setLoading_page] = useState(false);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    visible: false,
    msgClass: "SOMETHING IS WRONG",
  });
  let history = useHistory();
  const location = useLocation();
  const [providerID, setProviderID] = useState(0);
  const [slotsAvailables, setSlotsAvailables] = useState([]);
  const { providerId } = location.state;

  const [slotSelected, setSlotSelected] = useState("");
  const [isSameDay, setIsSameDay] = useState(true);
  const [appointmentReason, setAppointmentReason] = useState("");
  const [fileName, setFileName] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const { t, changeDirectLanguage } = useBDocTranslator();

  React.useEffect(() => {
    // const { providerId } = location.state;
    setProviderID(providerId);
  }, []);

  /* useEffect(() => {
    if (slotsAvailables.length > 0) {
      fetchTimesAvailables(providerID);
    }
  }, [appt_date_start]); */

  const clearForm = () => {
    document.getElementById("appointment-form").reset();
    setEvent_type("");
    setAppointment_type("");
    setAppt_date_start(moment().format("YYYY-MM-DD"));
    setAppt_date_end("");
    setService_type("");
  };

  const validateFields = () => {
    switch ("") {
      case event_type:
        return "Please Select an Event Type";
      case appointment_type:
        return "Please Select an Appointment Type";
      case service_type:
        return "Please Select a Service Type";
      case appointmentReason:
        return "Please fill out the Reason Field";
      default:
        return false;
    }
  };

  const handleClick = async (e) => {
    const emptyFields = validateFields();
    if (emptyFields) {
      swal("Error!", t(emptyFields), "error");
      return false;
    }
    console.log("end date", appt_date_end);
    if (appt_date_end === "Invalid date") {
      swal(
        "Error!",
        t("Please Select hours and minutes for your appointment"),
        "error"
      );
      return false;
    }

    if (
      event_type != null &&
      appointment_type != null &&
      duration != null &&
      slotSelected != null &&
      slotSelected !== "" &&
      appointmentReason != null &&
      appt_date_start != null
    ) {
      setLoading_page(true);
      // await handleUploadEHRFile();
      // create a formdata to send field to save and the file
      const formDataReq = new FormData();
      formDataReq.append("file", fileName);
      formDataReq.append("event_type", event_type);
      formDataReq.append("appointment_type_id", appointment_type);
      formDataReq.append(
        "appt_date_start",
        `${appt_date_start} ${slotSelected}`
      );
      formDataReq.append("appt_date_end", appt_date_end);
      formDataReq.append("appt_status", "NAPPT");
      formDataReq.append("service_type_id", service_type);
      formDataReq.append("appt_reason", appointmentReason);
      formDataReq.append("time_zone", "America.CostaRica");
      formDataReq.append("created_by_user", currentUserId);
      formDataReq.append("patient_id", patientId);
      formDataReq.append("provider_id", providerId);
      formDataReq.append("section_indicator", "PAT");

      Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/appointments/create`,
        formDataReq,
        {
          headers: { Authorization: "Bearer " + jwtSession },
        }
      )
        .then((response) => {
          setLoading_page(false);
          clearForm();
          swal({
            title: t("Success! Appointment Registered"),
            text: t(response?.data?.message),
            icon: "success",
            button: "OK",
          }).then((ok) => {
            if (ok) {
              history.push({
                pathname: "/patient/appointments",
              });
            }
          });
        })
        .catch((error) => {
          setLoading_page(false);
          swal("Error!", error.response?.data?.message, "error");
        });
    } else {
      swal("Warning!", t("Error, Please fill the Form"), "warning");
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const valuetext = (value) => {
    setService_type("CON" + value);
    setDuration(value);
  };

  const handleDateEnd = () => {
    const startDateTime = `${appt_date_start} ${slotSelected}`;
    const newDate = Date.parse(startDateTime);
    const time = parseInt(duration) * 60000;
    const end = new Date(newDate + time);
    const newEnd = moment(dateToString(end)).format("yyyy-MM-DD HH:mm:ss");
    console.log("log date", { time: time, end: end, newEnd: newEnd });
    setAppt_date_end(newEnd);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: 300,
      justifyContent: "center",
    },
  }));

  const classes = useStyles();

  const eventType = [
    { title: t("Virtual Consultation"), value: "VIRTUAL" },
    // { title: t("At Office"), value: "ON-SITE" },
  ];

  //Fecth Services for selected provider
  useEffect(() => {
    const { providerUserId } = location.state;
    async function fetchData() {
      let active = true;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL_BASE}/services/findByUserId/public?userId=${providerUserId}`,
        {
          headers: {
            Authorization: "Bearer " + jwtSession,
          },
        }
      );
      const services = await response.json();
      console.log("services", services);
      if (services)
        setService_options(Object.keys(services).map((key) => services[key]));
    }
    fetchData();
  }, [service_loading]);

  //Fetching times slots availables for the selected provider
  const fetchTimesAvailables = (provider, date) => {
    setLoadingSlots(true);
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/appointments/findHours?providerId=${provider}&date=${date}`,
      {
        headers: {
          Authorization: "Bearer " + jwtSession,
        },
      }
    )
      .then((res) => {
        setLoadingSlots(false);

        if (res.data.length < 0) {
          swal(
            "Sorry!",
            t("there are no places available for consultations"),
            "warning"
          );
        }
        setSlotsAvailables(res.data);
      })
      .catch((err) => {
        setLoadingSlots(false);
        setSlotsAvailables([]);
        console.log(err);
        swal("Error!", t("Booking times unavailable"), "error");
      });
  };

  const handleSelection = (e) => {
    e.preventDefault();
    console.log(e.target.getAttribute("data-slot"));
    e.target.classList.add("box-hour-selected");
    setSlotSelected(e.target.getAttribute("data-slot"));
    handleDateEnd();
  };

  useEffect(() => {
    const isTheSameDay = moment().format("yyyy-MM-DD") === appt_date_start;
    setIsSameDay(isTheSameDay);
    changeDirectLanguage();
  }, [appt_date_start]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file);
  };

  const handleUploadEHRFile = async () => {
    if (uploadedFiles.length === 0) {
      return;
    }
    if (uploadedFiles.length > 5) {
      swal("Error!", "Solo se permiten 5 archivos simultaneamente.", "error");
      return;
    }

    const data = new FormData();
    data.append("patient_id", patientId);
    uploadedFiles.map((file) => {
      return data.append("file", file);
    });

    try {
      const fileUploadResult = await Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/ehr/uploadLabsDocuments`,
        data,
        {
          headers: { Authorization: "Bearer " + jwtSession },
        }
      );
      if (fileUploadResult.status === 200) {
        setUploadedFiles([]);
        return true;
      }
    } catch (error) {
      console.log("appt creation error", error);
    }
  };

  return (
    <DashboardPatient
      sectionTitle={t("Create Appointment")}
      sectionSubtitle="Calendar"
    >
      <div className="container d-flex justify-content-center">
        <form
          className="col-12 m-3 shadow-sm p-3 mb-5 bg-white rounded"
          id="appointment-form"
        >
          <div className="col form-group">
            <Autocomplete
              onChange={(event, newValue) => {
                setEvent_type(newValue.value);
              }}
              id="combo-box-demo"
              style={{ width: "100%" }}
              options={eventType}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Event Type")}
                  variant="outlined"
                />
              )}
            />
          </div>

          <div className="col form-group justify-content-start">
            <label>{t("Type of Appointment")}</label>
            <div className="row">
              <Paper elevation={1} className="mx-3">
                <div className="form-check p-2 ml-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="cons-radio"
                    value="option1"
                    onClick={(e) => setAppointment_type("CONS")}
                  />
                  <label className="form-check-label" htmlFor="exampleRadios1">
                    {t("Consultation")}
                  </label>
                </div>
              </Paper>
              <Paper elevation={1} className="mx-2">
                <div className="form-check p-2 ml-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="folup-radio"
                    value="option2"
                    onClick={(e) => setAppointment_type("FOLUP")}
                  />
                  <label className="form-check-label" htmlFor="exampleRadios2">
                    {t("Follow Up")}
                  </label>
                </div>
              </Paper>
            </div>
          </div>

          <div className="col d-flex form-group justify-content-center p-3">
            <TextField
              id="outlined-select-service-type"
              select
              label={t("Service")}
              defaultValue={t("Select Service")}
              value={service_type_name}
              onChange={(event, newValue) => {
                setService_type_name(event.target.value);
                setService_type(event.target.value);
                setDuration(newValue.props.duration);
              }}
              helperText={t("Please select the type of service")}
              variant="outlined"
            >
              {service_options &&
                service_options.map((option) => (
                  <MenuItem
                    duration={option.duration_minute}
                    key={option.code}
                    value={option.id}
                    name={option.name}
                  >
                    {option.name + " - USD $" + option.price}
                  </MenuItem>
                ))}
            </TextField>
          </div>

          <div className="row d-flex justify-content-center align-items-baseline pb-3">
            <TextField
              id="datetime-local"
              label={t("Select a date")}
              type="date"
              // defaultValue={appt_date_start}
              value={appt_date_start}
              className={classes.textField}
              onChange={(event) => {
                setAppt_date_start(event.target.value);
                // handleDateEnd();
                fetchTimesAvailables(providerID, event.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: moment().format("YYYY-MM-DD"),
              }}
            />
          </div>
          {/* Appointment Reason */}
          <div className="row d-flex justify-content-center align-items-baseline pb-3">
            <textarea
              cols="30"
              rows="5"
              maxLength="200"
              className="col-md-8 col-sm-12 mt-1 input-condition-detail"
              onChange={(e) => setAppointmentReason(e.target.value)}
              placeholder={t("Appointment Reason")}
              value={appointmentReason}
            ></textarea>
          </div>
          {/* Document or Labs Result */}
          <div className="d-flex col justify-content-center align-items-baseline pb-3">
            <Button variant="contained" component="label">
              {fileName != null
                ? fileName.name
                : t("Upload Exam Results or Medical Documents (optional)")}
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
          </div>
          {/* <div className="d-flex col justify-content-center align-items-baseline pb-3">
            <div className="mt-2 mx-1 justify-center">
              <FileUpload
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
              />
            </div>
          </div> */}

          {/* Time Slots */}
          <div>
            {loadingSlots ? (
              <LoadingSpin type="spokes" color="#1d3557" />
            ) : (
              <TimeSlots
                data={slotsAvailables}
                handleSelection={handleSelection}
                isSameDay={isSameDay}
              />
            )}
          </div>

          <div className="col d-flex justify-content-center">
            <div className="p-3">
              <Button
                variant="contained"
                color="primary"
                startIcon={<CheckCircleOutlineIcon />}
                onClick={handleClick}
              >
                {t("Save")}
              </Button>
            </div>

            <div className="p-3">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
              >
                {t("Cancel")}
              </Button>
            </div>
          </div>
          {loading_page && (
            <div className="d-flex justify-content-center mt-3">
              <LoadingSpin type="spokes" color="#1d3557" />
            </div>
          )}
        </form>
      </div>
    </DashboardPatient>
  );
}

export default PatientAppointment;
