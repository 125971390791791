import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button,Grid,Typography,Paper,Avatar,IconButton} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import DescriptionIcon from '@material-ui/icons/Description';
import RatingComponent from '../../utils/StarsComponent';
import Tags from '../Tags';
import useBDocTranslator from '../../hooks/useBDocTranslator';
import ProfileLogo from '../../../images/profile-2.svg';
import {makeStyles} from '@material-ui/core/styles';


//flags images
import NICFlag from "../../../images/nicaragua-flag.png";
import CRCFlag from "../../../images/cr-flg.png";
import BahFlag from "../../../images/bahamas-flag.png";
import USAFlag from "../../../images/usa-flag.png";
import JAMFlag from "../../../images/jamaica-flag.png";
import {renderPlans} from "../../utils/RenderPlans";
import {Box} from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
    largeAvatar:{
        width:theme.spacing(15), // Adjust size as needed
        height:theme.spacing(15),
    },
}));

export default function ProviderCard(props){
    const classes = useStyles();
    let history = useHistory();
    const {t} = useBDocTranslator();

    const handleClick = () => {
        history.push({
            pathname:"/patient/appointments/create",
            state:{
                providerId:props.providerId,
                providerUserId:props.providerUserId,
            },
        });
    };
    const handleClickDetails = () => {
        history.push({
            pathname:`provider/details/${props.id}`,
            state:props.profileImage,
        });
    };

    const handleClickTextConsultation = () => {
        history.push({
            pathname:`/patient/textconsultation`,
            state:{providerId:props.providerId},
        });
    };


    const mathCountryFlag = (country) => {
        switch(country){
            case "CRC":
                return (
                    <img className="flag-size rounded" src={CRCFlag} alt="CRCFlag"/>
                );
            case "USA":
                return (
                    <img className="flag-size rounded" src={USAFlag} alt="USAFlag"/>
                );
            case "NIC":
                return (
                    <img className="flag-size rounded" src={NICFlag} alt="NICFlag"/>
                );
            case "TBH":
                return (
                    <img className="flag-size rounded" src={BahFlag} alt="BahFlag"/>
                );
            case "JAM":
                return (
                    <img className="flag-size rounded" src={JAMFlag} alt="JAMFlag"/>
                );

            default:
                return (
                    <img
                        className="flag-size rounded"
                        src={CRCFlag}
                        alt="CRCFlagDefault"
                    />
                );
        }
    };



    return (
        <Paper elevation={1} className="provider-card p-2 mb-2">
            <Grid container>
                <Grid item xs={12} md={6} style={{ borderRight: '1px solid #e0e0e0' }}>
                    <Box display="flex" flexDirection="column" alignItems="center" p={2}>
                        <Avatar
                            variant="circle"
                            alt="avatar"
                            src={props.profileImage || ProfileLogo}
                            className={classes.largeAvatar}
                        />
                        <Typography variant="h6" gutterBottom>
                            {props.title || "Dr."} {props.fullname || "John Smith Doe"}
                            {mathCountryFlag(props.country)}
                        </Typography>
                        <RatingComponent editRate={false} rating={props.rating} />
                        <Tags tags={props.specialty || []} />
                        {props?.plans && (
                            <Box className="plans">
                                {renderPlans(props?.plans)}
                            </Box>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
                        <Box p={2}>
                            <Typography variant="h6">
                                {t("About me")}
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'justify' }}>
                                {props.summary || "Professional resume not Provided yet 😞"}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" p={2} alignItems="flex-end">
                            <Box display={'flex'} alignItems={'center'}>
                                <Typography variant="body2" color="textSecondary">
                                    {props?.available && t("Available today") }
                                </Typography>
                                {props?.available && (
                                    <IconButton color="primary">
                                        <AccessAlarmIcon />
                                    </IconButton>
                                )}
                            </Box>
                            <Box display="flex" gridGap={4}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<DescriptionIcon />}
                                    onClick={handleClickDetails}
                                >
                                    {t("Details")}
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<EventIcon />}
                                    onClick={handleClick}
                                >
                                    {t("Schedule Appointment")}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );

}

