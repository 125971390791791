import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";

import BusinessIcon from "@material-ui/icons/Business";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import { useHistory } from "react-router-dom";
import SettingOption from "../../providers/SettiingOption";
import CalendarSetting from "../../../images/circuit-board.svg";
import ServiceSetting from "../../../images/dominos.svg";
import UserSetting from "../../../images/floating-cogs.svg";
import useBDocTranslator from "../../hooks/useBDocTranslator";
import DashboardAdmin from "../DashboardAdmin";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  responsive: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  info: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  content: {
    flex: "1 0 auto",
  },
  Icon: {
    height: 15,
    width: 20,
  },
}));

export default function AdminSetting() {
  const history = useHistory();
  const classes = useStyles();
  const { t, changeDirectLanguage } = useBDocTranslator();

  useEffect(() => {
    changeDirectLanguage();
  }, []);

  return (
    <DashboardAdmin>
      <div className="row">
        {/* Plan Management */}
        <SettingOption
          link="/users/plan/create"
          title={t("Manage Plans")}
          banner={CalendarSetting}
          optionTitle={t("Plans Management")}
        />

        {/* Afiliations Management */}
        <SettingOption
          link="/users/affiliations/list"
          title={t("Manage Affiliations")}
          banner={CalendarSetting}
          optionTitle={t("Affiliations Management")}
        />
      </div>
    </DashboardAdmin>
  );
}
