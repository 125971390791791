import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import consultationIcon from "../../images/consultation-icon.svg";
import useUserState from "../hooks/useUserState";
import Axios from "axios";
import ConsultationNotesDialog from "../prescription/ConsultationNotesDialog";
import useBDocTranslator from "../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "whitesmoke",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "900px",
  },
}));

export default function ConsultationNotesModalForm(props) {
  const classes = useStyles();
  const [notes, setNotes] = useState(""); // this is the state for prescription
  const [responseMessage, setResponseMessage] = useState("");
  const userLocalState = useUserState();
  const [visible, setVisible] = useState(true);
  const { t } = useBDocTranslator();

  const handleNotes = (e) => {
    setNotes(e.target.value);
  };

  //Method for Save the consultation notes
  const handleNotesSave = () => {
    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/consultations/createOnly`,
      {
        patient_id: props.patientId,
        note: notes,
        provider_id: userLocalState[0].extendedData.id,
        appointment_id: props.appointmentId,
        visibility: visible ? "public" : "private",
      },
      {
        headers: { Authorization: "Bearer " + userLocalState[0].jwt },
      }
    )
      .then((response) => {
        setResponseMessage("Note created successfuly");
        setNotes("");
        props.handleRefreshData();
      })
      .catch((error) => {
        console.log(error);
        setResponseMessage("Error!, Something went wrong");
      });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          {
            <div className={classes.paper}>
              <div className="row col-md-12">
                <div className="col-md-4">
                  <img
                    src={consultationIcon}
                    alt="rx icon"
                    className="w-30-custom"
                  />
                </div>
                <div className="col-md-8">
                  <h3>{t("Create Consultation Notes")}</h3>
                  <div>
                    <p className="font-weight-bold text-success">
                      {t(responseMessage)}
                    </p>
                  </div>
                </div>
              </div>
              {/* Form */}
              <div className="row col-md-12">
                <ConsultationNotesDialog
                  handleConsultationNotes={handleNotes}
                  consultationNotesValue={notes}
                  handleSaveConsultationNotes={handleNotesSave}
                  responseMessage={responseMessage}
                  visible={visible}
                  handleVisibility={setVisible}
                />
              </div>
            </div>
          }
        </Fade>
      </Modal>
    </div>
  );
}
