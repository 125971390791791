import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";

export default function useFeedback() {
  const [feedbackType, setFeedbackType] = useState("mejora");
  const [feedbackContent, setFeedbackContent] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSendFeedback = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/feedback`,
        {
          type: feedbackType,
          content: feedbackContent,
        }
      );
      if (response?.status === 200) {
        swal("Gracias por sus comentarios!", "", "success");
        setFeedbackContent("");
        setFeedbackType("mejora");
      }
      console.log(response);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return {
    handleSendFeedback,
    setFeedbackType,
    setFeedbackContent,
    feedbackType,
    feedbackContent,
    loading,
  };
}
