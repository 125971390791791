const userImageReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_USER_IMAGE_PROFILE":
      if (action.payload) {
        const { profileImg, exists } = action.payload;
        const newState = { ...state, profileImg, exists };
        return newState;
      }
      break;
    default:
      return state;
  }
};

export default userImageReducer;
