import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { MenuItem, TextField } from "@material-ui/core";
import useUserState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function HealthMeasurementDialog(props) {
  const userLocalState = useUserState();
  const [measurementResult, setMeasurementResult] = useState("");
  const [measurement, setMeasurement] = useState("");
  const { t } = useBDocTranslator();

  const measurementTypes = [
    {
      value: "BP",
      label: "Body Pressure",
    },
    {
      value: "ECG",
      label: "Electrocardiogram",
    },
    {
      value: "SpO2",
      label: "SpO2",
    },
    {
      value: "BT",
      label: "Body Temperature",
    },
    {
      value: "BG",
      label: "Blood Glucose",
    },
  ];

  const data = {
    measurement_type: measurement,
    created_by_userId: userLocalState[0].user.id,
    result: measurementResult,
    patient_id: props.patientId,
  };

  // clean values for next use
  const handleCleanValues = () => {
    setMeasurement("");
    setMeasurementResult("");
  };

  useEffect(() => {
    if (props.successFlag) {
      handleCleanValues();
    }
  }, [props.successFlag]);

  return (
    <div className="shadow m-2 bg-white rounded-lg col-md-12 col-sm-12 position-relative dependants-card">
      <div className="col">
        <div className="col-md-12 w-100 mt-2">
          <TextField
            id="measurementType"
            select
            label={t("Measure Type")}
            variant="outlined"
            className="w-100"
            value={measurement}
            onChange={(e) => setMeasurement(e.target.value)}
            required={true}
          >
            {measurementTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div className="col-md-12 w-100 mt-2">
          <TextField
            id="measurement"
            label={t("Measure Result")}
            variant="outlined"
            className="w-100"
            value={measurementResult}
            onChange={(e) => setMeasurementResult(e.target.value)}
            required={true}
          />
        </div>
      </div>

      <div className="row mt-2 mx-1">
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.handleMeasureCreate(data)}
          className="col-md-6"
        >
          {t("Save")}
        </Button>
        <Button
          variant="contained"
          color="default"
          onClick={props.handleClose}
          className="col-md-6"
        >
          {t("Cancel")}
        </Button>
      </div>
    </div>
  );
}
