import {useState} from 'react';
import Axios from 'axios';
import swal from "sweetalert";

// const [userData] = useUserState()
// const session = JSON.parse(sessionStorage.getItem("userState"));
// const jwt = session?.jwt;

/**
 * Handle API errors
 * @param error
 * @param customErrorMessage
 * @returns {*|{error: string}}
 */
const handleApiError = (error,customErrorMessage = "An error occurred.") => {
    console.error("API Error:",error);

    return error.response ? error.response.data : {error:customErrorMessage};
};
/**
 * Create User Patient
 * @returns {{userData: unknown, createUserPatient: ((function(*): Promise<any|{error: string}|undefined>)|*), loading: boolean, error: unknown}}
 */
const useCreateUserPatient = () => {
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [userData,setUserData] = useState(null);

    const createUserPatient = async(userData) => {
        try{
            setLoading(true);
            const response = await Axios.post(
                `${process.env.REACT_APP_API_URL_BASE}/userPatient/createUserPatient`,
                userData
            );
            setUserData(response.data);
            return response.data;
        }catch(error){
            setError(handleApiError(error,"Error creating user patient."));
            return error.response ? error.response.data : {error:"Unknown error occurred."};
        }finally{
            setLoading(false);
        }
    };

    return {loading,error,userData,createUserPatient};
};
/**
 * Get Plans
 * @returns {{plansData: unknown, loading: boolean, error: unknown, getPlans: ((function(): Promise<any|{error: string}|undefined>)|*)}}
 */
const useGetPlans = () => {
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [plansData,setPlansData] = useState(null);

    const getPlans = async() => {
        try{
            setLoading(true);
            const response = await Axios.get(`${process.env.REACT_APP_API_URL_BASE}/plans/getPlans`);
            setPlansData(response.data);
            return response.data;
        }catch(error){
            setError(handleApiError(error,"Error getting plans."));
            return error.response ? error.response.data : {error:"Unknown error occurred."};
        }finally{
            setLoading(false);
        }
    };

    return {loading,error,plansData,getPlans};
};
/**
 *
 * @param jwt
 * @return {{patientData: unknown, getPatientData: ((function(*): Promise<any|{error: string}|undefined>)|*), loading: boolean, error: unknown}}
 */
const useGetPatientData = (jwt) => {
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [patientData,setPatientData] = useState(null);

    const getPatientData = async(userId) => {
        try{
            setLoading(true);
            const response = await Axios.get(`${process.env.REACT_APP_API_URL_BASE}/patient/patientData`,
                {
                    headers:{
                        Authorization:`Bearer ${jwt}`,
                    },
                    params:{userId},
                });
            setPatientData(response.data);
            return response.data;
        }catch(error){
            setError(handleApiError(error,"Error getting plans."));
            return error.response ? error.response.data : {error:"Unknown error occurred."};
        }finally{
            setLoading(false);
        }
    };

    return {loading,error,patientData,getPatientData};
};
/**
 * Get Plan By Affiliation Id
 * @returns {{getPlanByAffiliationId: ((function(*): Promise<any|undefined>)|*), beneficiaryQty: unknown, loading: boolean, error: unknown, planByAffiliationIdData: unknown}}
 */
const usePlanByAffiliationId = (jwt) => {
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [planByAffiliationIdData,setPlanByAffiliationIdData] = useState(null);
    const [beneficiaryQty,setBeneficiaryQty] = useState(null);

    const getPlanByAffiliationId = async(affiliationId) => {
        try{
            setLoading(true);
            const response = await Axios.get(`${process.env.REACT_APP_API_URL_BASE}/plans/getPlanByAffiliationId?affiliation_id=${affiliationId}`,{
                headers:{
                    Authorization:`Bearer ${jwt}`,
                }
            });
            setPlanByAffiliationIdData(response.data);
            setBeneficiaryQty(response?.data);
            return response?.data;
        }catch(error){
            setError(error?.response?.data);
            return error?.response?.data
        }finally{
            setLoading(false);
        }
    };

    return {loading,error,planByAffiliationIdData,getPlanByAffiliationId,beneficiaryQty};
};
/**
 * Create Subscription
 * @returns {{checkoutData: unknown, createSubscription: ((function(*): Promise<any|{error: string}|undefined>)|*), loading: boolean, error: unknown}}
 */
const useCheckout = () => {
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [checkoutData,setCheckoutData] = useState(null);

    const createSubscription = async(affiliationId) => {
        try{
            setLoading(true);
            const response = await Axios.post(
                `${process.env.REACT_APP_API_URL_BASE}/payments/create-subscription-checkout-session`,
                {affiliationId},
            );
            setCheckoutData(response.data);
            return response.data;
        }catch(error){
            setError(handleApiError(error,"Error creating subscription checkout session."));
            return error.response ? error.response.data : {error:"Unknown error occurred."};
        }finally{
            setLoading(false);
        }
    };

    return {loading,error,checkoutData,createSubscription};
};
/**
 * Stripe Payment Success
 * @returns {{paymentSuccessData: unknown, changeAffiliationStatus: ((function(*): Promise<any|{error: string}|undefined>)|*), loading: boolean, error: unknown}}
 */
const useStripePaymentSuccess = (jwt) => {
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [paymentSuccessData,setPaymentSuccessData] = useState(null);

    const changeAffiliationStatus = async(data) => {
        try{
            setLoading(true);
            const response = await Axios.post(
                `${process.env.REACT_APP_API_URL_BASE}/payments/stripe-payment-success`,
                data,
                {
                    headers:{
                        Authorization:`Bearer ${jwt}`,
                    },
                }
            );
            setPaymentSuccessData(response.data);
            return response.data;
        }catch(error){
            setError(handleApiError(error,"Error processing Stripe payment success."));
            return error.response ? error.response.data : {error:"Unknown error occurred."};
        }finally{
            setLoading(false);
        }
    };

    return {loading,error,paymentSuccessData,changeAffiliationStatus};
};
/**
 * Affiliate User
 * @returns {{affiliateUserData: unknown, affiliateUser: ((function(*): Promise<any|{error: string}|undefined>)|*), loading: boolean, error: unknown}}
 */
const useAffiliateUser = (jwt) => {
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [affiliateUserData,setAffiliateUserData] = useState(null);

    const affiliateUser = async(userData) => {
        try{
            setLoading(true);
            const response = await Axios.post(
                `${process.env.REACT_APP_API_URL_BASE}/affiliations/createAffiliation`,
                userData,
                {
                    headers:{
                        Authorization:`Bearer ${jwt}`,
                    },
                }
            );
            setAffiliateUserData(response.data);
            return response.data;
        }catch(error){
            setError(handleApiError(error,"Error affiliating user."));
            return error.response ? error.response.data : {error:"Unknown error occurred."};
        }finally{
            setLoading(false);
        }
    };

    return {loading,error,affiliateUserData,affiliateUser};
};
/**
 * Create Users Beneficiary
 /**
 * Create Users if the user does not exist to then send the user id be able to create the beneficiary
 * @returns {{createUsersBeneficiaryData: unknown, createUsersBeneficiary: ((function(*): Promise<any|{error: string}|undefined>)|*), loading: boolean, error: unknown}}
 */
const useCreateUsersBeneficiary = () => {
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [createUsersBeneficiaryData,setCreateUsersBeneficiaryData] = useState(null);

    const createUsersBeneficiary = async(userData) => {
        try{
            setLoading(true);
            const response = await Axios.post(
                `${process.env.REACT_APP_API_URL_BASE}/userPatient/createUsersBeneficiary`,
                {usersBeneficiary:userData},
            );
            setCreateUsersBeneficiaryData(response.data);
            return response.data;
        }catch(error){
            setError(handleApiError(error,"Error creating users beneficiary."));
            return error.response ? error.response.data : {error:"Unknown error occurred."};
        }finally{
            setLoading(false);
        }
    };

    return {loading,error,createUsersBeneficiaryData,createUsersBeneficiary};
};
/**
 * Create the affiliation of the beneficiaries
 * @returns {{affiliateBeneficiarieData: unknown, affiliateBeneficiarie: ((function(*): Promise<any|{error: string}|undefined>)|*), loading: boolean, error: unknown}}
 */
const useAffiliationsBeneficiaries = (jwt) => {
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [affiliateBeneficiarieData,setAffiliateBeneficiarieData] = useState(null);

    const affiliateBeneficiarie = async(userData) => {
        try{
            setLoading(true);
            const response = await Axios.post(
                `${process.env.REACT_APP_API_URL_BASE}/affiliations/affiliationsBeneficiaries`,
                {beneficiaries:userData},
                {
                    headers:{
                        Authorization:`Bearer ${jwt}`,
                    },
                }
            );
            setAffiliateBeneficiarieData(response.data);
            return response.data;
        }catch(error){
            setError(handleApiError(error,"Error affiliating beneficiaries."));
            return error.response ? error.response.data : {error:"Unknown error occurred."};
        }finally{
            setLoading(false);
        }
    };

    return {loading,error,affiliateBeneficiarieData,affiliateBeneficiarie};
};
/**
 * Get Appointments By Patient
 * @returns {{getAppointmentsByPatient: ((function(): Promise<any|{error: string}|undefined>)|*), loadingData: boolean, error: unknown, appointments: unknown}}
 */
const useAppointmentsByPatient = (patientId,jwt) => {
    const [loadingData,setLoadingData] = useState(false);
    const [appointmentsData,setAppointmentsData] = useState([]);
    const [error,setError] = useState(null); // New state for handling errors

    const getAppointmentsByPatient = async() => {
        try{
            setError(null); // Reset error state before making the request
            setLoadingData(true);

            const response = await Axios.get(
                `${process.env.REACT_APP_API_URL_BASE}/appointments/findByPatient`,
                {
                    params:{patientId},
                    headers:{Authorization:`Bearer ${jwt}`},
                }
            );

            setAppointmentsData(response.data);
            return response.data;
        }catch(error){
            const errorMessage = handleApiError(error,"Something's Wrong, please contact Support");
            setError(errorMessage.error); // Set the error state
            swal("Error!",errorMessage.error,"warning");
            throw error; // Rethrow the error so that the calling code can handle it if needed
        }finally{
            setLoadingData(false);
        }
    };

    return {loadingData,appointmentsData,error,getAppointmentsByPatient};
};
/**
 * Appointments History By Provider
 * @returns {{getAppointmentsHistoryByProvider: ((function(): Promise<any|undefined>)|*), loadingHistoryData: boolean, appointmentsHistory: *[], error: unknown}}
 */
const useAppointmentsHistoryByProvider = (providerId,jwt) => {
    const [loadingHistoryData,setLoadingHistoryData] = useState(false);
    const [appointmentsHistory,setAppointmentsHistory] = useState([]);
    const [error,setError] = useState(null); // New state for handling errors

    const getAppointmentsHistoryByProvider = async() => {
        try{
            setError(null); // Reset error state before making the request
            setLoadingHistoryData(true)

            const response = await Axios.get(
                `${process.env.REACT_APP_API_URL_BASE}/appointments/findByProvider/history?id=${providerId}`,
                {
                    headers:{Authorization:`Bearer ${jwt}`},
                }
            );

            setAppointmentsHistory(response.data);
            return response.data;
        }catch(error){
            const errorMessage = handleApiError(error,"Something's Wrong, please contact Support");
            setError(errorMessage.error); // Set the error state
            swal("Error!",errorMessage.error,"warning");
            throw error; // Rethrow the error so that the calling code can handle it if needed
        }finally{
            setLoadingHistoryData(false);
        }
    };
    return {loadingHistoryData,appointmentsHistory,error,getAppointmentsHistoryByProvider};
}
/**
 * Find provider by id
 * @return {{getProviderById: ((function(*): Promise<any|undefined>)|*), loading: boolean, error: unknown, providerData: unknown}}
 *
 */
const useGetProviderById = (providerId,jwt) => {
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [providerData,setProviderData] = useState(null);

    const getProviderById = async() => {
        try{
            setLoading(true);
            const response = await Axios.get(`${process.env.REACT_APP_API_URL_BASE}/appointments/findByProviderId?id=${providerId}`,{
                headers:{
                    Authorization:`Bearer ${jwt}`,
                },
            });
            setProviderData(response.data);
            return response.data;
        }catch(error){
            setError(handleApiError(error,"Error getting provider by id."));
            return error.response ? error.response.data : {error:"Unknown error occurred."};
        }finally{
            setLoading(false);
        }
    };

    return {loading,error,providerData,getProviderById};

}


export {
    useCreateUserPatient,
    useGetPlans,
    useAffiliateUser,
    useCheckout,
    useCreateUsersBeneficiary,
    useAffiliationsBeneficiaries,
    useStripePaymentSuccess,
    usePlanByAffiliationId,
    useAppointmentsByPatient,
    useAppointmentsHistoryByProvider,
    useGetProviderById,
    useGetPatientData
};
