import React from "react";
import ReactLoading from "react-loading";

const Loading = ({ type, color, height, width }) => (
  <ReactLoading
    type={type || "balls"}
    color={color || "#fff"}
    height={60 || height}
    width={50 || width}
    className="mx-auto"
  />
);

export default Loading;
