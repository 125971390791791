import React, { useState, useEffect } from "react";
import RatingComponent from "../../utils/StarsComponent";
import {Box, Button} from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import DescriptionIcon from "@material-ui/icons/Description";
import Tags from "../../widgets/Tags";
import MessageIcon from "@material-ui/icons/Message";
import { useHistory } from "react-router-dom";
import ProfileLogo from "../../../images/profile-2.svg";
import useBDocTranslator from "../../hooks/useBDocTranslator";

//flags images
import NICFlag from "../../../images/nicaragua-flag.png";
import CRCFlag from "../../../images/cr-flg.png";
import BahFlag from "../../../images/bahamas-flag.png";
import USAFlag from "../../../images/usa-flag.png";
import JAMFlag from "../../../images/jamaica-flag.png";
import {renderPlans} from "../../utils/RenderPlans";

export default function ProviderCardOperations(props) {
  let history = useHistory();
  const { t } = useBDocTranslator();

  const handleClick = () => {
    history.push({
      pathname: "/users/op/appointments/create",
      state: {
        providerId: props.providerId,
        providerUserId: props.providerUserId,
      },
    });
  };
  const handleClickDetails = () => {
    history.push({
      pathname: `provider/details/${props.id}`,
      state: props.profileImage,
    });
  };

  const mathCountryFlag = (country) => {
    switch (country) {
      case "CRC":
        return (
          <img className="flag-size rounded" src={CRCFlag} alt="CRCFlag" />
        );
      case "USA":
        return (
          <img className="flag-size rounded" src={USAFlag} alt="USAFlag" />
        );
      case "NIC":
        return (
          <img className="flag-size rounded" src={NICFlag} alt="NICFlag" />
        );
      case "TBH":
        return (
          <img className="flag-size rounded" src={BahFlag} alt="BahFlag" />
        );
      case "JAM":
        return (
          <img className="flag-size rounded" src={JAMFlag} alt="JAMFlag" />
        );

      default:
        return (
          <img
            className="flag-size rounded"
            src={CRCFlag}
            alt="CRCFlagDefault"
          />
        );
    }
  };

  console.log("plans", props?.plans)

  return (
    <>
      <div className="d-flex justify-content-around shadow p-3 mb-5 bg-white rounded-lg-custom col-sm-12">
        {/* Photo and Star section */}
        <div className="text-center col-md-4 col-sm-12">
          <img
            src={props.profileImage || ProfileLogo}
            alt="avatar"
            className="rounded-circle img-fluid w-50"
          />
          <div className="mt-2">
            <RatingComponent editRate={false} rating={props.rating} />
          </div>
          <div className="text-center">
            {/* Tag Component to render the specialities */}
            <Tags tags={props.specialty || []} />
          </div>
          <div className="text-center">
            {props?.plans && (
                <Box className="plans">
                  {renderPlans(props?.plans)}
                </Box>
            )}
          </div>
        </div>
        {/* section divider  */}
        <div className="d-flex row align-content-center">
          <hr className="divider-main" />
        </div>
        <div className="d-flex row justify-content-center align-items-center p-5">
          <div className="col-md-12">
            <h4 className="pl-2 font-weight-bold">
              {props.fullname || "Undefined Name"}
            </h4>
          </div>
          <div className="col-md-12">
            <>
              <span className="w-10-custom ml-3">
                <AccessAlarmIcon
                  className={`${
                    props.available ? "text-success" : "text-danger"
                  }`}
                />{" "}
              </span>
              <span
                className={`${
                  props.available ? "text-success" : "text-danger"
                } fontS-2`}
              >
                {props.available
                  ? t("Consultation availables for today")
                  : t("No Available for today")}
              </span>
            </>

            <div className="col-md-12 mt-3">
              <p>
                <span className="font-weight-bold">{t("Location")}: </span>
                <span>
                  {`${props.city ?? "TBD"} ${props?.country} `}
                  {mathCountryFlag(props?.country)}
                </span>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <Button
              variant="contained"
              color="primary"
              size="medium"
              className="w-auto m-1"
              startIcon={<DescriptionIcon />}
              //className="w-30-custom"
              onClick={handleClickDetails}
            >
              {t("Details")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              className="w-auto m-1"
              startIcon={<EventIcon />}
              //className={classes.submit}
              onClick={handleClick}
            >
              {t("Schedule Appointment")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
