import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import PatientImage from "../../images/paciente2.svg";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import LoadingSpinner from "../utils/Loading";
import Copyright from "../utils/Copyright";
import "../../styles/registration.css";
import TermsOfUsePatient from "./termsOfUse/termsOfUsPatients.pdf";
import TranslationButton from "../utils/TranslationButton";
// import PaymentCheckoutForm from "./PaymentCheckOutForm";
// import CheckoutStripeWrapper from "../payments/CheckoutStripeWrapper";
import PatientsRegistrationLogic from "./PatientsRegistrationLogic";
import useBDocTranslator from "../hooks/useBDocTranslator";
import PaymentShowPlans from "./HealthPlans";
import IncludeFamilyMemberForm from "./IncludeFamilyMemberForm";
export default function PatientsRegistration() {
  const {
    setEmail,
    setPhone1,
    setDateOfBirth,
    setGender,
    loading,
    errorMessage,
    setPassword,
    jwt,
    acceptTerms,
    setAcceptTerms,
    t,
    handleClick,
    classes,
    setFirstName,
    setLastname,
    setPlan,
    onChangeLanguaje,
    plan,
  } = PatientsRegistrationLogic();

  const objPlans = [
    { id: 1, name: "Continuar con pagos por consulta", price: 0 },
    { id: 2, name: "Afiliacion a Plan de Salud", price: 0 },
  ];

  return (
    <Container component="main" maxWidth="xl" className={classes.container}>
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.btnContainer}>
          <TranslationButton onChangeLanguaje={onChangeLanguaje} />
        </div>
        <Avatar className={classes.avatar} src={PatientImage}></Avatar>
        <Typography component="h1" variant="h5">
          <strong>{t("Crear Cuenta de Paciente")}</strong>
        </Typography>
        <Typography component="h6" variant="h6" className="mt-2">
          {errorMessage.visible && jwt === "" && (
            <p className="text-danger">
              {errorMessage?.error || t("Por favor complete todos los campos")}
            </p>
          )}
          {jwt && <p className="text-success">{t("Registro Exitoso")}</p>}
        </Typography>
        <form className={classes.form} noValidate id="patient-form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="firstName"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label={t("Nombre")}
                autoFocus
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label={t("Apellido'")}
                name="lastName"
                autoComplete="lname"
                onChange={(e) => setLastname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label={t("Direccion de Correo Electronico")}
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="dateOfBirth"
                name="dateOfBirth"
                defaultValue=""
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                label={t("Fecha de Nacimiento")}
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="gender">{t("Genero")}</InputLabel>
                <Select
                  fullWidth
                  labelId="gender"
                  id="gender"
                  onChange={(e) => setGender(e.target.value)}
                  label={t("Genero")}
                >
                  <MenuItem value={"MALE"}>{t("Masculino")}</MenuItem>
                  <MenuItem value={"FEMALE"}>{t("Femenino")}</MenuItem>
                  <MenuItem value={"OTHER"}>{t("Otro")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Phone Number */}
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="phone"
                label="Telefono"
                name="phone"
                autoComplete="phone"
                type="tel"
                inputProps={{ pattern: "[0-9]*" }}
                onChange={(e) => setPhone1(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label={t("Password")}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="healthCarePlan">
                  {t("Plan de Salud")}
                </InputLabel>
                <Select
                  fullWidth
                  labelId="healthCarePlan"
                  id="healthCarePlan"
                  label={t("Healthcare Plan")}
                  onChange={(e) => setPlan(e.target.value)}
                >
                  {objPlans.map((plan) => (
                    <MenuItem key={plan.id} value={plan.name}>
                      {plan.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid className="d-flex" item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value="acceptTerms"
                    color="primary"
                    onClick={(e) => setAcceptTerms(!acceptTerms)}
                  />
                }
                // label={"I have read and agree to the Platforms terms." + linkUseTerms}
                label={
                  <div className="d-flex align-items-center">
                    <span>{t("He leido y acepto las")}</span>
                    <Link href={TermsOfUsePatient} className={classes.termsUse}>
                      {t("condiciones del servicio")}
                    </Link>
                  </div>
                }
              />
            </Grid>
          </Grid>
          {loading && (
            <div className="d-flex justify-content-center">
              <LoadingSpinner type="spinningBubbles" color="#1d3557" />
            </div>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleClick}
          >
            {t("Registrarse")}
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/patient/login" variant="body2">
                {t("already.have.account.signin")}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
