import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(1, 0),
        maxWidth: 380,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[500],
        borderRadius: 0,
    },
    noPlansBanner: {
        backgroundColor: theme.palette.warning.main,
        color: 'white',
        padding: theme.spacing(1),
        width: 'fit-content',
        position: 'relative',
        top: 0,
        left: '50%',
        transform: 'translate(20%, -20%)',
        zIndex: 1,
        boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.75)',
    },
    cardTitle: {
        fontSize: '1.5rem',
        marginBottom: theme.spacing(2),
    },
    cardDescription: {
        fontSize: '1rem',
        marginBottom: theme.spacing(2),
    },
    divider: {
        height: '1px',
        background: theme.palette.grey[500],
        marginBottom: theme.spacing(3),
    },
}));

const NoPlansCard = () => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <div className={classes.noPlansBanner}>
                <Typography variant="body3">No Plans Available</Typography>
            </div>
            <CardContent>
                <Typography variant="h5" className={classes.cardTitle}>
                    No Plans Found
                </Typography>
                <div className={classes.divider}></div>
                <Typography variant="body1" className={classes.cardDescription}>
                    There are currently no plans available. Please check back later.
                </Typography>
            </CardContent>
        </Card>
    );
};

export default NoPlansCard;
