import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import LoadingSpinner from "../utils/Loading";
import DashboardAdmin from "../administrator/DashboardAdmin";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import useUserState from "../hooks/useUserState";
import swal from "sweetalert";

import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Container,
  MenuItem,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    backgroundColor: "white",
    boxShadow: "5px 5px 16px 1px #1b3342",
    borderRadius: "10px",
  },
  gender: {
    width: "100%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "transparent",
    borderRadius: 0,
    width: "60px",
    height: "60px",
    marginBottom: "10px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: 120,
    width: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function ProvidersRegistration() {
  const classes = useStyles();
  let history = useHistory();

  const [email, setEmail] = useState("");
  const [identification, setIdentification] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [country, setCountry] = useState("");
  const [gender, setGender] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    visible: false,
    msgClass: "",
  });
  const [jwt, setJwt] = useState("");
  const userState = useUserState();

  const clearForm = () => {
    document.getElementById("provider-form").reset();
    setEmail("");
    setLastname("");
    setfirstName("");
    setPhone("");
    setCountry("");
    setGender("");
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (
      email &&
      firstName &&
      lastname &&
      phone &&
      country &&
      gender &&
      identification
    ) {
      setLoading(true);
      Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/provider/create`,
        {
          email,
          identification,
          phone1: phone,
          firstName,
          lastname,
          password: "123456",
          country,
          gender,
          rol_id: 3, // 3 -> for providers
          person_type: "PROV",
          summary: "Complete...",
        },
        {
          headers: { Authorization: "Bearer " + userState[0].jwt }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          setLoading(false);
          swal("Success!", "Provider registered successfully", "success");
          clearForm();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          swal("Error!", "something went wrong", "error");
        });
    } else {
      setErrorMessage({
        error: "Error, Please complete the fields",
        visible: true,
        msgClass: "alert alert-danger",
      });
    }
  };

  return (
    <DashboardAdmin sectionTitle="Providers" description="Add Provider">
      <Container component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h6" className="mt-2">
            Provider Registration Form
          </Typography>
          <Typography component="h6" variant="h6" className="mt-2">
            {errorMessage.visible && (
              <p className="text-danger">Please complete the fields</p>
            )}
          </Typography>
          <form className={classes.form} noValidate id="provider-form">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={(e) => setfirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastname"
                  autoComplete="lname"
                  onChange={(e) => setLastname(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="identification"
                  label="Identification"
                  name="identification"
                  autoComplete="Identification"
                  onChange={(e) => setIdentification(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  autoComplete="phone"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id="country"
                  required
                  select
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  placeholder="Select"
                  label="Country"
                  value={country || "select"}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <MenuItem value="CRC">Costa Rica</MenuItem>
                  <MenuItem value="USA">USA</MenuItem>
                  <MenuItem value="JAM">Jamaica</MenuItem>
                  <MenuItem value="NIC">Nicaragua</MenuItem>
                  <MenuItem value="BAH">Bahamas</MenuItem>
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                {/* <FormControl variant="outlined" className={classes.gender}>
                    <InputLabel required id="gender">
                        Gender
                    </InputLabel>
                    <Select
                        labelId="gender"
                        id="gender"
                        margin="normal"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        label="Gender"
                    >
                        <MenuItem value={""}>None</MenuItem>
                        <MenuItem value={"MALE"}>Male</MenuItem>
                        <MenuItem value={"FEMALE"}>Female</MenuItem>
                        <MenuItem value={"OTHER"}>Other</MenuItem>
                    </Select>
                </FormControl> */}
                <TextField
                  id="gender"
                  required
                  select
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  placeholder="Select"
                  label="Gender"
                  value={gender || "select"}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value={"MALE"}>Male</MenuItem>
                  <MenuItem value={"FEMALE"}>Female</MenuItem>
                  <MenuItem value={"OTHER"}>Other</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            {loading && (
              <div className="d-flex justify-content-center">
                <LoadingSpinner type="spinningBubbles" color="#1d3557" />
              </div>
            )}
            <Grid className="m-3" container justify="flex-start">
              <Grid item>Default password: 123456</Grid>
            </Grid>
          </form>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center ">
            <div className="p-3">
              <Button
                variant="contained"
                color="success"
                startIcon={<CheckCircleOutlineIcon />}
                onClick={handleClick}
              >
                Save
              </Button>
            </div>

            <div className="p-3">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CancelIcon />}
                onClick={(e) => history.goBack()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </DashboardAdmin>
  );
}
