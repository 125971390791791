import React from "react"
import ImageNotFound from "../../images/notfound.png";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const NotFound = () => {
    let history = useHistory();  
    
    const handleCancel = () => {
        history.goBack();
    };

  return(
    <div className="d-flex row" style={{height:"100vh"}}>

        <IconButton
            aria-label="delete"
            className="d-flex justify-content-center p-3"
            fontSize="large"
            onClick={handleCancel}
        >
            <ArrowBackIcon />
            <div className="d-flex col p-3 m-0">
                <h5 className="m-0">Go Back</h5>
            </div>
        </IconButton>

        <div className="d-flex row justify-content-center">
            <img className="w-75" src={ImageNotFound} alt="Page not Found" />
        </div>
    </div>
  )
}
export default NotFound;
