import React, { useEffect } from "react";
import { MdHeadsetMic } from "react-icons/md";
import {
  Card,
  Box,
  CardContent,
  Avatar,
  CardActions,
  Button,
  Chip,
} from "@material-ui/core/";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function OrderCard({
  serviceName,
  servicePrice,
  providerName,
  serviceStatus,
  apptDate,
  handleShowDetails,
}) {
  const { t, changeDirectLanguage } = useBDocTranslator();

  const chipColor = (status) => {
    let result = "default";

    if (servicePrice === 0) return (result = "success");

    switch (status) {
      case "completed":
        result = "success";
        break;
      case "canceled":
        result = "error";
        break;
      case "generated":
        result = "warning";
        break;
      default:
        result = "default";
    }

    return result;
  };

  const chipStatusText = (status) => {
    let result = "NO-Status";

    if (servicePrice === 0) return (result = "Payed");

    switch (status) {
      case "completed":
        result = "Payed";
        break;
      case "canceled":
        result = "Cancelled";
        break;
      case "generated":
        result = "Awaiting for Payment";
        break;
      default:
        result = "NO-Status";
    }

    return t(result);
  };

  useEffect(() => {
    changeDirectLanguage();
  }, []);

  return (
    <Box
      sx={{
        width: 400,
        height: "auto",
        borderRadius: "10px",
        margin: "5px",
        boxShadow: 2,
      }}
    >
      <Card variant="elevation">
        <CardContent>
          <div className="w-full card_header_container mb-1">
            <div className="row-title">
              <Avatar />{" "}
              <h5 className="m-1">
                {t("Service")}: {serviceName || "SERVICE NAME"}
              </h5>
            </div>
            <Chip
              label={chipStatusText(serviceStatus)}
              color={chipColor(serviceStatus)}
              className="chip-card-label"
              size="small"
            />
          </div>

          <p className="card_description">
            {t("Your consultation was scheduled on")} <b>{apptDate}</b>{" "}
            {t("and the price was")} <b>${servicePrice}</b>
          </p>
        </CardContent>
        <hr style={{ borderTop: "0.5px solid lightgray" }} />
        <CardActions>
          <Button size="small" onClick={handleShowDetails}>
            {t("Need Help")}
          </Button>
          <MdHeadsetMic />
        </CardActions>
      </Card>
    </Box>
  );
}
