import React, { useState, useEffect } from "react";
import Axios from "axios";
import LoadingSpin from "../utils/Loading";
import TableUsers from "../utils/TableUsers";
import UseUserState from "../hooks/useUserState";
import UserIcon from "./../../images/user-icon.svg";
import { useHistory } from "react-router-dom";
import DashboardAdmin from "../administrator/DashboardAdmin";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

function Users() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const localState = UseUserState();
  let history = useHistory();

  //Function for Edit the selected User
  const handleClickEdit = (row) => {
    history.push({ pathname: "/users/edit/" + row.id, state: row });
  };

  //Function for Add new User
  const handleAddButton = (row) => {
    history.push("/users/create");
  };

  useEffect(() => {
    setLoading(true);

    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/users`, {
      headers: {
        Authorization: "Bearers " + localState[0].jwt, //the jwt is a variable which holds the token
      },
    })
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <DashboardAdmin
      sectionTitle="Manage Users"
      description="User details"
      icon={UserIcon}
    >
      <div className="container-fluid mt-5">
        <div className="d-flex justify-content-end col-md-12 m-3 ">
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddButton}
          >
            Add User
          </Button>
        </div>
        {loading && <LoadingSpin type="spinningBubbles" color="#1d3557" />}
        {/* {data && <UsersTable data={data} columns={columns}></UsersTable>} */}
        {data && <TableUsers data={data} clickEvent={handleClickEdit} />}
      </div>
    </DashboardAdmin>
  );
}

export default Users;
