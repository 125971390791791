import React, {useState, useEffect, useMemo} from "react";
import swal from "sweetalert";
import Axios from "axios";
import {useParams, useHistory} from "react-router-dom";
import {Button, TextField} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

import UseUserState from "../hooks/useUserState";
import DashboardPatient from "../dashboard/DashboardPatient";
import {useLocation} from "react-router-dom";
import CheckoutResume from "./CheckoutResume";
import HeadingResume from "../widgets/HeadingResume";
import mobilePaymentIcon from "../../images/mobile-payment.svg";
import Backdrop from "@material-ui/core/Backdrop";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import PaypalButton from "./PaypalButton";
import useBDocTranslator from "../hooks/useBDocTranslator";
import CheckoutStripeWrapper from "./CheckoutStripeWrapper";
import {handlePaymentSuccess} from "../../services/paymentService";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export default function Checkout(props) {
    const location = useLocation();
    let history = useHistory();
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    //jwt received in the email an placed on uri
    let {id} = useParams();
    const [redirectAfterPay, setRedirectAfertPay] = useState(false);
    const {t, changeDirectLanguage} = useBDocTranslator();
    const [clientSecret, setClientSecret] = useState(null)

    const memClientSecret = useMemo(() => clientSecret, [clientSecret])

    const [errors, setErrors] = useState([]);
        const userState = UseUserState();
    const {
        appointmentId,
        patient_fullname,
        service_name,
        service_duration,
        price,
    } = location.state;

    //this method manage the backdrop status
    const handleClose = () => {
        setOpen(false);
    };


    //Handle the response on successfuly payment with paypal
    const handlePaypalSuccess = (response) => {
        handlePaymentSuccess(response, appointmentId, userState, setOpen, setErrors, setRedirectAfertPay, t);
    };

    useEffect(() => {
        changeDirectLanguage();
        if (redirectAfterPay) {
            setTimeout(() => {
                history.push({
                    pathname: "/patient/appointments",
                });
            }, 3000);
        }
    }, [redirectAfterPay]);


    const createPaymentIntent = async () => {

        try {
            const createIntentResponse = await Axios.post(
                `${process.env.REACT_APP_API_URL_BASE}/payments/create-payment-intent`,
                {
                    "items": [
                        {
                            "id": appointmentId.toString(),
                            "amount": price * 100 // for $14 => 1400
                        }
                    ]
                },
                {
                    headers: {Authorization: "Bearer " + userState[0].jwt},
                }
            )

            console.log("createIntentResponse", createIntentResponse)

            return createIntentResponse.data

        } catch (e) {
            console.log("Create Intent Error", e)
        }
    }


    // Stripe create intent payment
    useEffect(() => {

        setOpen(true);
        if(memClientSecret !== null){
            setOpen(false);
            return
        }
        createPaymentIntent()
            .then(res => setClientSecret(res.clientSecret))
            .catch(errors => {
                setOpen(false)
                console.log("createIntentErr", errors)
            })

        setOpen(false);

    }, []);

    return (
        <DashboardPatient>
            {/* Element that block the screen when request its happen */}
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <HeadingResume
                title={t("Checkout & Payment")}
                description={t(
                    "Once you make the payment we'll notify to Healthcare Provider and we'll send you invoice copy"
                )}
                icon={mobilePaymentIcon}
            />
            <div>
                <div className="row m-3 p-3 shadow w-30 rounded-lg-custom bg-white">
                    <CheckoutResume
                        serviceType={service_name}
                        serviceDuration={service_duration}
                        price={price}
                    />
                    <div className="col-md-6 col-sm-12 pl-md-3">
                        {/* Stripe Payment Component */}
                        <CheckoutStripeWrapper clientSecret={memClientSecret}/>

                        {/* Paypal Buttons */}
                        <div className="mt-2 paypal-buttons text-center w-100">
                            <div className="m-2">
                                <span>{t("Secure Payments with PayPal")}</span>
                            </div>
                            <PaypalButton
                                handlePaypalSuccess={handlePaypalSuccess}
                                backdropOpen={setOpen}
                                amount={`${price}.00`}

                            />
                        </div>
                        <div className="sq-error-message">
                            {/* {errors &&
            errors.map((errorMessage) => (
              <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
            ))} */}
                            {errors && (
                                <li key={`sq-error-${errors.errorMessage}`}>
                                    {errors.errorMessage}
                                </li>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </DashboardPatient>
    );
}
