import React from "react";
import { PayPalButton } from "react-paypal-button-v2";

export default function PaypalButton(props) {
  return (
    <div className="text-center">
      <PayPalButton
        options={{
          currency: "USD",
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
          disableFunding: "card",
        }}
        amount={props.amount}
        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        onSuccess={(details, data) => {
          props.backdropOpen(true);
          //details: contain detail data for payment
          props.handlePaypalSuccess(details);
          console.log("Payment response", { data, details });
          /* OPTIONAL: Call your server to save the transaction
            return fetch("/paypal-transaction-complete", {
            method: "post",
            body: JSON.stringify({
              orderID: data.orderID,
            }),
          }); */
        }}
      />
    </div>
  );
}
