import React from "react";
import Chip from "@material-ui/core/Chip";
import DomainIcon from "@material-ui/icons/Domain";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function Tags(props) {
  const { t } = useBDocTranslator();

  const { tags } = props;
  return tags.map((tagItem, idx) => {
    if (tagItem) {
      return (
        <Chip
          key={idx}
          icon={<DomainIcon />}
          label={t(tagItem) || ""}
          clickable={false}
          className="m-2"
          color="primary"
        />
      );
    }
  });
}
