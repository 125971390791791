import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ProviderImage from "../../images/doctor.svg";
import Axios from "axios";
import LoadingSpinner from "../utils/Loading";
import Copyright from "../utils/Copyright";
import "../../styles/registration.css";
import TermsOfUseProvider from "./termsOfUse/termsOfUseProvider.pdf";
import TranslationButton from "../utils/TranslationButton";
import useBDocTranslator from "../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    backgroundColor: "white",
    boxShadow: "5px 5px 16px 1px #1b3342",
    borderRadius: "10px",
  },
  termsUse: {
    marginLeft: "10px",
    cursor: "pointer",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "transparent",
    borderRadius: 0,
    width: "60px",
    height: "60px",
    marginBottom: "10px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: 120,
    width: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  btnContainer: {
    display: "flex",
    alignContent: "flex-end",
    width: "100%",
  },
}));

export default function ProvidersRegistration() {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [phone1, setPhone1] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    visible: false,
    msgClass: "",
  });
  const [password, setPassword] = useState("");
  const [jwt, setJwt] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);

  const clearForm = () => {
    document.getElementById("provider-form").reset();
    setEmail("");
    setLastname("");
    setfirstName("");
    setPhone1("");
    setPassword("");
  };

  const { onChangeLanguaje, t } = useBDocTranslator();

  const handleClick = (e) => {
    e.preventDefault();
    if(!checkPassword(password)){
      setErrorMessage({
        error: t("password is weak"),
        visible: true,
        msgClass: "alert alert-danger",
      });
      return;
    }
    if (email && firstName && lastname && phone1 && password && acceptTerms) {
      setLoading(true);
      Axios.post(`${process.env.REACT_APP_API_URL_BASE}/provider/create`, {
        email,
        phone1,
        firstName,
        lastname,
        password,
        rol_id: 3, // 3 for Providers
        identification: "",
        summary: "pending...",
      })
        .then((response) => {
          setLoading(false);
          setJwt(response.data.jwt);
          setErrorMessage({
            error: t("signup.provider.success.message"),
            visible: true,
            msgClass: "alert alert-success",
          });
          clearForm();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          setErrorMessage({
            error: error,
            visible: true,
            msgClass: "alert alert-danger",
          });
        });
    } else {
      setErrorMessage({
        error: t("please.complete.the.fields"),
        visible: true,
        msgClass: "alert alert-danger",
      });
    }
  };

  const checkPassword = (password) => {
    if (password.length < 8) {
      return false;
    }
    if (password.search(/[a-z]/i) < 0) {
      return false;
    }
    return password.search(/[0-9]/) >= 0;

  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.btnContainer}>
          <TranslationButton onChangeLanguaje={onChangeLanguaje} />
        </div>
        <Avatar className={classes.avatar} src={ProviderImage}></Avatar>
        <Typography component="h1" variant="h5">
          <strong>{t("login.provider.signuptitle")}</strong>
        </Typography>
        <Typography component="h6" variant="h6" className="mt-2">
          {errorMessage.visible && jwt === "" && (
            <p className="text-danger">{t("please.complete.the.fields")}</p>
          )}
          {jwt && (
            <p className="text-success text-center">
              {t("signup.provider.success.message")}
            </p>
          )}
        </Typography>
        <form className={classes.form} noValidate id="provider-form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label={t("first.name")}
                autoFocus
                onChange={(e) => setfirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label={t("last.name")}
                name="lastname"
                autoComplete="lname"
                onChange={(e) => setLastname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="phone"
                label={t("phone.number")}
                name="phone"
                autoComplete="phone"
                onChange={(e) => setPhone1(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label={t("email.address")}
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label={t("password")}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={acceptTerms}
                    color="primary"
                    onClick={(e) => setAcceptTerms(!acceptTerms)}
                  />
                }
                label={
                  <div className="d-flex align-items-center">
                    <span>{t("terms.announcement.aggree")}</span>
                    <Link
                      href={TermsOfUseProvider}
                      className={classes.termsUse}
                    >
                      {t("platform.terms")}
                    </Link>
                  </div>
                }
              />
            </Grid>
          </Grid>
          {loading && (
            <div className="d-flex justify-content-center">
              <LoadingSpinner type="spinningBubbles" color="#1d3557" />
            </div>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleClick}
          >
            {t("registration.signup")}
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/admin/login" variant="body2">
                {t("already.have.account.signin")}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
