import React, { useState, useEffect } from "react";
import LogoDefault from "../../images/facility-logo.svg";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Copyright from "../utils/Copyright";
import LoadingSpin from "../utils/Loading";
import Button from "@material-ui/core/Button";
import swal from "sweetalert";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import clsx from "clsx";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    backgroundColor: "white",
    boxShadow: "5px 5px 16px 1px #1b3342",
    borderRadius: "10px",
  },
  textField: {
    width: "100%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "transparent",
    width: "80px",
    height: "80px",
    borderRadius: 0,
    marginBottom: "30px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
}));

function PatientRecoverPassword() {
  const classes = useStyles();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const history = useHistory();

  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  let { id } = useParams();
  const [token, setToken] = useState(id);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleAxiosPutPaswword = (e) => {
    setLoading(true);
    Axios.put(
      `${process.env.REACT_APP_API_URL_BASE}/userPatient/changePassword`,
      {
        token,
        newPassword,
      },
      {
        headers: { Authorization: "Bearer " + id },
      }
    )
      .then((res) => {
        setLoading(false);
        swal({
          title: "Success!",
          text: "Password changed",
          icon: "success",
          button: "Login",
        }).then((result) => {
          if (result) {
            history.push(`/patient/login`);
          }
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        swal({
          title: "Failed!",
          text: "The link most be expired",
          icon: "error",
          button: "Send another email",
        }).then((result) => {
          if (result) {
            history.push(`/admin/login/recovery`);
          }
        });
      });
  };

  const handleChangePassword = (e) => {
    if (newPassword == confirmPassword && newPassword != "") {
      handleAxiosPutPaswword();
    } else {
      handleAlert();
    }
  };

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar} src={LogoDefault}></Avatar>
          <Typography component="h1" variant="h5">
            Please insert new password
          </Typography>
          <Typography component="h6" variant="h6" className="mt-2"></Typography>
          <form className={classes.form} noValidate>
            {/* <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    
                    onChange={(e) => setEmail(e.target.value)}
                    /> */}
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={110}
              />
            </FormControl>
            <FormControl
              className={clsx(classes.margin, classes.textField)}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={140}
              />
            </FormControl>
            {/* <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    /> */}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleChangePassword}
            >
              Change Password
            </Button>
          </form>
          <Box mt={8}>
            <Copyright />
          </Box>
        </div>
      </Container>
      <div className="m-3">
        {loading && (
          <div className="d-flex justify-content-center">
            <LoadingSpin type="spinningBubbles" color="#1d3557" />
          </div>
        )}
      </div>
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity="error">
          Confirm the password!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default PatientRecoverPassword;
