/**
 * DEPRECATED METHOD
 **/
import React, { useState, useContext, useEffect } from "react";
import ProfileLogo from "../../images/profile-2.svg";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import "../../styles/customs.css";
import UserContext from "../context/UserContext";
import PropTypes from "prop-types";
import Axios from "axios";
import LoadingSpin from "../utils/Loading";
import { setUserImageProfile } from "../../actions";
import { setNewUserImageProfile } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import ProviderEditProfile from "./ProviderEditProfile";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SaveIcon from "@material-ui/icons/Save";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DashboardMUI from "../dashboard/DashboardMui";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import providerIcon from "../../images/doctor.svg";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import CheckIcon from "@material-ui/icons/Check";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import FormHelperText from "@material-ui/core/FormHelperText";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import IconButton from "@material-ui/core/IconButton";
import UseUserState from "../hooks/useUserState";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));

/**
 * @deprecated
 * @returns
 */
function ProviderProfile() {
  const storeState = UseUserState();
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [identification, setIdentification] = useState("");
  const [identificationType, setIdentificationType] = useState("");
  const [country, setCountry] = useState("");
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(true);
  const [nationality, setNationality] = useState("");
  const [providerId, setProviderId] = useState("");
  const person_type = "PAT";
  const [imgLoading, setImgLoading] = useState(true);
  // const [isMounted, setIsMounted] = useState(null);
  const [openAlertImg, setOpenAlertImg] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    visible: false,
    msgClass: "",
  });
  const [countries, setCountries] = useState([]);
  const [data, setData] = useState("");
  const [jwtExpired, setJwtExpired] = useState("");

  const [licence, setLicence] = useState("");
  const [issuedBy, setIssuedBy] = useState("");
  const [title, setTitle] = useState("");
  const [issuedSaved, setIssuedSaved] = useState([]);
  const [specialty, setSpecialty] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState([]);
  const [newIssuedArray, setNewIssuedArray] = useState([]);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState();
  const [years, setYears] = useState([]);
  const [studies, setStudies] = useState({
    carrera: "",
    colegio: "",
    fechaInicio: "",
    fechaFinal: "",
  });
  const [experience, setExperience] = useState([
    {
      endDate: "March 2013",
      expSummary: "preventive Medicine",
      institute: "Sanatrix",
      job: "Doctor",
      startDate: "April 2005",
    },
  ]);
  const [lenguajes, setLenguajes] = useState([
    {
      lenguaje: "",
      level: "",
    },
    {
      lenguaje: "",
      level: "",
    },
  ]);

  //Distpach of Redux
  const distpach = useDispatch();

  //Extract the userState to Redux Glabal State
  const newUserImageState = useSelector((state) => state.newUserImage);
  const profileImg = useSelector((state) => state.userImage.profileImg);
  const exists = useSelector((state) => state.userImage.exists);

  const handleFileImage = (e) => {
    const file = e.target.files[0];
    readImage(file);
    distpach(setNewUserImageProfile(file));
    distpach(setUserImageProfile({ profileImg: "", exists: null }));
  };

  const readImage = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    console.log(reader.result);
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
  };

  // ------------------ FIND IMAGE CLOUDINARY ------------------

  const findImgByEmail = (email) => {
    // if(email != ""){
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/userImage/findByEmail`, {
      headers: {
        Authorization: "Bearer " + storeState[0].jwt,
        "Content-Type": "application/json",
      },
      params: { user_email: email },
    })
      .then((res) => {
        setImgLoading(false);
        distpach(
          setUserImageProfile({
            profileImg: res.data[0].path_resource,
            exists: true,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        setImgLoading(false);
        // changeUserProfileImg({ profileImg: "", exists: false });
        distpach(setUserImageProfile({ profileImg: "", exists: false }));
        setJwtExpired(err.response.status);
      });
  };

  const handleAlertImg = () => {
    setOpenAlertImg(true);
  };

  const handleCloseAlertImg = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlertImg(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleSaveProfileImg = (e) => {
    // console.log(userProfileImg.profileImg);
    if (newUserImageState.newProfileImg != undefined) {
      let formData = new FormData();
      formData.append("file_name", `${firstName}ProfileImg`);
      formData.append("created_by", firstName);
      formData.append("user_email", email);
      formData.append("file", newUserImageState.newProfileImg);
      console.log(formData);

      Axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL_BASE}/userImage/save`,
        data: formData,
        headers: {
          Authorization: "Bearer " + storeState[0].jwt,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          console.log(response);
          handleAlertImg();
          //Save response to Global State
          distpach(setUserImageProfile(response.data.dataSaved));
          findImgByEmail(email);
        })
        .catch(function (error) {
          //handle error
          console.log(error);
          swal(
            "Error!",
            "Something went wrong, please contact the Admin " + error,
            "error"
          );
        });
    } else {
      console.log("no hay imagen seleccionada");
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (
      email &&
      phone &&
      firstName &&
      lastname &&
      dateOfBirth &&
      gender &&
      providerId &&
      identification &&
      summary &&
      licence &&
      title &&
      selectedSpecialty.length > 0 &&
      issuedSaved
    ) {
      setLoading(true);
      const specialtyString = parseSpecialtyString(selectedSpecialty);
      if (specialtyString.length >= 150)
        return swal(
          "Error!",
          "Specialties overpass the maximum values, please select only the required values",
          "error"
        );
      const issuedString = parseIssuedString(issuedSaved);
      Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/provider/updateAndConfirm`,
        {
          email,
          phone1: phone,
          firstName,
          lastname,
          dateOfBirth,
          gender,
          identificationType,
          person_type,
          identification,
          country,
          nationality,
          id: providerId,
          summary,
          title,
          medical_license: licence,
          specialty: specialtyString,
          issued_by: issuedString,
        },
        {
          headers: { Authorization: "Bearer " + storeState[0].jwt }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          setLoading(false);
          console.log("RESPONSE", response);
          swal({
            tittle: "Success!",
            text: "Changes Saved",
            icon: "success",
            button: false,
            timer: 3000,
          });
          setErrorMessage({
            error: "",
            visible: false,
            msgClass: "",
          });
          //clearForm();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          setErrorMessage({
            error: error,
            visible: true,
            msgClass: "alert alert-danger d-flex justify-content-center",
          });
        });
    } else {
      setErrorMessage({
        error: "Error, Please fill the Form",
        visible: true,
        msgClass: "alert alert-danger ",
      });
      handleSaveProfileImg();
    }
  };

  // ----------------- COUNTRIES ----------------------

  const findCountries = () => {
    // if(email != ""){
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/countries/findAll`)
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // -------------------------- USEFFECT--------------------

  const setStatesGet = (data) => {
    findSpecialties();
    findCountries();
    setLoading(false);
    setData(data);
    //Set Data to Controls
    console.log(data);
    findImgByEmail(data.email);
    loadDataToControls(data);
    getWindowDimension();
  };

  const consoleLog = (data) => {
    console.log(data);
  };

  const getProvider = async (e) => {
    let res = await Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/provider/${storeState[0].jwt}`
    );
    setStatesGet(res.data[0]);
  };

  useEffect(() => {
    getProvider();
    handleYears();
  }, []);

  //------------------YEARS -------------------
  const handleYears = (e) => {
    let years = ["Present"];
    let date = new Date();
    let thisYear = date.getFullYear();
    console.log(thisYear);
    for (let i = thisYear; i > 1900; i--) {
      years.push(`${i}`);
    }
    console.log(years);
    setYears(years);
  };

  // ---------HANDLE INFO----------------

  const handleExperience = (
    job,
    institute,
    expSummary,
    monthStart,
    yearStart,
    monthEnd,
    yearEnd
  ) => {
    let startDate = monthStart + " " + yearStart;
    let endDate = monthEnd + " " + yearEnd;
    setExperience({
      job: job,
      institute: institute,
      startDate: startDate,
      endDate: endDate,
      expSummary: expSummary,
    });
  };
  const handleStudies = (info) => {
    setExperience({
      job: info.job,
      institute: info.institute,
      startDate: info.startDate,
      endDate: info.endDate,
      expSummary: info.expSummary,
    });
  };

  function loadDataToControls(data) {
    if (data) {
      handleIssuedBy(data.issued_by);
      handleSpecialties(data.specialty);
      const {
        email,
        firstName,
        lastname,
        identification,
        dateOfBirth,
        gender,
        country,
        nationality,
        id,
        phone1,
        identificationType,
        summary,
        title,
        medical_license,
      } = data;
      setEmail(email);
      setfirstName(firstName);
      setLastname(lastname);
      setIdentification(identification);
      setDateOfBirth(dateOfBirth && dateOfBirth.split("T")[0]);
      setGender(gender);
      setCountry(country);
      setNationality(nationality);
      setProviderId(id);
      setPhone(phone1);
      setIdentificationType(identificationType);
      setSummary(summary);
      setTitle(title);
      setLicence(medical_license);
    }
  }

  //---------------- ISSUED BY-----------------------

  const setIssued = (label) => {
    if (label) {
      setIssuedSaved([...issuedSaved, label]);
      setIssuedBy("");
    }
  };

  const parseIssuedString = (issuedArray) => {
    if (issuedArray) {
      let finalParsed = "";
      issuedArray.map((item, id) => {
        let index = id + 1;
        if (issuedArray.length != index) {
          finalParsed += item + ",";
        } else {
          finalParsed += item;
        }
      });
      return finalParsed;
    }
  };

  const handleIssuedBy = (issuedBy) => {
    if (issuedBy) {
      let issued = issuedBy.split(",");
      setIssuedSaved(issued);
    }
  };

  const handleDeleteIssued = async (array, index) => {
    array.splice(index, 1);
    console.log(issuedSaved);
    console.log(array);
    await setNewIssuedArray(array);
    await setIssuedSaved(array);
    await setNewIssuedArray([]);
  };

  const getWindowDimension = (e) => {
    setWidth(
      window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
    );
    setHeight(
      window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
    );
  };

  // --------------------SPECIALTY----------------------

  //Specialties stored on BD
  const findSpecialties = async () => {
    try {
      const result = await Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/specialty/findAll`
      );
      if (result) {
        console.log(result.data);
        // let specialties = []
        // result.data.map(specialty => {
        //   specialties.push(specialty.speciality_name)
        // })
        // console.log(specialties)
        setSpecialty(result.data);
      }
    } catch (error) {
      console.error("Something wrong", error);
    }
  };

  const parseSpecialty = (data) => {
    let finalParsed = [];
    data.map((item) => finalParsed.push(item.speciality_name));
    return finalParsed;
  };

  const parseSpecialtyString = (specialtiesArray) => {
    if (specialtiesArray) {
      let finalParsed = "";
      specialtiesArray.map((item, id) => {
        let index = id + 1;
        if (specialtiesArray.length != index) {
          finalParsed += item + ",";
        } else {
          finalParsed += item;
        }
      });
      return finalParsed;
    }
  };

  const handleSpecialties = (specialties) => {
    if (specialties) {
      let specialty = specialties.split(",");
      setSelectedSpecialty(specialty);
    }
  };

  //----------------------CHANGE INFO------------------
  const handleChangeInfo = (info, from) => {
    if (from == "email") {
      setEmail(info);
    }
    if (from == "firstName") {
      setfirstName(info);
    }
    if (from == "lastname") {
      setLastname(info);
    }
    if (from == "phone") {
      setPhone(info);
    }
    if (from == "country") {
      setCountry(info);
    }
    if (from == "identification") {
      setIdentification(info);
    }
    if (from == "identificationType") {
      setIdentificationType(info);
    }
    if (from == "dateOfBirth") {
      setDateOfBirth(info);
    }
    if (from == "gender") {
      setGender(info);
    }
    if (from == "summary") {
      setSummary(info);
    }
    if (from == "licence") {
      setLicence(info);
    }
    if (from == "title") {
      setTitle(info);
    }
    if (from == "issued") {
      setIssuedBy(info);
    }
    if (from == "specialty") {
      setSelectedSpecialty(info);
    }
    if (from == "studies") {
      setStudies(info);
    }
    if (from == "experience") {
      if (info.job) {
        setExperience([...experience, info]);
      }
    }
    if (from === "experienceDelete") {
      console.log(info);
      setExperience(info);
    }
    if (from == "idioms") {
      setLenguajes(info);
    }
  };

  return (
    <>
      <DashboardMUI
        sectionTitle="Provider"
        description="Edit Profile"
        icon={providerIcon}
      >
        <div className="shadow-lg p-3 mb-5 bg-white rounded">
          <div className="row d-flex justify-content-center">
            <div className="col-md-4 d-flex justify-content-center">
              <div className="text-center w-100 mw-75 shadow-sm p-3 mb-5 bg-white rounded">
                <article className="w-auto">
                  <p className="label-avatar">Complete your Profile</p>
                  <div className="img-cropper">
                    {exists == true ? (
                      <img
                        className="profile-avatar"
                        src={profileImg}
                        alt="Profile-logo"
                      />
                    ) : (
                      ""
                    )}
                    {!newUserImageState.newProfileImg ? (
                      ""
                    ) : (
                      <img
                        className="profile-avatar"
                        src={imageUrl}
                        alt="Profile-logo"
                      />
                    )}
                    {exists == false ? (
                      <img
                        className="profile-avatar"
                        src={ProfileLogo}
                        alt="Profile-logo"
                      />
                    ) : (
                      ""
                    )}
                    {!imgLoading ? (
                      ""
                    ) : (
                      <div className="d-flex justify-content-center">
                        <LoadingSpin type="spinningBubbles" color="#1d3557" />
                      </div>
                    )}
                  </div>
                  <div className="mt-5">
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      className={classes.input}
                      multiple
                      type="file"
                      onChange={handleFileImage}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Change Profile Photo
                      </Button>
                    </label>
                  </div>
                </article>
              </div>
            </div>
            <ProviderEditProfile
              handleChangeInfo={handleChangeInfo}
              firstName={firstName}
              lastname={lastname}
              email={email}
              phone={phone}
              country={country}
              identification={identification}
              identificationType={identificationType}
              dateOfBirth={dateOfBirth}
              gender={gender}
              summary={summary}
              loading={loading}
              imgLoading={imgLoading}
              countries={countries}
              title={title}
              specialty={specialty}
              selectedSpecialty={selectedSpecialty}
              licence={licence}
              issuedSaved={issuedSaved}
              issuedBy={issuedBy}
              width={width}
              handleDeleteIssued={handleDeleteIssued}
              handleIssuedBy={setIssued}
              errorMessage={errorMessage}
              studies={studies}
              experience={experience}
              idioms={lenguajes}
              years={years}
              handleExperience={handleExperience}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center ">
            <div className="p-3">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CheckCircleOutlineIcon />}
                onClick={handleClick}
              >
                Save
              </Button>
            </div>
          </div>
          <div className="col-md-12">
            {loading && (
              <div className="d-flex justify-content-center mt-3">
                <LoadingSpin type="spokes" color="#1d3557" />
              </div>
            )}
          </div>
        </div>
        <Snackbar
          open={openAlertImg}
          autoHideDuration={5000}
          onClose={handleCloseAlertImg}
        >
          <Alert onClose={handleCloseAlertImg} severity="success">
            Success Profile Image Updated
          </Alert>
        </Snackbar>
      </DashboardMUI>
    </>
  );
}
ProviderProfile.propTypes = {
  imgLoading: PropTypes.bool,
};

export default ProviderProfile;
