import React from "react"
import '../styles/home.css'

const Footer = props => {
  return(
  <footer className="container">
        <p className="float-right"><a href="#">Back to top</a></p>
        <p>&copy; {new Date().getFullYear()} BestDoc App, Inc. &middot; <a href="#">Privacy</a> &middot; <a href="#">Terms</a></p>
  </footer>
  )
}
export default Footer

