import React, { useState, useEffect } from "react";
import DashboardAdmin from "../DashboardAdmin";
import SearchModule from "../../patient-portal/SearchModule";
import SunshineSVG from "../../../images/sunshine.svg";
import Axios from "axios";
import { useSelector } from "react-redux";
import Loading from "../../utils/Loading";
import { resizeByLink } from "../../utils/ResizeLinkCloudinary";
import swal from "sweetalert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import useUserState from "../../hooks/useUserState";
import useBDocTranslator from "../../hooks/useBDocTranslator";
import ProviderCardOperations from "./ProviderCardOperations";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function MainOperations() {
  const [providers, setProviders] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [specialties, setSpecialties] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const userLocalState = useUserState();

  const { t, changeDirectLanguage } = useBDocTranslator();

  //this method manage the backdrop status
  const handleClose = () => {
    setOpen(false);
  };

  const jwtSession = userLocalState[0].jwt;

  //Method for search all providers
  const fetchAllProviders = () => {
    try {
      setOpen(true);
      Axios.get(`${process.env.REACT_APP_API_URL_BASE}/provider/enabled`, {
        headers: { Authorization: "Bearer " + jwtSession }, //the jwt is a variable which holds the token
      })
        .then((response) => {
          setOpen(false);
          setLoadingData(false);
          setProviders(response.data);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  //Effect to fetch providers
  useEffect(() => {
    fetchAllProviders();
    changeDirectLanguage(userLocalState[0]?.language || "en");
  }, []);

  //Effect to fetch Specialty data
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/specialty/findAll`, {
      headers: { Authorization: "Bearer " + jwtSession }, //the jwt is a variable which holds the token
    })
      .then((response) => {
        setLoadingData(false);
        setSpecialties(response?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSearch = (country, specialty, provider) => {
    const countryParam = country?.code;
    const specialtyParam = specialty?.speciality_name;
    const providerParam = provider?.firstName; // consider the lastname as well

    if (!countryParam && !specialtyParam && !providerParam) {
      fetchAllProviders();
    } else {
      setOpen(true);

      Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/customSearch/provider?country=${
          countryParam || ""
        }&specialty=${specialtyParam || ""}&providerName=${
          providerParam || ""
        }`,
        {
          headers: { Authorization: "Bearer " + jwtSession }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          setOpen(false);

          setProviders(response.data);
        })
        .catch((err) => {
          console.error(err);
          setOpen(false);

          swal({
            title: "Failed!",
            text: "Error fetching Data for Selected Country",
            icon: "error",
            button: false,
          });
        });
    }
  };

  const parseSpecialty = (toParse) => {
    const specialtyArr = toParse.specialty && toParse.specialty.split(",");
    if (specialtyArr && specialtyArr.length > 0) {
      const result = specialtyArr.map((item) => {
        if (item.length > 2) {
          return item;
        }
      });
      return result;
    }
  };

  return (
    <DashboardAdmin>
      {/* Element that block the screen when request its happen */}
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="d-flex flex-column">
        {/* <article className="m-3 p-3 shadow w-30 rounded-lg-custom">
          <h1 className="font-weight-bold">
            {t("welcome.to.your.dashboard")}
            {"! "}
            <span>
              <img className="sunshine-icon" src={SunshineSVG} alt="sunshine" />
            </span>
          </h1>
          <p>{t("welcome.dashboard.message")}</p>
        </article> */}
        {/* Search component */}
        <div>
          <SearchModule
            handleSearch={handleSearch}
            specialties={specialties}
            providers={providers}
            language={userLocalState[0]?.language || "en"}
          />
        </div>
        <div>
          {loadingData && <Loading color="#3788d8d4" />}
          {providers &&
            providers.map((item) => {
              if (item.status_provider.status_name === "APPROVED") {
                const fullname = `${item.firstName} ${item.lastname}`;
                let profileImageURL;

                if (item.user?.user_images?.length > 0) {
                  profileImageURL = resizeByLink(
                    item.user.user_images.at(-1).path_resource,
                    "upload"
                  );
                }

                const specialitiesParsed = Array.of(
                  item.medical_speciality?.speciality_name
                );
                return (
                  <ProviderCardOperations
                    key={item.id}
                    id={item.id}
                    title={item.title}
                    fullname={fullname}
                    profileImage={profileImageURL}
                    specialty={specialitiesParsed}
                    providerId={item.id}
                    country={item.country}
                    providerImg={item.profile_image}
                    summary={item.summary}
                    providerUserId={item?.user?.id}
                    rating={item.rating_avg}
                    available={item?.availability}
                    city={item?.city}
                    plans={item?.provider_plans}
                  />
                );
              }
            })}
        </div>
      </div>
    </DashboardAdmin>
  );
}

export default MainOperations;
