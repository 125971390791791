import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DashboardAdmin from "../../DashboardAdmin";
import PlanBasicInfo from "./PlanBasicInfo";
import PlanProviders from "./PlanProviders";
import PlanSpecialties from "./PlanSpecialties";
import {PlanWizardLogic} from "./PlanWizardLogic";
import PlanReview from "./PlanReview";
import swal from "sweetalert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory} from "react-router-dom";
import TablePlans from "../../../utils/TablePlans";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: "50px",
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    }
}));

function getSteps() {
    return ['Plan Information', 'Specialties', 'Healthcare Providers', "Review"];
}


export default function PlanWizard() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const steps = getSteps();
    const [providers, setProviders] = useState([]);
    const [specialties, setSpecialties] = useState([]);
    const [open, setOpen] = useState(false);

    const history = useHistory();


    //this method manage the backdrop status
    const handleClose = () => {
        setOpen(false);
    };

    const {
        plan,
        setPlan,
        getProviderList,
        getSpecialtiesList,
        createPlan,
        planSpecialties,
        setPlanSpecialties,
        providersPlan,
        setProvidersPlan,
        isCompletePlanInfoForm,
        getPlans,
        planList,
        planToEdit,
        setPlanToEdit,
        resetPlanToEdit,
        getProvidersAssignationsByPlanId,
        updateProviderList
    } = PlanWizardLogic(setOpen);

    useEffect(() => {
        getProviderList().then(r => setProviders(r));
        getSpecialtiesList().then(r => setSpecialties(r));
    }, []);

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        if(plan?.status === "hidden" && parseInt(plan?.price) > 0){
            swal("Error", "You can't create a hidden plan with a price greater than 0", "error");
            return false;
        }

        if (activeStep === 0 && !isCompletePlanInfoForm()) {
            swal("Error", "Please fill all the fields", "error");
            return;
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <PlanBasicInfo planInfo={plan} setPlanInfo={setPlan} planToEdit={planToEdit} setPlanToEdit={setPlanToEdit}/>;
            case 1:
                return <PlanSpecialties specialties={specialties} planSpecialties={planSpecialties}
                                        setPlanSpecialties={setPlanSpecialties} planToEdit={planToEdit} setPlanToEdit={setPlanToEdit}/>;
            case 2:
                return <PlanProviders providers={providers} setPlanProviders={setProvidersPlan}
                                      planProviders={providersPlan} planToEdit={planToEdit} setPlanToEdit={setPlanToEdit}/>;
            case 3:
                return <PlanReview planInfo={plan} planSpecialtiesInfo={planSpecialties}
                                   planProvidersInfo={providersPlan} specialties={specialties} providers={providers} planToEdit={planToEdit}/>;
            default:
                return 'Unknown step';
        }
    }

    useEffect(() => {
        getPlans().then(r => console.log("getting plans::ok"));

    }, []);

    const clickEditPlan = (plan) => {
        // setPlanToEdit(plan);
        getProvidersAssignationsByPlanId(plan).then(r => {console.log(r)});
    }

    console.log("Plan Data", {plan, planSpecialties, providersPlan, planToEdit})

    return (

        <DashboardAdmin>
            {/* Element that block the screen when request its happen */}
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                <CircularProgress color="inherit"/>
            </Backdrop>

            <div className={classes.root}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <div>
                    {activeStep === steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Button onClick={handleReset} className={classes.button}>
                                Reset
                            </Button>
                        </div>
                    ) : (
                        <div>
                            {
                                getStepContent(activeStep)
                            }
                            {/* Button Back and Next Steps */}
                            <div className="d-flex justify-content-end mx-4">
                                {/*<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>*/}
                                {/*    Back*/}
                                {/*</Button>*/}

                                {
                                    planToEdit?.id ? (
                                        <Button onClick={resetPlanToEdit} className={classes.button}>
                                            Cancel Edition
                                        </Button>
                                    ) : null
                                }


                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        if (activeStep === steps.length - 1 && !planToEdit?.id) {
                                            setOpen(true);
                                            // request confirmation to create the plan with swal
                                            swal({
                                                title: "Are you sure?",
                                                text: "Once created, The plan would be open to subscribers!",
                                                icon: "warning",
                                                buttons: true,
                                                dangerMode: true,
                                            })
                                                .then((willCreate) => {
                                                    if (willCreate) {
                                                        createPlan().then(r => {
                                                            if (r) {
                                                                swal("Plan Created!", "Your plan has been created!", "success");
                                                                history.push("/users/settings");
                                                            }
                                                        })
                                                            .catch((err) => {
                                                                setOpen(false);
                                                                console.log(err);
                                                            })
                                                    } else {
                                                        setOpen(false);
                                                    }
                                                })
                                                .catch((err) => {
                                                    setOpen(false);
                                                    swal("Error", "Something went wrong, please try again later", "error");
                                                });


                                        }else if(activeStep === steps.length - 1 && planToEdit?.id) {
                                            // update plan
                                            const providers = planToEdit.providersPlan.map(({provider}) => {
                                                return {id: provider.id, status: provider?.status}
                                            });
                                            const response =  updateProviderList(providers).then(r => {
                                                console.log(r)
                                            })
                                            if(response){
                                                swal("Plan Updated!", "Your plan has been updated!", "success");
                                                history.push("/users/settings");
                                            }


                                        } else {
                                            handleNext()
                                        }
                                    }}
                                    className={classes.button}
                                >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="mb-2">
                <TablePlans clickEvent={clickEditPlan} data={planList}/>
            </div>
        </DashboardAdmin>

    );
}
