import React from "react";
import TablePatients from "../utils/TablePatients";

function PatientsForProvider(props) {
  return (
    <div className="shadow-sm p-3 mb-5 bg-white rounded mt-3">
      <p>Patients for this Provider</p>
      <TablePatients
        data={props.data}
        parentComponent={props?.parentComponent}
      />
    </div>
  );
}

export default PatientsForProvider;
