import { IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import TranslateIcon from "@material-ui/icons/Translate";

const useStyles = makeStyles((theme) => ({
  langButton: {
    margin: theme.spacing(1),
    color: "black",
  },
}));

export default function TranslationButton({ onChangeLanguaje }) {
  const classes = useStyles();

  return (
    <div className="row lang-buttons-container">
      <IconButton
        aria-label="ES-EN"
        className={classes.langButton}
        fontSize="large"
        onClick={onChangeLanguaje}
      >
        <TranslateIcon />
      </IconButton>
      <span>EN | ES</span>
    </div>
  );
}
