import React, { useState } from "react";
import { MenuItem, TextField, Button } from "@material-ui/core";
import useUserState from "../../hooks/useUserState";
import Axios from "axios";
import swal from "sweetalert";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import Loading from "../../utils/Loading";
import useBDocTranslator from "../../hooks/useBDocTranslator";

export default function HealthConditionForm(props) {
  const userLocalState = useUserState();
  const [conditionType, setConditionType] = useState("");
  const [conditionNotes, setConditionNotes] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useBDocTranslator();

  //Method for Save the prescription
  const handleSaveHealthCondition = () => {
    if (conditionType !== "" && conditionNotes !== "") {
      setLoading(true);
      Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/healthconditions/create`,
        {
          segment_name: conditionType,
          detail: conditionNotes,
          patient_id: props.patientId,
          provider_id: userLocalState[0].user.id,
        },
        {
          headers: { Authorization: "Bearer " + userLocalState[0].jwt },
        }
      )
        .then((response) => {
          setResponseMessage(`${conditionType} saved successfuly`);
          setConditionNotes("");
          setLoading(false);
          props.handleRefreshData();
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setResponseMessage(error);
        });
    } else {
      swal("Alert", t("Error, Please fill the Form"), "warning");
    }
  };

  const contidionValues = [
    { value: "H-CONDITION", name: "Health Condition" },
    { value: "SURGERIES", name: "Surgery" },
    { value: "HOSPITALIZATIONS", name: "Hospitalization" },
    { value: "MEDICATIONS", name: "Medication" },
    { value: "ALLERGIES", name: "Allergy" },
  ];

  return (
    <div className="w-100">
      <form className="col-12">
        <TextField
          id="conditions-list"
          required
          select
          fullWidth
          variant="outlined"
          margin="normal"
          placeholder={t("Select Condition")}
          label={t("Condition Type")}
          onChange={(e) => setConditionType(e.target.value)}
          value={conditionType || "select"}
        >
          {contidionValues.map((item, idx) => {
            return (
              <MenuItem key={idx} value={item.value}>
                {t(item.name)}
              </MenuItem>
            );
          })}
        </TextField>
        <div className="col-12 mt-2">
          <textarea
            cols="30"
            rows="5"
            maxLength="200"
            className="col-12 mt-1 input-condition-detail"
            onChange={(e) => {
              setResponseMessage("");
              setConditionNotes(e.target.value);
            }}
            placeholder={t("Enter details here")}
            value={conditionNotes}
          ></textarea>
        </div>
        <div>
          {loading && <Loading color="#28527a" />}
          {responseMessage && (
            <h5
              className={`textResponseOnSave ${responseMessage && `fadeout`}`}
            >
              {responseMessage}
            </h5>
          )}
        </div>
        <div className="d-flex justify-content-around">
          <div className="m-1">
            <Button
              variant="contained"
              color="secondary"
              startIcon={<CheckCircleOutlineIcon />}
              onClick={handleSaveHealthCondition}
            >
              {t("Save")}
            </Button>
          </div>
          <div className="m-1">
            <Button
              variant="contained"
              color="secondary"
              startIcon={<CancelIcon />}
              onClick={() => setConditionNotes("")}
            >
              {t("Cancel")}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
