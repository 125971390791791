import React, { useEffect, useState } from "react";
import DashboardMUI from "../dashboard/DashboardMui";
import VideoFrame from "../videoconference/VideoFrame";
import { useLocation } from "react-router-dom";
import VideoConferenceIcon from "../../images/videoconference.svg";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import PrenscriptionDialog from "../prescription/PrenscriptionDialog";
import Axios from "axios";
import useUserState from "../hooks/useUserState";
import TabsContainer from "../prescription/TabsContainer";
import EHRaccordion from "./EHRaccordion";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function LobbyProvider(props) {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState(""); // this is the state for prescription
  const [responseMessage, setResponseMessage] = useState("");
  const [responseCNMessage, setResponseCNMessage] = useState("");

  const [enabledSave, setEnabledSave] = useState(true);
  // Consultation Notes
  const [consultationNotes, setConsultationNotes] = useState("");
  const [enabledSaveConsultation, setEnabledSaveConsultation] = useState(true);
  const [pharmacy, setPharmacy] = useState(null);
  const [pharmacyReferalsFlag, setPharmacyReferalsFlag] = useState(false);
  const [dispenseTo, setDispenseTo] = useState(null);
  const { event_code, providerFullname, password, appointmentId, patientId } =
    location.state;

  const userLocalState = useUserState();
  const { t } = useBDocTranslator();

  //Method for Save the prescription
  const handlePrescriptionSave = () => {
    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/prenscriptions/create`,
      {
        appointment_id: appointmentId,
        patient_directions: notes,
        medication_id: "1",
        dispense: "1",
        dispense_from: new Date(),
        dispense_to: dispenseTo,
        dispensing_status: "1",
        pharmacy_note: "1",
        created_by_userId: userLocalState[0].user.id,
        pharmacy_id:
          pharmacy !== null && pharmacy !== "select" ? pharmacy : null,
        pharmacyReferalsFlag: pharmacy !== null && pharmacy !== "select",
      },
      {
        headers: { Authorization: "Bearer " + userLocalState[0].jwt },
      }
    )
      .then((response) => {
        setResponseMessage(response?.data?.message);
        setNotes("");
        setEnabledSave(false);
      })
      .catch((error) => {
        console.log(error);
        setResponseMessage(error);
      });
  };

  const handleNotes = (e) => {
    setNotes(e.target.value);
  };

  /**
   * Consultation Notes Methods
   */

  //Method for Save the prescription
  const handleConsultationNotesSave = () => {
    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/consultations/create`,
      {
        appointment_id: appointmentId,
        note: consultationNotes,
        created_by_userId: userLocalState[0].user.id,
        is_draft: false,
      },
      {
        headers: { Authorization: "Bearer " + userLocalState[0].jwt },
      }
    )
      .then((response) => {
        setResponseCNMessage(response.data.message);
        setConsultationNotes("");
        setEnabledSaveConsultation(false);
      })
      .catch((error) => {
        console.log(error);
        setResponseMessage(error);
      });
  };

  const handleConsultationNotes = (e) => {
    setConsultationNotes(e.target.value);
  };

  return (
    <DashboardMUI
      sectionTitle={t("Online Consultation")}
      description={t(
        "Please Allow the use of Camera and Microphone on your Browser"
      )}
      icon={VideoConferenceIcon}
    >
      <div className="row">
        <div className="video-container col-md-8 col-sm-12">
          <VideoFrame
            eventCode={event_code}
            displayName={providerFullname}
            // password={password}
          />
        </div>
        <div className="col-md-4 col-sm-12">
          <EHRaccordion
            handleSave={handlePrescriptionSave}
            handleNotes={handleNotes}
            responseMessage={responseMessage}
            responseCNMessage={responseCNMessage}
            enabledSaveBtn={enabledSave}
            handleSaveConsultationNotes={handleConsultationNotesSave}
            handleConsultationNotes={handleConsultationNotes}
            enabledSaveConsultationBtn={enabledSaveConsultation}
            prescriptionValue={notes}
            consultationNotesValue={consultationNotes}
            patientId={patientId}
            setPharmacy={setPharmacy}
            pharmacy={pharmacy}
            setDispenseTo={setDispenseTo}
            dispenseTo={dispenseTo}
          />
        </div>
      </div>
    </DashboardMUI>
  );
}
