import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { MenuItem, TextField } from "@material-ui/core";
import useUserState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function VaccinesDialog(props) {
  const userLocalState = useUserState();
  const [name, setName] = useState("");
  const [dose, setDose] = useState("");
  const [notes, setNotes] = useState("");
  const [batch, setBatch] = useState("");
  const [applicationDate, setApplicationDate] = useState("");
  const { t } = useBDocTranslator();

  const data = {
    name,
    application_date: applicationDate,
    created_at: new Date(),
    dose,
    batch,
    notes,
    patient_id: props.patientId,
  };

  // clean values for next use
  const handleCleanValues = () => {
    setName("");
    setDose("");
    setNotes("");
    setBatch("");
    setApplicationDate("");
  };

  useEffect(() => {
    if (props.successFlag) {
      handleCleanValues();
    }
  }, [props.successFlag]);

  return (
    <div className="shadow m-2 bg-white rounded-lg col-md-12 col-sm-12 position-relative dependants-card">
      <div className="row">
        <div className="col-md-6 w-100 mt-2">
          <TextField
            id="name"
            label={t("Vaccine Name")}
            variant="outlined"
            className="w-100"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required={true}
          />
        </div>
        <div className="col-md-6 w-100 mt-2">
          <TextField
            id="dose"
            label={t("Dose (number)")}
            variant="outlined"
            className="w-100"
            value={dose}
            onChange={(e) => setDose(e.target.value)}
            required={true}
            type="number"
          />
        </div>
        <div className="col-md-6 w-100 mt-2">
          <TextField
            id="batch"
            label={t("Batch / Lot")}
            variant="outlined"
            className="w-100"
            value={batch}
            onChange={(e) => setBatch(e.target.value)}
            required={true}
          />
        </div>
        <div className="col-md-6 w-100 mt-2">
          <TextField
            id="aapplicationDate"
            label={t("Application Date")}
            variant="outlined"
            className="w-100"
            value={applicationDate}
            onChange={(e) => setApplicationDate(e.target.value)}
            required={true}
            type="date"
          />
        </div>
        <div className="col-md-12 w-100 mt-2">
          <TextField
            id="notes"
            label={t("Notes")}
            variant="outlined"
            className="w-100"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            required={true}
          />
        </div>
      </div>

      <div className="row mt-2 mx-1">
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.handleVaccinesCreate(data)}
          className="col-md-6"
          // disabled={!btnStatus}
        >
          {t("Save")}
        </Button>
        <Button
          variant="contained"
          color="default"
          onClick={props.handleClose}
          className="col-md-6"
          // disabled={!btnStatus}
        >
          {t("Cancel")}
        </Button>
      </div>
    </div>
  );
}
