import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIS);

export default function CheckoutStripeWrapper({clientSecret}) {
    const options = {
        // passing the client secret obtained from the server
        clientSecret,
    };

    return (

        clientSecret ?
        <Elements stripe={stripePromise} options={options}>
            <CheckoutForm/>
        </Elements> :
            <StripeModuleError />
    );
};


function StripeModuleError (){
    return (
        <>
            <section className="container-fluid mb-2">
                <h4 className="text-center">Card payment out of service</h4>
                <p className="text-center">Please try again later</p>
            </section>
        </>
    )
}