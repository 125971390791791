import { useSelector } from "react-redux";

export default function useUserState() {
  //Getting the data from session storage
  const sessionStore = JSON.parse(sessionStorage.getItem("userState"));

  //Extract the userState to Redux Glabal State
  const userState = useSelector((state) => {
    if (state.currentUser.user) {
      return state.currentUser;
    } else if (sessionStore) {
      return sessionStore;
    }
  });


  return [userState];
}
