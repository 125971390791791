import React from "react";

export default function HeadingResume(props) {
  return (
    <>
      <article className="mt-3 mb-3 p-3 shadow w-30 rounded-sm-custom bg-white">
        <h1 className="font-weight-bold">
          {props.title + " "}
          <span>
            <img className="sunshine-icon" src={props.icon} alt="svg-icon" />
          </span>
        </h1>
        <p>{props.description}</p>
      </article>
    </>
  );
}
