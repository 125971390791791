/**
 *  Component For Patients Login
 */
import React, { useState, useContext } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import "../../styles/text.css";
import LoadingSpin from "../utils/Loading";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Copyright from "../utils/Copyright";
import LogoDefault from "../../images/patient-logo-login.svg";
import Hospital from "../../images/hospital.png";
import swal from "sweetalert";
import "../../styles/LoginPatients.css";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
/** Redux resources */
import { setUserAction } from "../../actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TranslateIcon from "@material-ui/icons/Translate";
import TranslationButton from "../utils/TranslationButton";
// import HCGLogo from "../../images/logo-doc-nic.jpg";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  langButton: {
    margin: theme.spacing(1),
    color: "black",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "transparent", //theme.palette.secondary.main,
    width: "200px",
    height: "150px",
    borderRadius: 0,
    marginBottom: "30px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Login() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    visible: false,
  });
  const [loading, setLoading] = useState(false);
  const queryType = "2";

  let history = useHistory();

  //Distpach of Redux
  const distpach = useDispatch();

  // To manage translations
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("en");

  const Logo = process.env.REACT_APP_TENANT_LOGO;

  const handleClick = (e) => {
    e.preventDefault();
    if (email && password) {
      setLoading(true);
      Axios.post(`${process.env.REACT_APP_API_URL_BASE}/session`, {
        email,
        password,
        queryType,
      })
        .then((response) => {
          if (response.data) {
            let data = response.data;
            distpach(setUserAction(data));
            history.push("/patient/dashboard");
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          setLoading(false);
          swal(t("alert"), t("login.error"), "warning");
          setErrorMessage({ error: error, visible: true });
        });
      console.log(errorMessage);
    } else {
      setErrorMessage({ error: t("login.error"), visible: true });
      swal(t("alert"), t("login.error"), "warning");
    }
  };

  const handleCancel = () => {
    history.push("/");
  };

  // Func to change the language on demand
  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label="delete"
        className={classes.margin}
        fontSize="large"
        onClick={handleCancel}
      >
        <ArrowBackIcon />
      </IconButton>

      <div className="row">
        <h5 className="m-auto w-50 text-center p-3">
          {t("login.patient.new.here") + "?"}
          <Link href="/patient/registration" variant="inherit">
            {t("login.patient.register")}
          </Link>
        </h5>
        <TranslationButton onChangeLanguaje={onChangeLanguaje} />
      </div>

      <div className="row d-flex justify-content-around p-3">
        <Paper
          elevation={3}
          variant="elevation"
          style={{ width: "fit-content", height: "auto" }}
        >
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar} src={Logo}></Avatar>
              <Typography component="h1" variant="h5">
                {t("login.patient.login.title")}
              </Typography>
              <Typography component="h6" variant="h6" className="mt-2">
                {errorMessage.visible && (
                  <p className="text-danger">
                    {t("login.patient.invalid.password")}
                  </p>
                )}
              </Typography>
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label={t("login.patient.rememberme")}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleClick}
                >
                  {t("login.signin")}
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="/patient/login/recovery" variant="body2">
                      {t("login.patient.forgot.password")}
                    </Link>
                  </Grid>
                </Grid>
                <div>
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <LoadingSpin type="spinningBubbles" color="#1d3557" />
                    </div>
                  )}
                </div>
              </form>
            </div>
            <Box mt={8}>
              <Copyright />
            </Box>
          </Container>
        </Paper>
        <div className="d-flex align-items-center">
          <img className="hospital" src={Hospital} alt="Hospital" />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
