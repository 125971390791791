import React, { useState } from "react";
import moment from "moment";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function TimeSlots(props) {
  const { t } = useBDocTranslator();

  const BoxHour = (props) => {
    return (
      <div
        className="box-hour text-center"
        onClick={props.handleSelection}
        data-slot={props.hour}
      >
        {props.hour}
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="shadow-sm p-3 mb-5 bg-white rounded d-flex flex-wrap">
        <div className="col-12">
          <p>{t("Please doble click the hour")}</p>
        </div>
        {props.data &&
          props.data.map((item, idx) => {
            /**Compare if the slot of times passing for not rendeing */
            const slot = parseInt(item.replaceAll(":", ""));
            const currentTime = parseInt(
              moment().format("HH:mm:ss").replaceAll(":", "")
            );
            if (props.isSameDay && slot >= currentTime) {
              return (
                <BoxHour
                  key={idx}
                  hour={item}
                  handleSelection={props.handleSelection}
                />
              );
            } else if (!props.isSameDay) {
              return (
                <BoxHour
                  key={idx}
                  hour={item}
                  handleSelection={props.handleSelection}
                />
              );
            }
          })}
      </div>
    </div>
  );
}
