import React, {useState} from 'react';
import axios from 'axios';
import useUserState from "../../../hooks/useUserState";
import swal from "sweetalert";

export const AffliationsLogic = () => {

    const [localState] = useUserState();
    const [affiliationList, setAffiliationList] = useState([])
    const [patientList, setPatientList] = useState([])
    const [planList, setPlanList] = useState([])
    const [selectedPatient, setSelectedPatient] = useState(null)
    const [selectedPlan, setSelectedPlan] = useState(null)

    const getAffiliationList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL_BASE}/plans/affiliations/list`, {
                headers: {
                    Authorization: `Bearer ${localState.jwt}`,
                },
            });
            setAffiliationList(response.data)
            return response.data;
        } catch (e) {
            console.log(e);
            swal("Error", "Error getting affiliations", "error");
        }
    }

    const updateAffiliation = async (data) =>{
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL_BASE}/affiliations/updateAffiliationToPlan?id=${data?.id}`, data,{
                headers: {
                    Authorization: `Bearer ${localState.jwt}`,
                },
            });
            swal("Success", response?.data?.message ,"success")
            return response.data;
        } catch (e) {
            console.log(e);
            swal("Error", "Error: " + e?.response?.data?.message , "error");
        }
    }

    const getPatientList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL_BASE}/patient/`, {
                headers: {
                    Authorization: `Bearer ${localState.jwt}`,
                },
            });
            // sort the response.data by first name asc
            response.data.sort((a, b) => (a?.firstName > b?.firstName) ? 1 : -1)
            setPatientList(response.data)
            return response.data;
        } catch (e) {
            console.log(e);
            swal("Error", "Error getting patients data", "error");
        }
    }

    const getPlanList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL_BASE}/plans/getPlansByAdmin`, {
                headers: {
                    Authorization: `Bearer ${localState.jwt}`,
                },
            });
            // exclude the plans that are not in status hidden
            const respFiltered = response.data.filter(plan => plan.status === "hidden")
            setPlanList(respFiltered)
            return respFiltered;
        } catch (e) {
            console.log(e);
            swal("Error", "Error getting plans data", "error");
        }
    }

    return {
        getAffiliationList,
        affiliationList,
        updateAffiliation,
        getPatientList,
        patientList,
        getPlanList,
        planList,
        selectedPatient,
        setSelectedPatient,
        selectedPlan,
        setSelectedPlan
    }
}