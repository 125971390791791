import React, {useState, useEffect, useMemo} from "react";
import {useHistory} from "react-router-dom";
import DashboardPatient from "../dashboard/DashboardPatient";
import HeadingResume from "../widgets/HeadingResume";
import mobilePaymentIcon from "../../images/mobile-payment.svg";
import Backdrop from "@material-ui/core/Backdrop";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckSuccess from "../../images/checked-success.svg";
import SadFace from "../../images/sad-face.svg";
import useBDocTranslator from "../hooks/useBDocTranslator";
import Loading from "../utils/Loading";
import Button from "@material-ui/core/Button";
import axios from "axios";
import UseUserState from "../hooks/useUserState";



const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export default function CheckoutResult(props) {
    let history = useHistory();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    const classes = useStyles();

    const {t, changeDirectLanguage} = useBDocTranslator();

    const userState = UseUserState();


    //this method manage the backdrop status
    const handleClose = () => {
        setOpen(false);
    };

    const confirmPayment = async (status) => {

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL_BASE}/payments/confirm-payment`,
                {
                    clientSecret: clientSecret,
                    status: status === "succeeded" ? "completed" : "rejected",
                },
                {
                    headers: {Authorization: "Bearer " + userState[0].jwt},
                }
            )
            setOpen(false);
            console.log("confirmPaymentResponse", response)
        } catch (e) {
            setOpen(false);
            console.log("confirmPaymentResponse Error", e)
        }
    }

    useEffect(() => {
        changeDirectLanguage()
        setOpen(true);

        const redirectStatus = new URLSearchParams(window.location.search).get(
            "redirect_status"
        );
        if (!redirectStatus) {
            return;
        }
        switch (redirectStatus) {
            case "succeeded":
                setMessage("Payment succeeded!");
                break;
            case "processing":
                setMessage("Your payment is processing.");
                break;
            case "requires_payment_method":
                setMessage("Your payment was not successful, please try again.");
                break;
            default:
                setMessage("Something went wrong.");
                break;
        }

        confirmPayment(redirectStatus).then(r => console.log("confirmPayment", r))


    }, []);


    return (
        <DashboardPatient>
            {/* Element that block the screen when request its happen */}
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <HeadingResume
                title={t("Checkout & Payment")}
                description={t(
                    "Once you make the payment we'll notify to Healthcare Provider and we'll send you invoice copy"
                )}
                icon={mobilePaymentIcon}
            />
            <div>
                <div className="row m-3 p-3 shadow w-30 rounded-lg-custom bg-white">
                    {/* Create a container to display a checkmark icon to show to the user that the payment was successful  */}
                    {message === "Payment succeeded!" &&
                        (<div className="col-12 text-center">
                            <h3 className="text-center">{t("Payment successful")}</h3>
                            <p className="text-center">{t("Your payment was successful")}</p>
                            <p className="text-center">{t("We'll notify to Healthcare Provider and we'll send you invoice copy")}</p>
                            <p className="text-center">{t("Thank you for using Our Services")}</p>
                            {/*    green checkmark svg icon */}
                            <img src={CheckSuccess} alt="checkmark icon" className="w-15-custom"/>
                        </div>)
                    }

                    {/*    Payment declined  */}
                    {
                        message === "Your payment was not successful, please try again." &&
                        (<div className="col-12 text-center">
                            <h3 className="text-center">{t("Payment declined")}</h3>
                            <p className="text-center">{t("Your payment was not successful, please try again")}</p>
                            <img src={SadFace} alt="sad icon" className="w-15-custom"/>
                        </div>)
                    }
                    {/*    Go to Dashboard button */}
                    <div className="col-12 text-center mt-3">
                        <Button variant="contained" color="primary" onClick={() => history.push("/patient/dashboard")}>
                            {t("Go to Dashboard")}
                        </Button>
                    </div>
                </div>
            </div>
        </DashboardPatient>
    );
}
