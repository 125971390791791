import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import ProvidersTable from "../utils/TableUIProviders";
import LoadingSpin from "../utils/Loading";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TableProviders from "../utils/TableProviders";
import providerIcon from "../../images/doctor.svg";
import useUserState from "../hooks/useUserState";
import DashboardAdmin from "../administrator/DashboardAdmin";
import { Button } from "@material-ui/core";

function ProvidersList(props) {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const userState = useUserState();

  //Action to send to Button
  const handleAddButton = () => {
    history.push("/users/providerRegister");
  };

  //Function for Edit the selected Provider
  const handleClickEdit = (row) => {
    history.push("/users/provider/edit/" + row.id);
  };

  useEffect(() => {
    setLoading(true);
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/provider`, {
      headers: {
        Authorization: "Bearer " + userState[0].jwt, //the jwt is a variable which holds the token
      },
    })
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const columns = [
    "#",
    "First name",
    "Last name",
    "E-mail",
    "Register date",
    "Request Status",
    "Actions",
  ];

  return (
    <DashboardAdmin
      sectionTitle="Healthcare Providers"
      description="List of Healthcare providers registered, you can Manage Providers data and Application Status"
      icon={providerIcon}
    >
      <div className="d-flex flex-column col-md-12">
        <div className="d-flex justify-content-end col-md-12 m-3 ">
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddButton}
          >
            Add Provider
          </Button>
        </div>
        {data ? (
          // <ProvidersTable
          //   clickEvent={handleClickEdit}
          //   data={data}
          //   columns={columns}
          // ></ProvidersTable>
          <TableProviders clickEvent={handleClickEdit} data={data} />
        ) : (
          <LoadingSpin type="spokes" color="#023E8A" />
        )}
      </div>
    </DashboardAdmin>
  );
}

export default ProvidersList;
