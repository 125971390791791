// PatientsRegistrationLogic.js

import  { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {useCreateUserPatient} from "../../services/services";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux"; // Make sure to provide the correct path
import {setRegistrationData} from "../../actions";
import useBDocTranslator from "../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
    container:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
    },
    paper:{
        marginTop:theme.spacing(8),
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        padding:"30px",
        backgroundColor:"white",
        maxWidth:600,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[500],
        borderRadius: 0
    },
    termsUse:{
        marginLeft:"10px",
        cursor:"pointer",
    },
    cursor:{
        cursor:"pointer",
    },
    avatar:{
        margin:theme.spacing(1),
        backgroundColor:"transparent",
        borderRadius:0,
        width:"60px",
        height:"60px",
        marginBottom:"10px",
    },
    form:{
        width:"100%", // Fix IE 11 issue.
        marginTop:theme.spacing(3),
    },
    submit:{
        margin:theme.spacing(3,0,2),
    },
    formControl:{
        //margin: theme.spacing(1),
        minWidth:120,
        width:160,
    },
    selectEmpty:{
        marginTop:theme.spacing(2),
    },
    btnContainer:{
        display:"flex",
        alignContent:"flex-end",
        width:"100%",
    },
    textFields:{
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[500],
        borderRadius: 0,
    }
}));
const PatientsRegistrationLogic = () => {
    const classes = useStyles();
    const {createUserPatient} = useCreateUserPatient();

    const [email, setEmail] = useState("");
    const [phone1, setPhone1] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastname, setLastname] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [gender, setGender] = useState("");
    const [plan, setPlan] = useState("");
    const status = "ENABLED";
    const person_type = "PAT";
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState({
        error: "",
        visible: false,
        msgClass: "",
    });
    const [password, setPassword] = useState("");
    const [jwt, setJwt] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [language, setLanguage] = useState("en");
    let history = useHistory();
    const dispatch = useDispatch();
    const { t, changeDirectLanguage, onChangeLanguaje } = useBDocTranslator();


    const clearForm = () => {
        setDateOfBirth("");
        setEmail("");
        setGender("");
        setLastname("");
        setFirstName("");
        setPhone1("");
        document.getElementById("patient-form").reset();
    };

    function pushAfterRegister(){
        setTimeout(() => {
        history.push("/patient/login");
         } , 3000);
    }

    const userData = {
        email,
        phone1,
        firstName,
        lastname,
        dateOfBirth,
        gender,
        password,
        status,
        personType: person_type,
    }

    const handleClick = async (e) => {
        e.preventDefault();

        if (!checkPassword(password)) {
            setErrorMessage({
                error: t("password is weak"),
                visible: true,
                msgClass: "alert alert-danger",
            });
            return;
        }

        if (email && firstName && lastname && dateOfBirth && gender && acceptTerms) {
            try {
                setLoading(true);

                const response = await createUserPatient(userData);
                setLoading(false);
                // setJwt(response.jwt);
                setErrorMessage({
                    error: t("message.success.on.registration"),
                    visible: true,
                    msgClass: "alert alert-success",
                });
                clearForm();
                console.log("RESPONSE", response)
                dispatch(setRegistrationData(response));
                // window.sessionStorage.setItem("registerData", JSON.stringify(response));
                if(plan !== "Continuar con pagos por consulta"){
                    history.push("/patient/registration/healthPlans", {patientId: response?.patientId});
                    return
                }
                pushAfterRegister()
            } catch (error) {
                setLoading(false);
                console.log(error);
                setErrorMessage({
                    error: error,
                    visible: true,
                    msgClass: "alert alert-danger",
                });
            }
        } else {
            setErrorMessage({
                error: t("please.complete.the.fields"),
                visible: true,
                msgClass: "alert alert-danger",
            });
        }
    };


    const checkPassword = (password) => {
        if (password.length < 8) {
            return false;
        }
        if (password.search(/[a-z]/i) < 0) {
            return false;
        }
        return password.search(/[0-9]/) >= 0;
    };



    return {
        email,
        setEmail,
        phone1,
        setPhone1,
        firstName,
        setFirstName,
        lastname,
        setLastname,
        dateOfBirth,
        setDateOfBirth,
        gender,
        setGender,
        status,
        person_type,
        loading,
        errorMessage,
        password,
        setPassword,
        jwt,
        acceptTerms,
        setAcceptTerms,
        t,
        language,
        clearForm,
        handleClick,
        checkPassword,
        useStyles,
        classes,
        setPlan,
        plan,
        onChangeLanguaje
    };
};

export default PatientsRegistrationLogic;
