import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import consultationIcon from "../../images/consultation-icon.svg";
import useUserState from "../hooks/useUserState";
import Axios from "axios";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import useBDocTranslator from "../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "whitesmoke",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "900px",
  },
}));

export default function HealthConditionModalForm(props) {
  const classes = useStyles();
  const [conditionNotes, setConditionNotes] = useState(""); // this is the state for prescription
  const [responseMessage, setResponseMessage] = useState("");
  const userLocalState = useUserState();
  const { t } = useBDocTranslator();
  const [newConditionNotes, setNewConditionNotes] = useState("");

  const handleConditionNotes = (e) => {
    setConditionNotes(e.target.value);
  };

  //Method for Save the prescription
  const handleSaveHealthCondition = () => {
    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/healthconditions/create`,
      {
        segment_name: props.conditionType,
        detail: conditionNotes,
        patient_id: props.patientId,
        provider_id: userLocalState[0].user.id,
      },
      {
        headers: { Authorization: "Bearer " + userLocalState[0].jwt },
      }
    )
      .then((response) => {
        setResponseMessage(
          `Record for ${props?.conditionType} saved successfuly`
        );
        setConditionNotes("");
        props.handleRefreshData();
      })
      .catch((error) => {
        console.log(error);
        setResponseMessage(error);
      });
  };

  const handleUpdateHealthCondition = async () => {
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/healthconditions/update/${props.updatableData?.id}`,
        {
          detail: newConditionNotes,
        },
        {
          headers: { Authorization: "Bearer " + userLocalState[0].jwt },
        }
      );
      console.log(response);
      setResponseMessage(response.data?.message);
    } catch (error) {
      console.log(error);
      setResponseMessage(error.response.data?.message);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={() => {
          setNewConditionNotes("");
          props.handleClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          {
            <div className={classes.paper}>
              <div className="row col-md-12">
                <div className="col-md-4">
                  <img
                    src={consultationIcon}
                    alt="rx icon"
                    className="w-30-custom"
                  />
                </div>
                <div className="col-md-8">
                  <h3>
                    <b>{t("New")}</b> - {props.modalTitle}
                  </h3>
                </div>
              </div>
              {/* Form */}
              <div className="row col-md-12">
                {/* <div className="form-group justify-content-center p-3"> */}
                <div className="shadow p-3 m-2 bg-white rounded-lg col-md-12 col-sm-12 position-relative prescription-card">
                  {!props.edition && (
                    <textarea
                      name="condition-notes-txt"
                      id="condition-notes-txt"
                      rows="10"
                      placeholder={t("Enter the Health Information")}
                      className="mb-3 w-100 h-60-custom form-control col-md-12"
                      onChange={handleConditionNotes}
                      value={conditionNotes}
                    ></textarea>
                  )}
                  {/* For update when props.edition is true and get the value from prop updatableData */}
                  {props.edition && (
                    <textarea
                      name="condition-notes-txt"
                      id="condition-notes-txt"
                      rows="10"
                      placeholder={props.updatableData.detail}
                      className="mb-3 w-100 h-60-custom form-control col-md-12"
                      onChange={(e) => setNewConditionNotes(e.target.value)}
                      value={newConditionNotes}
                    ></textarea>
                  )}
                  {props.edition && (
                    <div className="d-flex justify-content-around">
                      <div className="col-md-5">
                        <p className="font-weight-bold">{t("Created at")}:</p>
                        <p className="font-weight-bold">{t("Updated at")}:</p>
                      </div>
                      <div className="col-md-5">
                        <p>{props.updatableData.created_at}</p>
                        <p>{props.updatableData.updated_at}</p>
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-around">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveHealthCondition}
                      className="col-md-5"
                      disabled={props.edition}
                    >
                      {t("Save")}
                    </Button>
                    {/* Update Condition */}
                    {props.edition && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleUpdateHealthCondition}
                        className="col-md-5"
                        // disabled={!btnStatus}
                      >
                        {t("Update")}
                      </Button>
                    )}
                  </div>
                  <div className="col-md-6 mt-2">
                    <p className="font-weight-bold">{responseMessage}</p>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          }
        </Fade>
      </Modal>
    </div>
  );
}
