import React, { useState, useEffect } from "react";
import LogoDefault from "../../images/facility-logo.svg";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Copyright from "../utils/Copyright";
import LoadingSpin from "../utils/Loading";
import Button from "@material-ui/core/Button";
import swal from "sweetalert";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import useBDocTranslator from "../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    backgroundColor: "white",
    boxShadow: "5px 5px 16px 1px #1b3342",
    borderRadius: "10px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "transparent",
    width: "80px",
    height: "80px",
    borderRadius: 0,
    marginBottom: "30px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
}));

function ForgotPassword() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const { t, changeDirectLanguage } = useBDocTranslator();

  const handleAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleAxiosPostEmail = (e) => {
    Axios.post(`${process.env.REACT_APP_API_URL_BASE}/users/recoverPassword`, {
      email,
    })
      .then((res) => {
        setLoading(false);
        swal(
          t(
            "Please check your email, we sent you a link to recover your password"
          ),
          t("Link has been sent"),
          "success"
        );
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: t("Failed"),
          text: t("Email not found"),
          icon: "error",
        });
        console.log(err);
      });
  };

  const handleSendEmail = (e) => {
    if (email !== "") {
      swal({
        title: email,
        text: "ALERT, It's correct your email Address?, please click on Send button",
        icon: "info",
        button: "Send",
      }).then((result) => {
        if (result) {
          setLoading(true);
          handleAxiosPostEmail();
        }
      });
    } else {
      handleAlert();
    }
  };

  useEffect(() => {
    changeDirectLanguage("es");
  }, []);

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar} src={LogoDefault}></Avatar>
          <Typography component="h1" variant="h5">
            {t("Please write your email")}
          </Typography>
          <Typography component="h6" variant="h6" className="mt-2"></Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("Your Email")}
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSendEmail}
            >
              {t("Recover password")}
            </Button>
          </form>
          <Box mt={8}>
            <Copyright />
          </Box>
        </div>
      </Container>
      <div className="m-3">
        {loading && (
          <div className="d-flex justify-content-center">
            <LoadingSpin type="spinningBubbles" color="#1d3557" />
          </div>
        )}
      </div>
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity="error">
          {t("Please provide a valid email")}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default ForgotPassword;
