import React, { useEffect, useState } from "react";
import DashboardPatient from "../dashboard/DashboardPatient";
import { useParams, useLocation } from "react-router-dom";
import "../../styles/customs.css";
import NICFlag from "../../images/nicaragua-flag.png";
import CRCFlag from "../../images/cr-flg.png";
import BahFlag from "../../images/bahamas-flag.png";
import USAFlag from "../../images/usa-flag.png";
import JAMFlag from "../../images/jamaica-flag.png";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import UseUserState from "../hooks/useUserState";
import LoadingSpin from "../utils/Loading";
import { makeStyles } from "@material-ui/core/styles";
import ProfileLogo from "../../images/profile-2.svg";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import { resizeByLink } from "./../utils/ResizeLinkCloudinary";
import useBDocTranslator from "../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down("sm")]: {
      width: "800px",
    },
  },
}));

export default function ProviderDetails() {
  const classes = useStyles();
  const location = useLocation();
  const profileImg = location.state;
  const { id } = useParams();
  const storeState = UseUserState();
  const [loading, setLoading] = useState(true);
  const [age, setAge] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [provider, setProvider] = useState({});
  const [specialty, setSpecialty] = useState([]);
  const [issuedBy, setIssuedBy] = useState([]);
  const [providerUserId, setProviderUserId] = useState("");
  let history = useHistory();

  const { t, changeDirectLanguage } = useBDocTranslator();

  const providerD = {
    studies: {
      carrera: "doctor",
      colegio: "UCV",
      fechaInicio: "september 2018",
      fechaFinal: "september 2019",
    },
    experience: [
      {
        job: "portero",
        institute: "sanatrix",
        startDate: "agust 2018",
        endDate: "december 2019",
        expSummary: "work cleanning bathrooms",
      },
      {
        job: "Anaesthesiology",
        institute: "sanatrix",
        startDate: "january 2020",
        endDate: "today",
        expSummary: "work in the department of Anaesthesiology",
      },
    ],
    lenguajes: [
      {
        lenguaje: "ingles",
        level: "high",
      },
      {
        lenguaje: "spanish",
        level: "low",
      },
    ],
  };

  const findProvider = (e) => {
    if (storeState[0].jwt) {
      Axios.get(`${process.env.REACT_APP_API_URL_BASE}/provider/review/${id}`, {
        headers: {
          Authorization: "Bearer " + storeState[0].jwt,
        },
      })
        .then((res) => {
          getBithString(res.data.dateOfBirth);
          setProvider(res.data);
          setLoading(false);
          getAge(res.dateOfBirth);
          handleSpecialties(res.data.medical_speciality);
          handleIssuedBy(res.data.issued_by);
          setProviderUserId(res.data.user_id);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    findProvider();
    changeDirectLanguage();
  }, []);

  const mathCountryFlag = (country) => {
    switch (country) {
      case "CRC":
        return (
          <img className="flag-size rounded" src={CRCFlag} alt="flagImg" />
        );
      case "USA":
        return (
          <img className="flag-size rounded" src={USAFlag} alt="flagImg" />
        );
      case "NIC":
        return (
          <img className="flag-size rounded" src={NICFlag} alt="flagImg" />
        );
      case "TBH":
        return (
          <img className="flag-size rounded" src={BahFlag} alt="flagImg" />
        );
      case "JAM":
        return (
          <img className="flag-size rounded" src={JAMFlag} alt="flagImg" />
        );
      default:
        return (
          <img className="flag-size rounded" src={CRCFlag} alt="flagImg" />
        );
    }
  };

  function getAge(dateString) {
    if (dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      setAge(age);
    }
  }
  const getBithString = (date) => {
    if (date) {
      let dates = date.split("T");
      setBirthDate(dates[0]);
    }
  };
  const handleSpecialties = (specialties) => {
    if (specialties) {
      let specialty = Array.of(specialties.speciality_name);
      setSpecialty(specialty);
    }
  };
  const handleIssuedBy = (issuedBy) => {
    if (issuedBy) {
      let issued = Array.of(issuedBy);
      setIssuedBy(issued);
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleClick = () => {
    history.push({
      pathname: "/patient/appointments/create",
      state: { providerId: provider.id },
    });
  };

  return (
    <DashboardPatient>
      {loading && (
        <div className="m-3">
          <LoadingSpin type="spokes" color="#1d3557" />
        </div>
      )}
      {provider.id && (
        <div
          className={
            "container-fluid m-3 p-3 shadow rounded-lg-custom bg-white " +
            classes.paper
          }
        >
          {/* GoBack Button */}
          <div>
            <IconButton
              aria-label="delete"
              className={classes.margin}
              fontSize="large"
              onClick={handleCancel}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className="row container-fluid">
            {profileImg ? (
              <div className="col-12 col-lg-2 d-flex justify-content-center">
                <div className="img-d-cropper">
                  <img
                    className="profile-avatar"
                    src={resizeByLink(profileImg, "upload")}
                    alt="Profile-logo"
                  />
                </div>
              </div>
            ) : (
              <div className="col-12 col-lg-2 d-flex justify-content-center">
                <div className="img-d-cropper">
                  <img
                    className="profile-avatar"
                    src={ProfileLogo}
                    alt="Profile-logo"
                  />
                </div>
              </div>
            )}
            <div className="col-10 col-lg-8 mt-3 mt-lg-0 d-flex justify-content-start align-items-center">
              <Typography
                className="pt-2 d-none d-sm-flex mr-2 font-weight-bold"
                variant="h4"
                gutterBottom
              >
                {provider.title && provider.title + " "}
              </Typography>
              <Typography
                className="pt-2 font-weight-bold"
                variant="h4"
                gutterBottom
              >
                {provider.firstName + " " + provider.lastname}
              </Typography>
            </div>
            {provider.country && (
              <div className="col-2 mt-3 mt-lg-0 d-flex pl-0 pb-2 justify-content-start align-items-center">
                <span className="w-30-custom">
                  {mathCountryFlag(provider.country)}
                </span>
              </div>
            )}
          </div>
          {/* Appointment Button */}
          <div className="mt-3 ml-4">
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="w-auto m-1"
              startIcon={<EventIcon />}
              //className={classes.submit}
              onClick={handleClick}
            >
              {t("New Appointment")}
            </Button>
          </div>
          <div className="row container-fluid p-3">
            <div className="col-12 pl-0">
              <Typography
                className="title-custome font-weight-bold pt-3 mt-4"
                variant="h5"
                gutterBottom
              >
                {t("Professional information")}
              </Typography>
            </div>
            {provider.medical_license && (
              <div className="col-12 mt-3">
                <Typography variant="h6" gutterBottom>
                  {t("Medical Licence")}
                  {provider.medical_license}
                </Typography>
              </div>
            )}
            {provider.summary && (
              <div className="col-12 mt-3 ml-3 issued">
                <div className="row p-3">
                  <div className="col-12 pl-0">
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      {t("Summary")}
                    </Typography>
                  </div>
                  <div className="col-12 mt-2 pl-0">
                    <Typography variant="subtitle1" gutterBottom>
                      {provider.summary}
                    </Typography>
                  </div>
                </div>
              </div>
            )}
            {specialty[0] && (
              <div className="col-12 mt-3 ml-3 issued">
                <div className="row p-3">
                  <div className="col-12 pl-0">
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      {t("Specialties")}
                    </Typography>
                  </div>
                  <div className="col-12 mt-2 pl-0">
                    {specialty.map((item, idx) =>
                      item === "" ? (
                        ""
                      ) : (
                        <Chip
                          key={idx}
                          label={item || ""}
                          clickable={false}
                          className="m-2"
                          color="primary"
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            )}
            {issuedBy.length > 0 && (
              <div className="col-12 mt-3 ml-3 issued">
                <div className="row p-3">
                  <div className="col-12 pl-0">
                    <Typography variant="h6" gutterBottom>
                      {t("Issued By")}
                    </Typography>
                  </div>
                  <div className="col-12 mt-2 pl-0">
                    {issuedBy.map((item, idx) => (
                      <Chip
                        key={idx}
                        label={item || ""}
                        clickable={false}
                        className="m-2"
                        color="primary"
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
            {!providerD.experience[0] && (
              <div className="col-12 mt-3 ml-3 issued">
                <div className="row p-3">
                  <div className="col-12 pl-0">
                    <Typography variant="h6" gutterBottom>
                      {t("Experience")}
                    </Typography>
                  </div>
                  {providerD.experience.map((item, idx) => (
                    <div
                      key={idx}
                      className="col-11 d-flex justify-content-between px-3 py-2 my-2 mx-auto pl-0 issued"
                    >
                      <div className="flex-column">
                        <Typography variant="h6" gutterBottom>
                          {"• " + item.job}
                        </Typography>
                        <Typography
                          className="ml-1"
                          variant="subtitle1"
                          gutterBottom
                        >
                          {item.institute}
                        </Typography>
                        <Typography
                          className="ml-1"
                          variant="subtitle1"
                          gutterBottom
                        >
                          {item.expSummary}
                        </Typography>
                      </div>
                      <div className="d-flex align-items-center">
                        <Typography variant="subtitle1" gutterBottom>
                          {item.startDate + " - " + item.endDate}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!providerD.lenguajes[0] && (
              <div className="col-12 mt-3 ml-3 issued">
                <div className="row p-3">
                  <div className="col-12 pl-0">
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      {t("Languages")}
                    </Typography>
                  </div>
                  {providerD.lenguajes.map((item, idx) => (
                    <div
                      key={idx}
                      className="col-11 d-flex justify-content-between px-3 py-2 my-2 mx-auto pl-0 issued"
                    >
                      <div className="flex-column">
                        <Typography variant="h6" gutterBottom>
                          {item.lenguaje}
                        </Typography>
                      </div>
                      <div className="d-flex align-items-center">
                        <Typography variant="subtitle1" gutterBottom>
                          {item.level}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </DashboardPatient>
  );
}
