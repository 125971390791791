import React, { useState, useEffect } from "react";
import DashboardAdmin from "../../DashboardAdmin";
import useReport from "../../../hooks/useReport";
import { saveAs } from "file-saver";
import { Parser } from "json2csv";
import swal from "sweetalert";
import { Button } from "@material-ui/core";

export default function ApptReport() {
  const [reportData, setReportData] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { getReport, loading } = useReport();

  const handleGenerateReport = async () => {
    const res = await getReport(startDate, endDate);
    console.log("reportData", res);
    if (!res || res.data.length === 0)
      return swal("Error", "No data for given dates", "error");

    setReportData(res);
  };

  const downloadFile = () => {
    const json2csvParser = new Parser();
    const csv = json2csvParser.parse(reportData?.data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "reporte.csv");
    cleanState();
  };

  // clean state when report is downloaded
  const cleanState = () => {
    setReportData(null);
    setStartDate("");
    setEndDate("");
  };

  return (
    <DashboardAdmin>
      {/* Table with mock data for the appointment report , with a title a datepicket selection for start and enddate using material ui v4 */}
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="card-title">Appointments Report</h4>
                </div>
              </div>
              {/* Content */}
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="start-date">Start Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="start-date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="end-date">End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="end-date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <button
                      className="btn btn-primary"
                      onClick={handleGenerateReport}
                      disabled={!startDate || !endDate}
                    >
                      {loading ? "Loading..." : "Generate Report"}
                    </button>
                  </div>
                </div>
              </div>
              {/* End Content */}
            </div>

            <div className="m-4">
              {reportData && (
                <Button variant="outlined" onClick={downloadFile}>
                  Download Report
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardAdmin>
  );
}
