import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import "../../styles/consultText.css";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function SearchModule(props) {
  //const [value, setValue] = useState([]);
  const [country, setCountry] = useState(null);
  const [specialty, setSpecialty] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { t } = useBDocTranslator();

  const clearAll = () => {
    setRefresh(true);
    setCountry(null);
    setSpecialty(null);
    props.setProvider("");
  };

  useEffect(() => {
    if (props.preselectedProvider.name !== "") {
      props.setProvider(props.preselectedProvider);
    }
    if (refresh || props.cancel) {
      setRefresh(false);
      props.setCancel(false);
    } else {
      if (country || specialty || Array.isArray(specialty) || props.provider) {
        props.handleSearch(country, specialty, props.provider);
      } else {
        props.handleRefresh();
      }
    }
  }, [country, specialty, props.provider]);

  const useStyles = makeStyles((theme) => ({
    autocomplete: {
      width: "900px",
    },
  }));

  const classes = useStyles();

  return (
    <div className="p-3 mb-4 col-sm-12 ">
      <div className="flex-column">
        {/* Search by Country */}
        {/* <Autocomplete
        id="search-by-country"
        onChange={(e, newValue) => setCountry(newValue)}
        options={countries}
        getOptionLabel={(option) => option.name}
        style={{ width: 300 }}
        renderInput={(params) => (
            <TextField
            {...params}
            label="Filter by Country"
            variant="outlined"
            className="bg-white"
            />
        )}
        /> */}
        {/* Search By Specialty */}
        <div className={"d-flex justify-content-center "}>
          <Autocomplete
            // style={{ width: "450px" }}
            // PopperComponent={PopperMy}
            // fullWidth={false}
            className="m-4"
            id="search-by-specialty"
            onChange={(e, newValue) => setSpecialty(newValue)}
            options={props.specialties}
            getOptionLabel={(option) => t(option.speciality_name)}
            //getOptionDisabled={(option) => option === timeSlots[0] || option === timeSlots[2]}
            style={{ width: 300 }}
            // disabled
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Filter by Specialty")}
                variant="outlined"
                className="bg-white"
                // disabled
              />
            )}
          />
        </div>

        {/* Search by Providers name */}
        <div className={"d-flex justify-content-center "}>
          <Autocomplete
            className="m-4"
            id="search-by-name"
            onChange={(e, newValue) => {
              console.log("new value", newValue);
              props.setProvider(newValue);
            }}
            options={props.providers}
            getOptionLabel={(option) =>
              option.firstName + " " + option.lastname
            }
            style={{ width: 300 }}
            //value={props?.preselectedProvider}
            // disabled
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Filter by Provider's name")}
                variant="outlined"
                className="bg-white"
                value={props?.provider?.name}
              />
            )}
          />
        </div>
        {/* <IconButton 
        onClick={() => {clearAll();}} 
        variant="contained" 
        color="primary" 
        aria-label="refresh">
        <RefreshIcon />
        </IconButton> */}
      </div>
      {/* <div className="d-flex justify-content-end m-3">
        <Button
        onClick={() => props.handleSearch(country, specialty, provider)}
        variant="contained"
        color="primary"
        >
        Search
        </Button>
    </div> */}
    </div>
  );
}

const countries = [
  { name: "United States", code: "USA" },
  { name: "Costa Rica", code: "CRC" },
  { name: "Bahamas", code: "BHA" },
  { name: "Jamaica", code: "JAM" },
  { name: "Nicaragua", code: "NIC" },
];
