import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Fade,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 20,
  },
  input: {
    borderRadius: 20,
  },
}));

export default function AdminLoginForm(props) {
  const classes = useStyles();

  const handleLogin = (email, password) => {
    return true;
  };

  return (
    <div className="d-flex justify-content-center">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Email is required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Email format invalid";
          } else if (!values.password) {
            errors.password = "please use your password";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const result = props.handleLogin(values.email, values.password);
          if (result) setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form className="w-100" onSubmit={handleSubmit}>
            {/* Email Address */}
            <div className="form-group">
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <ErrorMessage
                name="email"
                component="p"
                className="ml-3 text-sm text-red-900"
              />
            </div>

            {/* Password */}
            <div className="form-group">
              <TextField
                className={classes.input}
                id="password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                autoComplete="current-password"
              />
              <ErrorMessage
                name="password"
                component="p"
                className="ml-3 text-sm text-red-900"
              />
            </div>

            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <div className="text-center">
              <Fade
                in={props.loading}
                style={{
                  transitionDelay: props.loading ? "800ms" : "0ms",
                }}
                unmountOnExit
              >
                <CircularProgress color="primary" size={40} thickness={4} />
              </Fade>
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSubmitting}
            >
              Log In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/admin/login/recovery" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <div>
              {/* {loading && (
                    <div className="d-flex justify-content-center">
                      <LoadingSpin type="spinningBubbles" color="#1d3557" />
                    </div>
                  )} */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
