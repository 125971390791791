import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {CssBaseline, Container} from "@material-ui/core";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(8),
        alignItems: "center",
        padding: "30px",
        backgroundColor: "white",
        boxShadow: "5px 5px 16px 1px #caced0",
        borderRadius: "10px",
    },

}));
export default function PlanProviders({providers, planProviders, setPlanProviders, planToEdit, setPlanToEdit }) {
    const classes = useStyles();
    const [selectedProvider, setSelectedProvider] = useState("");
    const [tempPlanProviders, setTempPlanProviders] = useState([]);
    const [planProvider, setPlanProvider] = useState({
        provider_id: '',
        plan_id: '',
        status: 'active',
    });

    const handleSetProvidersPlan = () => {
        if(selectedProvider === ""){
            swal("Error", "Please select a provider", "error");
            return;
        }

        // find the specialty_name from the specialties list with the selectedSpecialty id
        let name = providers.find(s => s.id === parseInt(selectedProvider)).firstName;
        let specialty_name = providers.find(s => s.id === parseInt(selectedProvider)).medical_speciality.speciality_name;
        // add the specialty_name to the tempPlanSpecialties array
        setTempPlanProviders([...tempPlanProviders, {...planProvider, name, specialty_name}]);
        // add the planSpecialty to the planSpecialties array
        setPlanProviders([...planProviders, planProvider]);
        // reset the selectedSpecialty to empty
        setSelectedProvider("");

    }

    // remove row from tempPlanProviders and planProviders arrays when remove button is clicked
    const handleRemoveProvider = (provider_id) => {
        // filter out the specialty_id from the tempPlanSpecialties array
        let newTempPlanProviders = tempPlanProviders.filter(s => s.provider_id !== provider_id);
        // filter out the specialty_id from the planSpecialties array
        let newPlanProviders = planProviders.filter(s => s.provider_id !== provider_id);
        // set the tempPlanSpecialties array to the newTempPlanSpecialties array
        setTempPlanProviders(newTempPlanProviders);
        // set the planSpecialties array to the newPlanSpecialties array
        setPlanProviders(newPlanProviders);
    }

    // remove row from planToEdit.providersPlan array when remove button is clicked
    const handleRemoveProviderFromEdit = (provider_id) => {
        // filter out the specialty_id from the planToEdit.providersPlan array
        let newPlanProviders = planToEdit.providersPlan.filter(s => s.provider.id !== provider_id);
        // set the planToEdit.providersPlan array to the newPlanSpecialties array
        setPlanToEdit({...planToEdit, providersPlan: newPlanProviders});
    }

    const handleSetEditProvidersPlan = () => {
        if(planToEdit?.id){
            const planProvidersEdit = planToEdit.providersPlan;

            const providerEdit = {
                id: planProvider.provider_id,
                firstName: providers.find(s => s.id === parseInt(selectedProvider)).firstName,
                lastname: providers.find(s => s.id === parseInt(selectedProvider)).lastname,
                medical_speciality: {
                    speciality_name: providers.find(s => s.id === parseInt(selectedProvider)).medical_speciality.speciality_name
                }
            }

            // add the planSpecialty to the planSpecialties array
            planProvidersEdit.push({assignation_status: "new", provider: providerEdit});
            // set the planToEdit.providersPlan array to the newPlanSpecialties array
            setPlanToEdit({...planToEdit, providersPlan: planProvidersEdit});
        }
    }


    return (
        <Container component="main">
            <CssBaseline/>
            <div className={classes.paper}>

                <div className="form-group">
                    <label htmlFor="provider">Provider List</label>
                    <select
                        className="form-control"
                        id="provider"
                        name="provider"
                        value={selectedProvider}
                        onChange={(e) => {
                            setSelectedProvider(e.target.value);
                            setPlanProvider({...planProvider, provider_id: e.target.value});
                        }}
                    >
                        <option value="">Select Provider</option>
                        {providers ? providers.map((provider) => (
                            <option key={provider.id} value={provider.id}>
                                {provider?.firstName +" "+provider?.lastname} - {provider?.medical_speciality?.speciality_name}
                            </option>
                        )) : <option value="">No Providers</option>
                        }
                    </select>
                   <div className="mt-2">
                          <button className="btn btn-primary" onClick={()=> {
                              handleSetProvidersPlan()
                              handleSetEditProvidersPlan()
                          }}>Add Provider</button>
                       </div>
                    <div className="mt-3">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>Provider Name</th>
                                <th>Specialty</th>
                                <th>Remove</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tempPlanProviders.length > 0 && !planToEdit?.id ? tempPlanProviders.map((provider) => (
                                <tr key={provider.provider_id}>
                                    <td>{provider.name}</td>
                                    <td>{provider.specialty_name}</td>
                                    <td>
                                        <button onClick={()=> handleRemoveProvider(provider.provider_id)}  className="btn btn-danger">Remove</button>
                                    </td>
                                </tr>
                            )) :
                                <tr>
                                    {!planToEdit?.id && <td colSpan={3}>No Providers Added</td>}
                                </tr>
                            }
                            {
                                planToEdit.providersPlan?.length > 0 ? planToEdit.providersPlan.map(({provider}) => (
                                    <tr key={provider.id}>
                                        <td>{provider?.firstName+ " "+provider?.lastname}</td>
                                        <td>{provider?.medical_speciality?.speciality_name}</td>
                                        <td>
                                            <button onClick={()=> handleRemoveProviderFromEdit(provider.id)}  className="btn btn-danger">Remove</button>
                                        </td>
                                    </tr>
                                )) : null
                            }
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
        </Container>
    )
}
