import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import useUserState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function TimelineMessage(props) {
  const classes = useStyles();
  const userState = useUserState();
  const { t } = useBDocTranslator();

  const [replies, setReplies] = useState([]);

  useEffect(() => {
    if (props.messages.thread) {
      setReplies(props.messages.thread);
    }
  }, []);

  return (
    <Timeline align="alternate">
      {
        props.messages &&
          replies.length > 0 &&
          replies.map((item) => {
            const date = new Date(item?.createdAt).toLocaleString();
            const mainMessage = props.messages;
            return (
              <TimelineItem key={item.id}>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {date}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary">
                    <MarkunreadMailboxIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                      {item.send_by_userid === userState[0].user.id
                        ? t("You")
                        : mainMessage?.other_user?.extendedData?.firstName +
                          " " +
                          mainMessage?.other_user?.extendedData?.lastname}
                    </Typography>
                    <Typography>{item?.content}</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ); // inner return
          }) // fin map
      }
    </Timeline>
  ); // return ppal
} // fin component
