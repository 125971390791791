import React,{useEffect,useState} from 'react';
import PlanCard from './PlanCard';
import Typography from "@material-ui/core/Typography";
import {Grid,makeStyles} from "@material-ui/core";
import {useGetPlans,usePlanByAffiliationId} from "../../services/services";
import useUserState from "../hooks/useUserState";
import NoPlansCard from "../NoPlansCard";

const HealthPlans = () => {
    const [userData] = useUserState();
    const JWT = userData?.jwt;

    const [planAffiliated,setPlanAffiliated] = useState([])
    const {getPlanByAffiliationId,beneficiaryQty} = usePlanByAffiliationId(JWT)
    const {plansData,getPlans} = useGetPlans();
    const ifNoPlans = plansData?.length === 0 || plansData === null
    const { affiliations } = userData?.extendedData || {};
    const { affiliations_beneficiaries } = userData?.extendedData || {};

    const affiliateIdValue = affiliations?.[0]?.id || affiliations_beneficiaries?.[0]?.affiliation_id

    console.log("plansData",plansData);
    console.log("beneficiaryQty",beneficiaryQty);
    console.log("planAffiliated",planAffiliated);

    useEffect(() => {
        getPlans().then( r => console.log("data",r))
        if(affiliateIdValue){
            getPlanByAffiliationId(affiliateIdValue);
        }
    },[]);

    useEffect(() => {
        console.log("ifNoPlans", ifNoPlans)
        console.log("plandata", plansData)
        console.log("plansData && beneficiaryQty", plansData && beneficiaryQty)
        if(plansData && beneficiaryQty){
            compareAndAddKey(plansData,beneficiaryQty).then((data) => {
                setPlanAffiliated(data);
            });
        } else {
            setPlanAffiliated(plansData);
        }
    },[plansData && beneficiaryQty || plansData && !beneficiaryQty]);

    async function compareAndAddKey(data,data2){
        const data2Array = await Array.isArray(data2) ? data2 : [data2];

        const result = data?.map((item) => {
            const match = data2Array?.find((item2) => item2?.id === item?.id && item2?.status === 'active');
            console.log("match",match)

            if(match){
                return {
                    ...item,
                    planAffiliated:true,
                };
            }else{
                return {
                    ...item,
                    planAffiliated:false,
                };
            }
        });

        const resultFilter = result?.filter((item) => item.planAffiliated === true);
        if(resultFilter?.length > 0){
            return resultFilter;
        }else{
            return result;
        }
    }

    const useStyles = makeStyles((theme) => ({
        container:{
            background:theme.palette.grey[200],
            padding:theme.spacing(3),
            display:'flex',
            flexDirection:'column',
            height:'100%',
            width:'100%',
        },
        title:{
            marginBottom:theme.spacing(3),
        },
        divider:{
            height:'1px',
            background:'gray',
            marginBottom:theme.spacing(3),
        },
        content:{
            flexGrow:1,
        },
        cardContainer:{
            display:'flex',
            justifyContent:'center',
            height:'100%',
            minWidth:'350px',
        },
        buttonContainer:{
            marginTop:'auto'
        },
    }));

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography variant="h1" component="h1" className={classes.title}>
                Planes de Salud
            </Typography>
            <div className={classes.divider}></div>
            <Grid container spacing={3}>
                {
                    ifNoPlans &&
                        <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.cardContainer}>
                                <NoPlansCard/>
                            </div>
                        </Grid>
                }
                {planAffiliated?.map((plan,index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <div className={classes.cardContainer}>
                            <PlanCard {...plan} />
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default HealthPlans;
