// paymentService.js

import Axios from 'axios';
import swal from "sweetalert";

const createPaymentIntent = async (appointmentId, price, userState) => {
    try {
        const createIntentResponse = await Axios.post(
            `${process.env.REACT_APP_API_URL_BASE}/payments/create-payment-intent`,
            {
                items: [
                    {
                        id: appointmentId.toString(),
                        amount: price * 100, // for $14 => 1400
                    },
                ],
            },
            {
                headers: { Authorization: "Bearer " + userState[0].jwt },
            }
        );

        console.log("createIntentResponse", createIntentResponse);

        return createIntentResponse.data;
    } catch (e) {
        console.log("Create Intent Error", e);
        // Handle the error as needed, you can throw it or return an error object
        throw e;
    }
};

const handlePaymentSuccess = (response, appointmentId, userState, setOpen, setErrors, setRedirectAfertPay, t) => {
    if (response) {
        setOpen(true);

        Axios.post(
            `${process.env.REACT_APP_API_URL_BASE}/payments/paypal/process`,
            {
                paymentStatus: response.status,
                appointment_id: appointmentId,
                serviceResponse: response,
            },
            {
                headers: { Authorization: "Bearer " + userState[0].jwt },
            }
        )
            .then((res) => {
                setOpen(false);
                console.log(res);
                swal(t("Success!"), t("Payment Completed successfully"), "success");
                setRedirectAfertPay(true);
            })
            .catch((err) => {
                setErrors({ errorMessages: err });
                setOpen(false);
            });
    }
};

export { createPaymentIntent, handlePaymentSuccess };
