import React, { useState } from "react";
import ProfileLogo from "../../images/profile-2.svg";
import LoadingSpin from "../utils/Loading";
import { setNewUserImageProfile } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import UseUserState from "../hooks/useUserState";
import Button from "@material-ui/core/Button";
import useBDocTranslator from "../hooks/useBDocTranslator";

function ProviderProfileImage({ imgLoading }) {
  const userState = UseUserState();
  const [imageUrl, setImageUrl] = useState(null);
  const userImageProfile = userState[0].user?.user_images.at(-1);
  const { t } = useBDocTranslator();

  //Distpach of Redux
  const distpach = useDispatch();

  //Extract the userState to Redux Glabal State
  const newUserImageState = useSelector((state) => state.newUserImage);

  const handleFileImage = (e) => {
    const file = e.target.files[0];
    readImage(file);
    distpach(setNewUserImageProfile({ profileImg: file, exists: true }));
  };

  const readImage = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
  };

  return (
    <div className="text-center w-100 mw-75 shadow-sm p-3 mb-5 bg-white rounded">
      <article className="w-auto">
        <p className="label-avatar">{t("Complete your Profile")}</p>
        <div className="img-cropper">
          {imgLoading && (
            <div className="d-flex justify-content-center">
              <LoadingSpin type="spinningBubbles" color="#1d3557" />
            </div>
          )}

          {newUserImageState?.newProfileImg && imageUrl && (
            <img className="profile-avatar" src={imageUrl} alt="Profile-logo" />
          )}

          {userImageProfile && imageUrl === null && (
            <img
              className="profile-avatar"
              src={userImageProfile?.path_resource}
              alt="Profile-logo"
            />
          )}
          {!userImageProfile && imageUrl === null && !imgLoading && (
            <img
              className="profile-avatar"
              src={ProfileLogo}
              alt="Profile-logo"
            />
          )}
        </div>
        <div className="mt-5">
          <input
            accept="image/*"
            id="contained-button-file"
            className="d-none"
            multiple
            type="file"
            onChange={handleFileImage}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              {t("Change Profile Photo")}
            </Button>
          </label>
        </div>
      </article>
    </div>
  );
}

export default ProviderProfileImage;
