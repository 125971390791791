import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import {useEffect, useState} from "react";
import useBDocTranslator from "../hooks/useBDocTranslator";

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState("");
    const {t, changeDirectLanguage} = useBDocTranslator();



    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // get base path url
        const baseUrl = window.location.origin;

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${baseUrl}/patient/checkout/result`,
            },
        });



        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
            setMessage(result.error.message)
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button className="w-100 my-2 mx-1 bg-primary pt-2 pb-2 border-0 text-white font-weight-bold" disabled={!stripe}>{t("Pay")}</button>
            <p>{message}</p>
        </form>
    )
};

export default CheckoutForm;