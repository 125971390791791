import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
    Box
} from "@material-ui/core"; // Import from @material-ui/core
// import logo from "../images/logo-doc-nic.jpg";
import RecipeNavbar from "./RecipeNavbar";

const Landing = () => {

  const Logo = process.env.REACT_APP_TENANT_LOGO;


  const handleRecipeSearch = (trackingNumber) => {
    console.log("Searching for recipes with tracking number:", trackingNumber);
  };

  return (
      <Container maxWidth="lg">
        {/*<RecipeNavbar onSearch={handleRecipeSearch} />*/}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card variant="elevation">
              <CardContent>
                <Box display="flex" justifyContent="center"> {/* Center the image */}
                  <img src={Logo} alt="DocOnline Logo" className="img-fluid" width={'20%'} height={'20%'} />
                </Box>
                <Typography variant="h5" component="div" gutterBottom>
                  We are a telemedicine platform that connects patients with doctors and medical professionals. Our goal is to provide the best medical care to our patients and to make it easy for them to access medical care.
                </Typography>
                <hr />
                <Typography variant="body1" paragraph>
                  If you are a patient, you can register and book an appointment, or you can book a virtual consultation with a doctor.
                </Typography>
                <Button variant="contained" color="primary" href="http://bestdoc.app">
                  Learn more
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card variant="elevation">
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  For Patients
                </Typography>
                <Typography variant="body1" paragraph>
                  If you are a patient, you can register and book an appointment, or you can book a virtual consultation with a doctor.
                </Typography>
                <Button component={Link} to="/patient/login" variant="contained" color="primary">
                  Patient Portal
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card variant="elevation">
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  For Providers
                </Typography>
                <Typography variant="body1" paragraph>
                  If you are a provider, you can register and start providing medical services to patients.
                </Typography>
                <Button component={Link} to="/admin/login" variant="contained" color="primary">
                  Provider Portal
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
  );
};

export default Landing;
