const userRegistrationDataReducer = (state = {}, action) => {
    switch (action.type) {
        case "SET_REGISTRATION_DATA":
            if (action.payload){
                // take the user from session storage
                const localState = JSON.parse(
                    window.sessionStorage.getItem("userState")
                );
                if(localState){
                    // clone the user object and add new element to user_images array with the new image
                    const newUser = {
                        ...localState.user,
                        registration_data: action.payload
                    };
                    state.user = newUser;
                    localState.user = newUser;
                    window.sessionStorage.setItem("userState", JSON.stringify(localState));
                    return state;
                } else {
                    window.sessionStorage.setItem("userState", JSON.stringify(action.payload));
                    return action.payload;
                }
            }
            break;
        default:
            return state;
    }
}

export default userRegistrationDataReducer;