import React, { useEffect, useState } from "react";
import DashboardPatient from "../dashboard/DashboardPatient";
import HeadingResume from "../widgets/HeadingResume";
import WalletIcon from "./../../images/wallet-svg.svg";
import OrderCard from "./OrderCard";
import Axios from "axios";
import useUserState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function OrdersSummary() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const localState = useUserState();
  const { t } = useBDocTranslator();

  useEffect(() => {
    setLoading(true);
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/patient/orders/summary?userId=${localState[0].user?.id}`,
      {
        headers: { Authorization: "Bearer " + localState[0].jwt },
      }
    )
      .then((response) => {
        setLoading(false);
        setOrders(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  return (
    <DashboardPatient>
      <HeadingResume
        title={t("Your Orders List")}
        description={t(
          "In this section you can find your previous and next Orders, on each order card you will be able to see the details of the order"
        )}
        icon={WalletIcon}
      />
      <div className="row">
        {loading && (
          <div className="col-12 text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">{t("Loading...")}</span>
            </div>
          </div>
        )}
        {orders &&
          orders.length > 0 &&
          orders.map((item) => (
            <OrderCard
              key={item.id}
              serviceName={item.service_type.name}
              servicePrice={item.price_total}
              providerName={
                item.appointment.provider.firstName +
                " " +
                item.appointment.provider.lastname
              }
              serviceStatus={item.status}
              apptDate={new Date(
                item.appointment.appt_date_start
              ).toLocaleDateString("en-US")}
              handleShowDetails={() => {}}
            />
          ))}
      </div>
    </DashboardPatient>
  );
}
