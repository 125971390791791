import React from "react";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "30px",
    height: "45px",
    padding: "10px",
    width: "100%",
  },
  link: {
    color: "#5390d9",
    fontSize: "13px",
  },
}));



export default function BreadCrumbsNav(props) {
  const classes = useStyles();

  return (
    <Paper elevation={1} className={classes.paper}>
      <Breadcrumbs aria-label="breadcrumb">
        {props.breadOptions &&
          props.breadOptions.map((opt) => {
            return (
              <Link key={opt.title} href={opt?.url} className={classes.link}>
                {opt.title}
              </Link>
            );
          })}
      </Breadcrumbs>
    </Paper>
  );
}
