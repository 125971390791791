import React, { useState } from "react";
import LoginForm from "./Login";
import ToggleButton from "./ToggleButton";
import Login from "./Login";
import PatientsRegistration from "../registration/PatientsRegistration";

/**
 * @deprecated
 * @returns
 */
function LoginFront() {
  const [login, setLogin] = useState(true);
  const [register, setRegister] = useState(false);

  const handleChangeReg = () => {
    setRegister(true);
    setLogin(false);
  };
  const handleChangeLogin = () => {
    setLogin(true);
    setRegister(false);
  };

  return (
    <div className="container">
      {/* <ToggleButton
        handleToggleLog={handleChangeLogin}
        handleToggleReg={handleChangeReg}
      /> */}
      {login && <Login />}
      {register && <PatientsRegistration />}
    </div>
  );
}

export default LoginFront;
