import React from "react";
import ReactStars from "react-rating-stars-component";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

function StarsComponent(props) {
  return (
    <div className="mb-3 d-flex justify-content-center">
      <ReactStars
        count={5}
        onChange={props.ratingChanged}
        size={24}
        isHalf={false}
        char="★"
        edit={props.editRate}
        value={props.rating}
        // emptyIcon={EmptyStar}
        // halfIcon={HalfStar}
        // fullIcon={fullStar}
        // activeColor="#ffd700"
      />
    </div>
  );
}

export default StarsComponent;
