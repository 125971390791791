import React from "react";
//import { Button, MenuItem, FormControl } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

import { TextField, Button, MenuItem, FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function RoleEditionForm(props) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div>
      <div className="form-group col-md-6">
        <TextField
          margin="normal"
          label="Role Name"
          variant="outlined"
          id="roleName"
          fullWidth
          //onChange={(e) => setfirstName(e.target.value)}
          required
          value={props.roleName}
        />
      </div>
      <div className="form-group col-md-6">
        <TextField
          id="role-status"
          required
          select
          fullWidth
          variant="outlined"
          margin="normal"
          placeholder="Select"
          label="Role Status"
          value={props.roleStatus || ""}
          onChange={props.handleChange}
        >
          <MenuItem key={1} value={"enabled"}>
            Enabled
          </MenuItem>
          <MenuItem key={2} value={"disabled"}>
            Disabled
          </MenuItem>
        </TextField>
      </div>
      <div className="form-group col-md-6">
        <FormControl className="col-md-12">
          <Autocomplete
            multiple
            id="tags-outlined"
            options={props.modules}
            getOptionLabel={(option) => option.name}
            value={props.assignedModules}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            onChange={(e, val, reason, details) => {
              props.handleChangeModules(val);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Modules" placeholder="Modules" />
            )}
          />
        </FormControl>
      </div>
      <div className="form-group col-md-10">
        <Button
          color="inherit"
          variant="contained"
          onClick={props.handleBack}
          className="m-1"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className="m-1"
          startIcon={<SaveIcon />}
          onClick={props.handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
