const userUpdateState = (state = {}, action) => {
    switch (action.type) {
        case "UPDATE_USER_STATE":
            const newState = { ...state, ...action.payload };

            window.sessionStorage.setItem("userState", JSON.stringify(newState));

            return newState;

        default:
            return state;
    }
};

export default userUpdateState;