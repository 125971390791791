import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import useBDocTranslator from "../hooks/useBDocTranslator";
import useUserState from "../hooks/useUserState";
import Axios from "axios";
import { MenuItem, TextField } from "@material-ui/core";

export default function PrenscriptionDialog(props) {
  const { t, changeDirectLanguage } = useBDocTranslator();
  let btnStatus = props.enabledSaveBtn;

  const [pharmacies, setPharmacies] = useState([]);
  const userLocalState = useUserState();

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/pharmacy/findAll`, {
      headers: { Authorization: "Bearer " + userLocalState[0].jwt },
    })
      .then((response) => {
        setPharmacies(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    changeDirectLanguage();
  }, []);

  return (
    <div className="shadow p-3 m-2 bg-white rounded-lg col-md-12 col-sm-12 position-relative prescription-card">
      {/* Pharmacies */}
      <TextField
        id="pharmacy-list"
        required
        select
        fullWidth
        variant="outlined"
        margin="normal"
        placeholder={t("Referals to Pharmacy ")}
        label={t("Pharmacy")}
        onChange={(e) => props?.setPharmacy(e.target.value)}
        value={props?.pharmacy || "select"}
      >
        <MenuItem value="select">{t("Select")}</MenuItem>
        {pharmacies?.length > 0 &&
          pharmacies.map((item, idx) => {
            return (
              <MenuItem key={idx} value={item.id}>
                {`${item.name} - ${item.city}`}
              </MenuItem>
            );
          })}
      </TextField>

      <textarea
        name="prescription-txt"
        id="prescription-txt"
        rows="10"
        placeholder={t(
          "Sug, In each line / item of the prescription you must prepend an asterisk"
        )}
        className="mb-3 w-100 h-60-custom form-control col-md-12"
        onChange={props.handleNotes}
        value={props.prescriptionValue}
      ></textarea>
      <div className="row">
        <Button
          variant="contained"
          color="primary"
          onClick={props.handleSave}
          className="col-md-6"
          // disabled={!btnStatus}
        >
          {t("Save")}
        </Button>
        <div className="col-md-6">
          <p className="font-weight-bold">{t(props.responseMessage)}</p>
        </div>
      </div>
    </div>
  );
}
