import React, { useEffect, useState } from "react";

import { useParams, useLocation } from "react-router-dom";
import "../../../styles/customs.css";
import NICFlag from "../../../images/nicaragua-flag.png";
import CRCFlag from "../../../images/cr-flg.png";
import BahFlag from "../../../images/bahamas-flag.png";
import USAFlag from "../../../images/usa-flag.png";
import JAMFlag from "../../../images/jamaica-flag.png";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import UseUserState from "../../hooks/useUserState";
import LoadingSpin from "../../utils/Loading";
import { makeStyles } from "@material-ui/core/styles";
import ProfileLogo from "../../../images/profile-2.svg";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import { resizeByLink } from "../../utils/ResizeLinkCloudinary";
import useBDocTranslator from "../../hooks/useBDocTranslator";
import DashboardAdmin from "../DashboardAdmin";
import AppointmentSummaryOP from "./AppointmentSummaryOP";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down("sm")]: {
      width: "800px",
    },
  },
}));

export default function AppointmentHistoryOP() {
  const classes = useStyles();
  const { id } = useParams();
  const storeState = UseUserState();
  const [loading, setLoading] = useState(true);
  const [appointmentList, setAppointmentList] = useState([]);
  let history = useHistory();

  const { t, changeDirectLanguage } = useBDocTranslator();

  const findAppointments = (e) => {
    if (storeState[0].jwt) {
      Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/appointments/findByPatient?patientId=${id}`,
        {
          headers: {
            Authorization: "Bearer " + storeState[0].jwt,
          },
        }
      )
        .then((res) => {
          console.log("appts", res.data);
          setAppointmentList(res.data);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    findAppointments();
    changeDirectLanguage();
  }, []);

  const handleCancel = () => {
    history.goBack();
  };

  const lastOrderRegister = (serviceOrders) => {
    if (serviceOrders.length > 0) {
      return serviceOrders.sort((a, b) => b.id - a.id);
    }
    return serviceOrders;
  };

  return (
    <DashboardAdmin>
      <div
        className={
          "container-fluid m-3 p-3 shadow rounded-lg-custom bg-white " +
          classes.paper
        }
      >
        {/* GoBack Button */}
        <div>
          <IconButton
            aria-label="delete"
            className={classes.margin}
            fontSize="large"
            onClick={handleCancel}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        {appointmentList.length > 0 ? (
          appointmentList.map((item) => {
            const appt_start = moment(item.appt_date_start);
            const appt_end = moment(item.appt_date_end);
            const provider_fullname = `${item?.provider?.firstName} ${item?.provider?.lastname}`;
            const serviceName =
              item.services_orders.length > 0 &&
              item.services_orders?.[0].service_type?.name;
            const serviceOrderStatus = lastOrderRegister(item.services_orders);

            return (
              <AppointmentSummaryOP
                key={item.id}
                event_code={item.event_code}
                patient_fullname={
                  item.patient.firstName + " " + item.patient.lastname
                }
                password="br4ck3ts1t"
                appt_date_start={appt_start.utc().format("yy-MM-DD HH:mm")}
                appt_date_end={appt_end.utc().format("yy-MM-DD HH:mm")}
                providerFullname={provider_fullname}
                service_name={serviceName}
                event_type={item.event_type}
                appt_status_code={item.status_appointment.appt_status_code}
                appt_status_name={item.status_appointment.description}
                // profileImage={profileImageURL}
                appointmentId={item.id}
                paymentStatus={
                  serviceOrderStatus.length > 0
                    ? serviceOrderStatus[0].status
                    : "N/A"
                }
                patientEmail={null}
                appointmentReason={item.appt_reason}
                orderId={
                  serviceOrderStatus.length > 0 ? serviceOrderStatus[0].id : 0
                }
                patientId={item.patient.id}
              />
            );
          })
        ) : (
          <div className="row container-fluid">
            <div className="col-12 d-flex justify-content-center">
              <Typography
                className="pt-2 font-weight-bold"
                variant="h4"
                gutterBottom
              >
                {t("No Appointments")}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </DashboardAdmin>
  );
}

function AppointmentCardOP({ providerImg, provider }) {
  return (
    <div className="row container-fluid">
      <div className="col-12 col-lg-2 d-flex justify-content-center">
        <div className="img-d-cropper">
          <img
            className="profile-avatar"
            src={
              providerImg ? resizeByLink(providerImg, "upload") : ProfileLogo
            }
            alt="Profile-logo"
          />
        </div>
      </div>

      <div className="col-10 col-lg-8 mt-3 mt-lg-0 d-flex justify-content-start align-items-center">
        <Typography className="pt-2 font-weight-bold" variant="h4" gutterBottom>
          {provider.firstName + " " + provider.lastname}
        </Typography>
      </div>
      <div className="col-12 d-flex justify-content-center">
        <Divider className="mt-3 mb-3 divider-appt-op" />
      </div>
    </div>
  );
}
