const EHRReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_EHR_DATA":
      if (action.payload) {
        const ehrState = { ...state, ehr: action.payload };
        return ehrState;
      }
      break;
    default:
      return state;
  }
};

export default EHRReducer;
