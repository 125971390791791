import React, { useState, useEffect } from "react";
import DashboardMui from "../dashboard/DashboardMui";
import Axios from "axios";
import RolesTable from "../utils/TableUIRoles";
import { useHistory } from "react-router-dom";
import LoadingSpin from "../utils/Loading";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import shieldIcon from "../../images/shield.svg";
import DashboardAdmin from "../administrator/DashboardAdmin";
import useUserState from "../hooks/useUserState";
import RoleTable from "../administrator/security-components/RoleTable";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function Roles(props) {
  const [data, setData] = useState([]);
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  //Backdrop
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [statusUpdated, setStatusUpdated] = useState(false);

  const userLocalState = useUserState();

  useEffect(() => {
    setLoading(true);

    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/role`, {
      headers: {
        Authorization: "Bearer " + userLocalState[0].jwt, //the jwt is a variable which holds the token
      },
    })
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [statusUpdated]);

  const handleClickEdit = (row) => {
    history.push("/users/role/edit/" + row.id);
  };

  //to handle the status for swith control for the role
  const handleStatusChange = (roleStatus, roleId) => {
    setOpen(true); //block the screen until the request
    const role_status = roleStatus === "ENABLED" ? "DISABLED" : "ENABLED";

    const params = {
      id: roleId,
      status: role_status,
    };
    Axios.put(
      `${process.env.REACT_APP_API_URL_BASE}/role/update/roleStatus`,
      params,
      {
        headers: {
          Authorization: "Bearer " + userLocalState[0].jwt, //the jwt is a variable which holds the token
        },
      }
    )
      .then((res) => {
        //setLoading(false);
        setOpen(false);
        swal("Success!", "Role Updated", "success");
        setStatusUpdated(!statusUpdated);
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
        swal(
          "Error!",
          "Please try later or contact to the Administrator",
          "warning"
        );
      });
  };

  const columns = [
    "#",
    "Role Name",
    "Creation Date",
    "Status",
    "Change Status",
    "Actions",
  ];
  return (
    <DashboardAdmin
      sectionTitle="Roles & Permision"
      description="In this section you will be able to manage permision and roles for users"
      icon={shieldIcon}
    >
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="container-fluid mt-5">
        {loading && <LoadingSpin type="spinningBubbles" color="#1d3557" />}
        <RoleTable
          data={data}
          columns={columns}
          clickEvent={handleClickEdit}
          handleStatusChange={handleStatusChange}
        />
      </div>
    </DashboardAdmin>
  );
}

export default Roles;
