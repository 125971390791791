import React from "react";
import logo from "../images/logo.png";
import Header from "./Header";
import Landing from "./Landing";
//Styles
import "../styles/carousel.css";
import "bootstrap/dist/css/bootstrap.css";
import Carousel from "./Carousel";
import MarketingContainer from "./MarketingContainer";
import Footer from "./footer";

function Home() {
  return (
    <React.Fragment>
      <Landing className="mt-3" />
      <Footer />
    </React.Fragment>
  );
}

export default Home;
