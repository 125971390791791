import React from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

export default function CropImage({
  src,
  onComplete,
  imageRef,
  setCrop,
  crop,
}) {
  return (
    <ReactCrop crop={crop} onChange={(c) => setCrop(c)} onComplete={onComplete}>
      <img src={src} alt="signature" ref={imageRef} width={500} />
    </ReactCrop>
  );
}
