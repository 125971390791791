const userNewImageReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_NEW_USER_IMAGE_PROFILE":
      if (action.payload) {
        const { profileImg, exists } = action.payload;
        state.newProfileImg = profileImg;
        return state;
      }
      break;
    default:
      return state;
  }
};

export default userNewImageReducer;
