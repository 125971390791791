const userReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      if (action.payload) {
        const { user, jwt, language, extendedData, patientId } = action.payload;
        state.user = user;
        state.jwt = jwt;
        state.language = language;
        state.extendedData = extendedData;
        state.patientId = patientId;
        window.sessionStorage.setItem("userState", JSON.stringify(state));
        return state;
      }
      break;
    default:
      return state;
  }
};

export default userReducer;
