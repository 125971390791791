import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import "../../styles/dashboard.css"

function DashSidebarOption({ linkTo, Icon, optionText }) {
  return (
    <>
      <ListItem button>
        <ListItemIcon>
          {/* Here Icon component received by props */}
          {Icon && <Icon />}
        </ListItemIcon>
        <ListItemText primary={optionText} />
      </ListItem>
    </>
  );
}

export default DashSidebarOption;
