import React, {useState, useEffect} from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {makeStyles} from "@material-ui/core/styles";
import userIcon from "../../../../images/user-icon.svg";
import useUserState from "../../../hooks/useUserState";
import useBDocTranslator from "../../../hooks/useBDocTranslator";
import TextField from "@material-ui/core/TextField";
import {Button, MenuItem} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import InformationComp from "../../../utils/InformationComp";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import {AffliationsLogic} from "./AffliationsLogic";
import {useAffiliateUser} from "../../../../services/services";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: "whitesmoke",
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "900px",
        height: "600px",
    },
}));

export default function AffiliationModalAssignation({open, handleClose}) {
    const classes = useStyles();
    const [responseMessage, setResponseMessage] = useState("");
    const [userLocalState] = useUserState();
    const [successFlag, setSuccessFlag] = useState(false);
    const {t} = useBDocTranslator();
    const {updateAffiliation, getPatientList, patientList, getPlanList, planList, selectedPatient, setSelectedPatient, selectedPlan, setSelectedPlan} = AffliationsLogic()

    const {affiliateUser, affiliateUserData, loading} = useAffiliateUser(userLocalState.jwt)

    useEffect(() => {
        getPatientList().then(r => console.log("patient loaded"))
        getPlanList().then(r => console.log("plan loaded"))
    }, [])

    const handleAffiliateUser = () => {
        if(selectedPatient && selectedPlan){
            const data = {
                patientId: selectedPatient,
                planId: selectedPlan,
                status: 'active',
                affiliation_dt_start: new Date(),
            }
            affiliateUser(data).then(r => console.log(r))
        }else{
            setResponseMessage("ERROR: You must select a patient and a plan")
        }
    }


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    {
                        <div className={classes.paper}>
                            <div className="row col-md-12 mb-4">
                                <div className="col-md-4">
                                    <img src={userIcon} alt="rx icon" className="w-30-custom"/>
                                </div>
                                <div className="col-md-8">
                                    <h3>{t("Create Affiliation")}</h3>
                                </div>
                                <div className="col-md-12">
                                    <p
                                        className={`font-weight-bold  ${
                                            responseMessage?.includes("ERROR")
                                                ? "text-danger"
                                                : "text-success"
                                        } text-center`}
                                    >
                                        {t(responseMessage)}
                                    </p>
                                </div>
                            </div>
                            {/* Form */}
                            <div className="row col-md-12 justify-content-center">
                                {/*    Form here to Edit the plan information when the user click on edit button in the table, the data will be received by props */}
                                <div className="row col-md-12 my-3">
                                    {/*    Patient List */}
                                    <div className="col-md-6">
                                        <TextField
                                            id="outlined-basic"
                                            label="Patient Name"
                                            variant="outlined"
                                            className="w-100-custom"
                                            fullWidth
                                            select
                                            value={selectedPatient}
                                            onChange={e => setSelectedPatient(e.target.value)}
                                        >
                                            <MenuItem value={""}>
                                                Select a Patient
                                            </MenuItem>
                                            {patientList?.map((patient) => (
                                                <MenuItem
                                                    key={patient.id}
                                                    value={patient.id}
                                                >
                                                    {patient?.firstName + " " + patient?.lastname + " | Identification: " + patient?.identification}
                                                </MenuItem>
                                            ))}

                                        </TextField>
                                    </div>
                                    {/*    Plan */}
                                    <div className="col-md-6">
                                        <TextField
                                            id="outlined-basic"
                                            label="Health Plan"
                                            variant="outlined"
                                            className="w-100-custom"
                                            fullWidth
                                            select
                                            value={selectedPlan}
                                            onChange={e => setSelectedPlan(e.target.value)}
                                        >
                                            <MenuItem value={""}>
                                                Select a Plan
                                            </MenuItem>
                                            {planList?.map((plan) => (
                                                <MenuItem
                                                    key={plan.id}
                                                    value={plan.id}
                                                >
                                                    {plan?.name + " | desc: " + plan?.description + " | price: " + plan?.price}
                                                </MenuItem>
                                            ))}

                                        </TextField>
                                    </div>
                                </div>


                                {/*divisor line */}
                                <hr className="w-100"/>
                                <div className="col-md-12 my-3">
                                    <div className="mb-4">
                                        <InformationComp
                                            title={t("Importante")}
                                            description={t(
                                                "Las Afiliaciones manuales solo estan permitas por el administrador ya que las mismas son de cobro 0 (cero)."
                                            )}/>
                                    </div>
                                    {/* Loading Spinner */}
                                    <div className="row col-md-12 justify-content-center">
                                        {loading && (
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        )}
                                    </div>
                                    {/* response of affiliation */}
                                    <div className="row col-md-12 justify-content-center">
                                        {affiliateUserData && (
                                            <div className="alert alert-success" role="alert">
                                                Affiliation created successfully - ID: {affiliateUserData?.id}
                                            </div>
                                        )}
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 d-flex justify-content-center ">
                                            <div className="p-3">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    startIcon={<CheckCircleOutlineIcon/>}
                                                    onClick={handleAffiliateUser}
                                                    disabled={loading || affiliateUserData}
                                                >
                                                    {t("Save")}
                                                </Button>
                                            </div>

                                            <div className="p-3">
                                                <Button
                                                    variant="contained"
                                                    color="default"
                                                    startIcon={<CancelIcon/>}
                                                    onClick={handleClose}
                                                >
                                                    {t("Cancel")}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    }
                </Fade>
            </Modal>
        </div>
    );
}
