import React, { useEffect } from "react";
import DashboardMUI from "../dashboard/DashboardMui";
import providerIcon from "../../images/doctor.svg";
import bdocIcon from "../../images/bdoc-providers.png";
import settingsIcon from "../../images/settings-icon.svg";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import Avatar from "@material-ui/core/Avatar";
import CardActions from "@material-ui/core/CardActions";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import { ContactSupport } from "@material-ui/icons";
import BusinessIcon from "@material-ui/icons/Business";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import { useHistory } from "react-router-dom";
import SettiingOption from "./SettiingOption";
import CalendarSetting from "../../images/calendar-setting-cover.png";
import ServiceSetting from "../../images/services-settings-cover.png";
import UserSetting from "../../images/user-setting-cover.png";
import useBDocTranslator from "../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  responsive: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  info: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  content: {
    flex: "1 0 auto",
  },
  Icon: {
    height: 15,
    width: 20,
  },
}));

export default function ProviderSetting() {
  const history = useHistory();
  const classes = useStyles();
  const { t, changeDirectLanguage } = useBDocTranslator();

  useEffect(() => {
    changeDirectLanguage();
  }, []);

  return (
    <DashboardMUI
      sectionTitle={t("Manage Settings")}
      description={t("In this section you can Manage your settings")}
      icon={settingsIcon}
    >
      <div className="row">
        <div className="col-md-6 col-sm-12 mt-2">
          <Card className={classes.root}>
            <CardActionArea
              onClick={(e) => {
                history.push("/admin/serviceSettings");
              }}
            >
              <CardMedia
                // className={classes.cover}
                image={ServiceSetting}
                component="img"
                alt="Service Settings"
                height="140"
              />
              <div className={classes.details}>
                <CardContent className={classes.content}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {t("Manage your Services")}
                  </Typography>
                  <div className={classes.responsive + " row"}>
                    <div className="col-1 pr-1 d-flex align-items-center justify-content-center">
                      <Typography variant="body2" color="primary" component="p">
                        <BusinessIcon className={classes.Icon} />
                      </Typography>
                    </div>
                    <div className="col-7 pl-2">
                      <Typography variant="body2" color="primary" component="p">
                        {t("Custom Services")} {":"}
                      </Typography>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                      <Typography variant="body2" color="primary" component="p">
                        {t("ON")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.responsive + " row"}>
                    <div className="col-1 pr-1 d-flex align-items-center justify-content-center">
                      <Typography variant="body2" color="primary" component="p">
                        <ChatBubbleOutlineIcon className={classes.Icon} />
                      </Typography>
                    </div>
                    <div className="col-7 pl-2">
                      <Typography variant="body2" color="primary" component="p">
                        {t("Electronic Payments")} {":"}
                      </Typography>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                      <Typography variant="body2" color="primary" component="p">
                        {t("ON")}
                      </Typography>
                    </div>
                  </div>
                  {/* <div className={classes.responsive + " row"}>
                    <div className="col-1 pr-1 d-flex align-items-center justify-content-center">
                      <Typography variant="body2" color="primary" component="p">
                        <VideoCallIcon className={classes.Icon} />
                      </Typography>
                    </div>
                    <div className="col-7 pl-2">
                      <Typography variant="body2" color="primary" component="p">
                        Video Consultation :
                      </Typography>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                      <Typography variant="body2" color="primary" component="p">
                        ON
                      </Typography>
                    </div>
                  </div> */}
                </CardContent>
              </div>
            </CardActionArea>
          </Card>
        </div>
        <div className="col-md-6 col-sm-12 mt-2">
          <Card className={classes.root}>
            <CardActionArea
              onClick={(e) => {
                history.push("/admin/userSettings");
              }}
            >
              <CardMedia
                // className={classes.cover}
                image={UserSetting}
                component="img"
                alt="User Settings"
                height="140"
              />
              <div className={classes.details}>
                <CardContent className={classes.content}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {t("User Settings")}
                  </Typography>
                  <div className={classes.responsive + " row"}>
                    <div className="col-1 pr-1 d-flex align-items-center justify-content-center">
                      <Typography variant="body2" color="primary" component="p">
                        <BusinessIcon className={classes.Icon} />
                      </Typography>
                    </div>
                    <div className="col-7 pl-2">
                      <Typography variant="body2" color="primary" component="p">
                        {t("Custom Settings")} {":"}
                      </Typography>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                      <Typography variant="body2" color="primary" component="p">
                        {t("ON")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.responsive + " row"}>
                    <div className="col-1 pr-1 d-flex align-items-center justify-content-center">
                      <Typography variant="body2" color="primary" component="p">
                        <ChatBubbleOutlineIcon className={classes.Icon} />
                      </Typography>
                    </div>
                    <div className="col-7 pl-2">
                      <Typography variant="body2" color="primary" component="p">
                        {t("Personal Signatures")} {":"}
                      </Typography>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                      <Typography variant="body2" color="primary" component="p">
                        {t("ON")}
                      </Typography>
                    </div>
                  </div>
                  {/* <div className={classes.responsive + " row"}>
                    <div className="col-1 pr-1 d-flex align-items-center justify-content-center">
                      <Typography variant="body2" color="primary" component="p">
                        <VideoCallIcon className={classes.Icon} />
                      </Typography>
                    </div>
                    <div className="col-7 pl-2">
                      <Typography variant="body2" color="primary" component="p">
                        Video Consultation :
                      </Typography>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                      <Typography variant="body2" color="primary" component="p">
                        ON
                      </Typography>
                    </div>
                  </div> */}
                </CardContent>
              </div>
            </CardActionArea>
          </Card>
        </div>
        {/* Calendar Time Slot Config */}

        <SettiingOption
          link="/admin/provider/planner"
          title={t("Calendar Availability")}
          banner={CalendarSetting}
        />

        {/* END calendar time Slot */}
      </div>
    </DashboardMUI>
  );
}
