import React, { useState } from "react";
import ProfileLogo from "../../images/profile-2.svg";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import "../../styles/customs.css";
import LoadingSpin from "../utils/Loading";
import useBDocTranslator from "../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));

function PatientProfile(props) {
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState("");
  const { t } = useBDocTranslator();

  const handleFileImage = (file) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => {
      props.handleSaveProfileImg(file);
    };
  };

  return (
    <div className="text-center w-100 mw-75 shadow-sm p-3 mb-5 bg-white rounded">
      <article className="w-auto">
        <p className="label-avatar">{t("Edit Profile")}</p>
        <div className="img-cropper">
          {props.userImageURL && (
            <img
              className="profile-avatar"
              src={props.userImageURL}
              alt="Profile-logo"
            />
          )}

          {/* if the user does not have an avatar set default */}
          {!props.userImageURL && (
            <img
              className="profile-avatar"
              src={ProfileLogo}
              alt="Profile-logo"
            />
          )}

          {props.imgLoading && (
            <div className="d-flex justify-content-center">
              <LoadingSpin type="spinningBubbles" color="#1d3557" />
            </div>
          )}
        </div>
        {/* If disable button is False then show the button */}
        {!props.disableButton && (
          <div className="mt-5">
            <input
              accept="image/*"
              id="contained-button-file"
              className={classes.input}
              multiple
              type="file"
              onChange={(e) => handleFileImage(e.target.files[0])}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                disabled={props.disableButton || false}
              >
                {t("Change Profile Photo")}
              </Button>
            </label>
          </div>
        )}
      </article>
    </div>
  );
}

export default PatientProfile;
