import React from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import useUserState from "../hooks/useUserState";
import swal from "sweetalert";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function RatingComponent() {
  const history = useHistory();
  const userState = useUserState();
  const { t } = useBDocTranslator();

  const redirectUser = () => {
    setTimeout(() => {
      history.push("/patient/dashboard");
    }, 500);
  };

  const rateProvider = async (rating, appointmentId) => {
    const { data } = await Axios.put(
      `${process.env.REACT_APP_API_URL_BASE}/appointments/rateAppointment`,
      {
        rating,
        appointment_id: appointmentId,
      },
      {
        headers: { Authorization: "Bearer " + userState[0].jwt },
      }
    );
    swal(t("Success!"), t("Your rating has been saved"), "success");
    redirectUser();
  };

  const saveRating = async () => {
    await rateProvider();
  };

  return { rateProvider, lan: userState[0].language, saveRating };
}
