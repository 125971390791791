import {Grid} from "@material-ui/core";
import {Alert,AlertTitle} from "@material-ui/lab";
import React from "react";
function InformationComp({title,description, style}){
    return <Grid
        style={{style}}
    >
        <Alert severity="info">
            <AlertTitle>{title}</AlertTitle>
            {description}
        </Alert>
    </Grid>;
}
export default InformationComp;