import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {CssBaseline, Container} from "@material-ui/core";
import swal from "sweetalert";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(8),
        alignItems: "center",
        padding: "30px",
        backgroundColor: "white",
        boxShadow: "5px 5px 16px 1px #caced0",
        borderRadius: "10px",
    },

}));
export default function PlanSpecialties({
                                            specialties,
                                            planSpecialties,
                                            setPlanSpecialties,
                                            planToEdit,
                                            setPlanToEdit
                                        }) {
    const classes = useStyles();
    const [selectedSpecialty, setSelectedSpecialty] = useState("");
    const [numConsultationsSelected, setNumConsultationsSelected] = useState(0);
    const [tempPlanSpecialties, setTempPlanSpecialties] = useState([]);
    const [planSpecialty, setPlanSpecialty] = useState({
        specialty_id: '',
        plan_id: '',
        status: 'active',
        num_consultations: ''
    });

    const handleSetTempSpecialtyPlan = () => {
        if (selectedSpecialty === "" || numConsultationsSelected === 0) {
            swal("Error", "Please select a specialty and the Number of consultations", "error");
            return;
        }

        // find the specialty_name from the specialties list with the selectedSpecialty id
        let specialty_name = specialties.find(s => s.id === parseInt(selectedSpecialty)).speciality_name;
        // add the specialty_name to the tempPlanSpecialties array
        setTempPlanSpecialties([...tempPlanSpecialties, {...planSpecialty, specialty_name}]);
        // add the planSpecialty to the planSpecialties array
        setPlanSpecialties([...planSpecialties, planSpecialty]);
        // reset the selectedSpecialty to empty
        setSelectedSpecialty("");
        // reset the numConsultationsSelected to 0
        setNumConsultationsSelected(0);
    }

    // remove row from tempPlanSpecialties and planSpecialties arrays when remove button is clicked
    const handleRemoveSpecialty = (specialty_id) => {
        // filter out the specialty_id from the tempPlanSpecialties array
        let newTempPlanSpecialties = tempPlanSpecialties.filter(s => s.specialty_id !== specialty_id);
        // filter out the specialty_id from the planSpecialties array
        let newPlanSpecialties = planSpecialties.filter(s => s.specialty_id !== specialty_id);
        // set the tempPlanSpecialties array to the newTempPlanSpecialties array
        setTempPlanSpecialties(newTempPlanSpecialties);
        // set the planSpecialties array to the newPlanSpecialties array
        setPlanSpecialties(newPlanSpecialties);
    }

    const handleEditSpecialty = (specialty_id) => {
        // filter out the specialty_id from the tempPlanSpecialties array
        let newEditPlanSpecialties = planToEdit?.planSpecialties.filter(s => s.specialty_id !== specialty_id);
        // filter out the specialty_id from the planSpecialties array
        let newPlanSpecialties = planSpecialties.filter(s => s.specialty_id !== specialty_id);
        // set the tempPlanSpecialties array to the newTempPlanSpecialties array
        //setTempPlanSpecialties(newEditPlanSpecialties);
        // set the planSpecialties array to the newPlanSpecialties array
        setPlanToEdit({...planToEdit, planSpecialties: newEditPlanSpecialties});
    }

    // return the specialty_name from the specialties list with the specialty_id
    const getSpecialtyName = (specialty_id) => {
        return specialties.find(s => s.id === parseInt(specialty_id))?.speciality_name;
    }

    const handleSetPlanSpecialties = () => {
        console.log({planSpecialty, tempPlanSpecialties, planSpecialties})
        // set the tempPlanSpecialties array to the planSpecialties array into planToEdit
        if(planToEdit?.id){
            const planSpecialtiesEdit = planToEdit?.planSpecialties
            planSpecialtiesEdit.push(planSpecialty)

            // set the planSpecialties array into planToEdit
            setPlanToEdit({...planToEdit, planSpecialties: planSpecialtiesEdit})
        }

    }


    return (
        <Container component="main">
            <CssBaseline/>
            <div className={classes.paper}>

                <div className="form-group">
                    <label htmlFor="provider">Specialties List</label>
                    <select
                        className="form-control"
                        id="specialty"
                        name="specialty"
                        value={selectedSpecialty}
                        onChange={(e) => {
                            setSelectedSpecialty(e.target.value);
                            setPlanSpecialty({...planSpecialty, specialty_id: e.target.value})
                        }}
                    >
                        <option value="">Select Specialty</option>
                        {specialties ? specialties.map((specialty) => (
                            <option key={specialty.id} value={specialty.id}>
                                {specialty?.speciality_name}
                            </option>
                        )) : <option value="">No Specialties</option>

                        }
                    </select>
                    {/* Num of Consultation allowed */}
                    <div className="mt-2">
                        <label htmlFor="consultations">Consultations Allowed</label>
                        <input
                            type="number"
                            className="form-control"
                            id="consultations"
                            name="consultations"
                            min={1}
                            max={500}
                            placeholder="Enter Consultations Allowed"
                            value={numConsultationsSelected}
                            onChange={(e) => {
                                setNumConsultationsSelected(e.target.value);
                                setPlanSpecialty({...planSpecialty, num_consultations: e.target.value})
                            }}
                        />
                    </div>

                    <div className="mt-2">
                        <button className="btn btn-primary" onClick={()=>{
                            handleSetTempSpecialtyPlan()
                            handleSetPlanSpecialties()
                        }}>Add Specialty</button>
                    </div>
                    <div className="mt-3">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Num of Consultations</th>
                                <th>Remove</th>
                            </tr>
                            </thead>
                            <tbody>
                            { tempPlanSpecialties.length > 0 && !planToEdit?.id ? tempPlanSpecialties.map((esp) => (
                                <tr key={esp.specialty_id}>
                                    <td>{esp.specialty_name}</td>
                                    <td>{esp.num_consultations}</td>
                                    <td>
                                        <button onClick={() => handleRemoveSpecialty(esp.specialty_id)}
                                                className="btn btn-danger">Remove
                                        </button>
                                    </td>
                                </tr>
                            )) : <tr>
                              {!planToEdit?.id && <td colSpan={3}>No Specialties Added</td>}
                            </tr>

                            }
                            {
                                planToEdit?.id && planToEdit?.planSpecialties?.length > 0 ? planToEdit?.planSpecialties?.map((esp) => (
                                    <tr key={esp?.specialty_id}>
                                        <td>{getSpecialtyName(esp?.specialty_id)}</td>
                                        <td>{esp?.num_consultations}</td>
                                        <td>
                                            <button onClick={() => handleEditSpecialty(esp.specialty_id)}
                                                    className="btn btn-danger">Remove
                                            </button>
                                        </td>
                                    </tr>
                                )) : null
                            }
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
        </Container>
    )
}
