import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import StarsComponent from "../utils/StarsComponent";
import { useLocation } from "react-router-dom";
import RatingComponent from "./RatingComponent";
import useBDocTranslator from "../hooks/useBDocTranslator";
import { Button } from "@material-ui/core";

export default function Rating() {
  const location = useLocation();
  const { rateProvider, lan } = RatingComponent();
  const { t, changeDirectLanguage } = useBDocTranslator();
  const { appointmentId, providerFullname, profileImage } = location.state;
  const [currentRate, setCurrentRate] = useState(null);

  useEffect(() => {
    changeDirectLanguage();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div
          className="card rounded-lg shadow p-3 mb-5 bg-white"
          style={{ width: "25rem" }}
        >
          <div className="card-body">
            <h5 className="card-title">
              {t("Rate your experience")}
              {":"}
            </h5>
            <h5 className="card-subtitle mt-4 mb-2 text-muted">
              {providerFullname}
            </h5>
            <div className="d-flex justify-content-center">
              <Avatar
                alt={providerFullname}
                src={profileImage}
                style={{
                  width: "100px",
                  height: "100px",
                  border: "2px solid #085279a1",
                  backgroundColor: "black",
                }}
              />
            </div>

            <StarsComponent
              ratingChanged={(newRating) => {
                setCurrentRate(newRating);
              }}
              editRate={true}
            />
            <div className="d-flex justify-content-center">
              <p>{t("Your rating")}:</p>
              {currentRate === 1 ? (
                <p className="text-danger">{t("Very bad")}</p>
              ) : currentRate === 2 ? (
                <p className="text-danger">{t("Bad")}</p>
              ) : currentRate === 3 ? (
                <p className="text-warning">{t("Good")}</p>
              ) : currentRate === 4 ? (
                <p className="text-success">{t("Very good")}</p>
              ) : currentRate === 5 ? (
                <p className="text-success">{t("Excellent")}</p>
              ) : (
                <p className="text-muted">{t("Not rated")}</p>
              )}
            </div>

            <div className="d-flex justify-content-center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  rateProvider(currentRate, appointmentId);
                }}
                disabled={currentRate === null}
              >
                {t("Save")}
              </Button>
            </div>
            <section>
              <div className="d-flex justify-content-center">
                {/* explain why the rating is important for us */}
                <p>{t("Your rating is important for us")}</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
