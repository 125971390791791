export const dateToString = (date) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };
  if (date) {
    const dateResult = new Date(date).toLocaleDateString("en-US", options);
    return dateResult;
  }
};
