/**
 *  Component For Providers appointment calendar
 */
import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import FullCalendar, { flexibleCompare } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import DashboardMui from "../dashboard/DashboardMui";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import swal from "sweetalert";
import "../../styles/mycalendar.css";
import scheduleIcon from "../../images/schedule.svg";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { resizeByLink } from "./../utils/ResizeLinkCloudinary";
import moment from "moment";
import dummyProfile from "../../images/profile-2.svg";
import IconButton from "@material-ui/core/IconButton";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import Tooltip from "@material-ui/core/Tooltip";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import TextField from "@material-ui/core/TextField";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { FaCheckDouble, FaUserClock } from "react-icons/fa";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import Alert from "@material-ui/lab/Alert";
import timeGridPlugin from "@fullcalendar/timegrid";
import { dateToString } from "../utils/DateUtils";
import useUserState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";
import OfflinePinIcon from "@material-ui/icons/OfflinePin";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "whitesmoke",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function MyCalendar(props) {
  const localState = useUserState();
  const jwtSession = localState[0].jwt;

  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [patientName, setPatientName] = useState("");
  const [creationDate, setCreationDate] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [appointmentDuration, setAppointmentDuration] = useState("");
  const [appointmentProvider, setAppointmentProvider] = useState("");
  const [providerImage, setProviderImage] = useState(dummyProfile);
  const [patientImage, setPatientImage] = useState(dummyProfile);
  const [apptStatus, setApptStatus] = useState("");
  const [apptStatusCode, setApptStatusCode] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [btnStatus, setBtnStatus] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [appointmentReason, setAppointmentReason] = useState("");
  const [appt_date_start_rescheduled, setAppt_date_start_rescheduled] =
    useState("");
  const [appt_date_end, setAppt_date_end] = useState("");
  const [orderId, setOrderId] = useState("");
  const { t, changeDirectLanguage } = useBDocTranslator();

  let history = useHistory();

  // Appointments Status Collections and Styles to apply
  const APPT_STATUS_CODES = {
    NAPPT: "text-warning",
    CAPPT: "text-success",
    IPAPPT: "text-success",
    CDAPPT: "text-info",
    CCAPPT: "text-danger",
    RSAPPT: "text-warning",
  };

  useEffect(() => {
    changeDirectLanguage();
    setLoading(true);

    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/appointments/findByProviderId/`,
      {
        headers: {
          Authorization: "Bearer " + jwtSession,
        },
        params: {
          id: localState[0].extendedData.id,
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setData(res.data);
        getEvents(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const lastOrderRegister = (serviceOrders) => {
    if (serviceOrders.length > 0) {
      return serviceOrders.sort((a, b) => b.id - a.id);
    }
    return serviceOrders;
  };

  const getEvents = (data) => {
    const totalEvents = data.map(function (event) {
      const startDate = moment(event?.appt_date_start);
      const endDate = moment(event?.appt_date_end);
      const serviceOrderStatus = lastOrderRegister(event?.services_orders);
      return {
        title:
          event?.event_type +
          " for: " +
          event?.patient?.firstName +
          " " +
          event?.patient?.lastname,
        start: startDate.utc().format("yy-MM-DD HH:mm"),
        end: endDate.utc().format("yy-MM-DD HH:mm"),
        editable: true,
        backgroundColor: event?.event_type === "VIRTUAL" ? "#257CEA" : "#12A615",
        extendedProps: {
          description:
            serviceOrderStatus && serviceOrderStatus?.length > 0
              ? serviceOrderStatus[0]?.service_type?.name
              : "NO-AVAILABLE",
          patient: event?.patient?.firstName + " " + event?.patient?.lastname,
          created: event?.created_at,
          date_of_appointment: startDate.utc().format("yy-MM-DD HH:mm"),
          duration:
            serviceOrderStatus && serviceOrderStatus?.length > 0
              ? serviceOrderStatus[0]?.service_type?.duration_minute
              : "NO-AVAILABLE",
          provider: event?.provider?.firstName + " " + event?.provider?.lastname,
          patientImage:
            event?.patient?.user?.user_images?.length > 0
              ? event?.patient?.user?.user_images?.at(-1)?.path_resource
              : "",
          apptStatus: event?.status_appointment?.description,
          apptStatusCode: event?.status_appointment?.appt_status_code,
          apptId: event?.id,
          paymentStatus:
            serviceOrderStatus && serviceOrderStatus?.length > 0
              ? serviceOrderStatus[0]?.status
              : "NO-AVAILABLE",
          apptReason: event?.appt_reason,
          orderId:
            serviceOrderStatus && serviceOrderStatus?.length > 0
              ? serviceOrderStatus[0]?.id
              : "NO-AVAILABLE",
        },
      };
    });
    setEvents(totalEvents);
  };

  const handleAddButton = () => {
    history.push("/admin/newAppointment");
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBtnStatus(false);
    setNewPrice("");
  };

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const handleChangeStatus = (newStatus) => {
    console.log("confirm");
    if (newStatus === apptStatusCode)
      return swal(
        "Warning",
        t(
          "You cannot set the same Status, please contact support for more details"
        ),
        "warning"
      );
    if (appointmentId) {
      setLoading(true);

      Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/appointments/updateStatus`,
        {
          id: appointmentId,
          appt_status_code: newStatus,
          providerName:
            localState[0]?.extendedData?.firstName +
            " " +
            localState[0]?.extendedData?.lastname,
          patientName,
        },
        {
          headers: {
            Authorization: "Bearer " + jwtSession,
          },
        }
      )
        .then((res) => {
          setLoading(false);
          swal(t("Success!"), t("Appointment status Changed"), "success");
        })
        .catch((err) => {
          setLoading(false);
          swal(
            "Error!",
            t("Something went wrong, please contact the Admin ") + err,
            "error"
          );
          console.log(err);
        });
    }
  };

  const handleUpdatePrice = () => {
    if (newPrice && appointmentId) {
      setLoading(true);
      Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/services/updatePrice`,
        {
          appointment_id: appointmentId,
          price_total: newPrice,
          orderId: orderId,
        },
        {
          headers: {
            Authorization: "Bearer " + jwtSession,
          },
        }
      )
        .then((res) => {
          setLoading(false);
          setBtnStatus(!btnStatus);
          swal(
            t("Success!"),
            t("Price For this Appointment was Changed"),
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          swal(
            "Error!",
            t("Something went wrong, please contact the Admin ") + err,
            "error"
          );
          console.log(err);
        });
    } else {
      swal("Error!", t("Please type New price"), "error");
    }
  };

  // Method for sending payment reminder
  const handleReminder = () => {
    if (paymentStatus.toUpperCase() !== "COMPLETED") {
      Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/payments/requestPay`,
        {
          appointment_id: appointmentId,
          orderId: orderId,
        },
        {
          headers: { Authorization: "Bearer " + jwtSession }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          swal(t("Success!"), t("Reminder Sent to the Patient"), "success");
        })
        .catch((error) => {
          console.log(error);
          swal(
            "Error!",
            t(
              "Something's Wrong Retrieving the Appointment details, please contact Support"
            ),
            "warning"
          );
        });
    } else {
      swal(
        "Error!",
        t("Appointment was payed by the Patient, cannot send an reminder"),
        "warning"
      );
    }
  };

  // Handle the end date of appointment
  const handleDateEnd = () => {
    const newDate = Date.parse(appt_date_start_rescheduled);
    const time = parseInt(15) * 60000; //TODO: change the default value for real duration
    const end = new Date(newDate + time);
    const newEnd = moment(dateToString(end)).format("yyyy-MM-DDTHH:mm:ss");
    setAppt_date_end(newEnd);
  };

  /** Method fot handling the reschedule of appointment */
  const handleReschedule = () => {
    if (appointmentDate === setAppt_date_start_rescheduled) {
      return swal("Warning", t("Please select a different Date"), "warning");
    }

    if (appointmentId) {
      setLoading(true);

      Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/appointments/updateAppointment`,
        {
          id: appointmentId,
          appt_date_start: appt_date_start_rescheduled,
          appt_date_end: appt_date_end,
          appt_status_code: "CAPPT",
        },
        {
          headers: {
            Authorization: "Bearer " + jwtSession,
          },
        }
      )
        .then((res) => {
          setLoading(false);
          setAppt_date_start_rescheduled("");
          swal(t("Success!"), t("Appointment Re-scheduled"), "success");
        })
        .catch((err) => {
          setLoading(false);
          swal(
            "Error!",
            t("Something went wrong, please contact the Admin ") + err,
            "error"
          );
          console.log(err);
        });
    }
  };

  return (
    <DashboardMui
      sectionTitle={t("Appointments")}
      description={t(
        "Here you have a complete view of your schedule, your previous and next consultations, can book new appointments and manage a single consultation."
      )}
      icon={scheduleIcon}
    >
      <div className="maincontainer">
        <div className="button d-flex justify-content-center col-md-12">
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddButton}
          >
            {t("New Appointment")}
          </Button>
        </div>
        <div className="bg-white p-3 rounded">
          <FullCalendar
            plugins={[
              dayGridPlugin,
              interactionPlugin,
              listPlugin,
              bootstrapPlugin,
              timeGridPlugin,
            ]}
            initialView="timeGridWeek"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
            }}
            editable="true"
            // theme="true"
            themeSystem="standard"
            slotMinTime="06:00:00"
            slotDuration="00:15:00"
            eventClick={function (arg) {
              handleOpen();
              setPatientName(arg.event.extendedProps.patient);
              setCreationDate(
                new Date(arg.event.extendedProps.created).toLocaleDateString(
                  "en-US",
                  options
                )
              );
              setAppointmentDate(
                new Date(
                  arg.event.extendedProps.date_of_appointment
                ).toLocaleDateString("en-US", options)
              );
              setAppointmentDuration(arg.event.extendedProps.duration);
              setAppointmentProvider(arg.event.extendedProps.provider);
              setPatientImage(arg.event.extendedProps.patientImage);
              setApptStatus(arg.event.extendedProps.apptStatus);
              setApptStatusCode(arg.event.extendedProps.apptStatusCode);
              setAppointmentId(arg.event.extendedProps.apptId);
              setPaymentStatus(arg.event.extendedProps.paymentStatus);
              setAppointmentReason(arg.event.extendedProps.apptReason);
              setOrderId(arg.event.extendedProps.orderId);
            }}
            events={events}
          />
        </div>

        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={classes.paper}>
                <img
                  src={
                    patientImage
                      ? resizeByLink(patientImage, "upload")
                      : dummyProfile
                  }
                  alt="avatar"
                  className="rounded-circle img-fluid row mx-auto w-30-custom"
                />
                <h2 id="transition-modal-title">{patientName}</h2>
                <p id="transition-modal-element">
                  {t("Appointment due to")}: <b>{appointmentDate}</b>
                </p>
                <p id="transition-modal-description">
                  {t("Service Duration")}: <b>{appointmentDuration}</b>{" "}
                  <b>{t("Minutes")}</b>
                </p>
                <p id="transition-modal-status">
                  Status:{" "}
                  <span
                    className={`font-weight-bold ${
                      apptStatusCode
                        ? APPT_STATUS_CODES[apptStatusCode]
                        : "text-warning"
                    }`}
                  >
                    {t(apptStatus).toUpperCase()}
                  </span>
                </p>
                {/* Payment Status */}
                <p className="m-3">
                  <span className="text-label">{t("Payment Status")}: </span>
                  <span className="text-span-value">
                    {props.paymentStatus?.toUpperCase() === "COMPLETED" ? (
                      <FaCheckDouble className="paymentIcon-completed" />
                    ) : (
                      <FaUserClock className="paymentIcon-await" />
                    )}
                  </span>
                </p>
                <div className="my-3">
                  <span>
                    <b>{t("Appointment reason")}:</b>
                  </span>
                  <Alert severity="success">
                    {appointmentReason || t("No reason provided")}
                  </Alert>
                </div>
                {/* Control to change Service Price */}

                <div className="form-group justify-content-center p-3">
                  <fieldset className="fieldset row mb-2">
                    <h3 className="label-new-service">
                      {t("Modify Appointment")}:{" "}
                    </h3>
                    <div className="col">
                      <div className="row">
                        <div className="col-6">
                          <TextField
                            id="input-price"
                            className="mt-2"
                            label={t("Price") + " ($)"}
                            variant="outlined"
                            value={newPrice}
                            onChange={(e) => setNewPrice(e.target.value)}
                          />
                        </div>
                        <div className="col-6">
                          <Button
                            className="mt-3"
                            variant="contained"
                            color="secondary"
                            startIcon={<CheckCircleOutlineIcon />}
                            onClick={handleUpdatePrice}
                            disabled={
                              paymentStatus.toUpperCase() === "COMPLETED"
                                ? true
                                : btnStatus
                            }
                          >
                            {t("Save")}
                          </Button>
                        </div>
                      </div>
                      {/* Fin Control change Service Price*/}

                      {/* Reschedule the Appointment */}
                      <div className="row mt-3">
                        <div className="col-6">
                          <TextField
                            id="datetime-local"
                            label={t("Re-scheduled date")}
                            type="datetime-local"
                            variant="outlined"
                            defaultValue={appt_date_start_rescheduled}
                            value={appt_date_start_rescheduled}
                            className={classes.textField}
                            onChange={(event) => {
                              setAppt_date_start_rescheduled(
                                event.target.value
                              );
                              handleDateEnd();
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              min: moment().format("YYYY-MM-DD"),
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <Button
                            className="mt-2 ml-3"
                            variant="contained"
                            color="secondary"
                            startIcon={<CheckCircleOutlineIcon />}
                            onClick={handleReschedule}
                            disabled={appt_date_start_rescheduled === ""}
                          >
                            {t("re-schedule")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div className="modal-footer">
                  <Tooltip title={t("Confirm")}>
                    <IconButton
                      aria-label="confirm"
                      onClick={() => handleChangeStatus("CAPPT")}
                    >
                      <EventAvailableIcon style={{ color: "green" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("Cancel")}>
                    <IconButton
                      aria-label="cancel"
                      onClick={() => handleChangeStatus("CCAPPT")}
                    >
                      <EventBusyIcon style={{ color: "red" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("Payment Reminder")} arrow>
                    <IconButton aria-label="cancel" onClick={handleReminder}>
                      <TouchAppIcon style={{ color: "#3d73c3" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("Concluded")} arrow>
                    <IconButton
                      aria-label="cancel"
                      onClick={() => handleChangeStatus("CDAPPT")}
                    >
                      <OfflinePinIcon style={{ color: "#17a2b8" }} />
                    </IconButton>
                  </Tooltip>
                  {/* <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleClose}
                    >
                      Edit Appointment
                    </button> */}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={handleClose}
                  >
                    {t("Close")}
                  </button>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
      </div>
    </DashboardMui>
  );
}

export default MyCalendar;
