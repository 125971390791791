const userUpdateAffiliateReducer = (state = {}, action) => {
    switch (action.type) {
        case "SET_AFFILIATE_ID":
            if (action.payload) {
                // take the user from session storage
                const localState = JSON.parse(
                    window.sessionStorage.getItem("userState")
                );
                // clone the user object and add new element to user_images array with the new image
                const newUser = {
                    ...localState.user,
                    affiliate_id: action.payload,
                };
                state.user = newUser;
                localState.user = newUser;
                window.sessionStorage.setItem("userState", JSON.stringify(localState));
                return state;
            }
            break;
        default:
            return state;
    }
}

export default userUpdateAffiliateReducer;