import React, { useEffect, useState } from "react";
import Jitsi from "react-jitsi";
import Loading from "../utils/Loading";
import { useHistory, useParams } from "react-router-dom";

export default function SoleVideoFrameV2() {
  const [onCall, setOnCall] = useState(false);
  const [redirectOnLeft, setredirectOnLeft] = useState(false);
  let history = useHistory();
  const { id, type } = useParams();

  const onJitsiAPIReady = (externalApi) => {
    console.log("Jitsi API loaded", externalApi);
    externalApi.autoJoinEnabled = true;
    externalApi.executeCommand("allow", "camera; microphone");
    externalApi.on("videoConferenceLeft", () => {
      setredirectOnLeft(true);
      console.log("Left");
    });
  };

  useEffect(() => {
    if (redirectOnLeft) {
      setTimeout(() => {
        history.push("/patient/login");
      }, 500);
    }

    // clear timeout in case the component is unmounted before the timeout is reached
    return () => clearTimeout();
  }, [redirectOnLeft]);

  useEffect(() => {
    setOnCall(true);
  }, [onCall]);

  return onCall ? (
    <div style={{ width: "100%", height: "100vh" }}>
      <Jitsi
        config={{ prejoinPageEnabled: false, disableDeepLinking: true }}
        appId={process.env.REACT_APP_JITSI_APP_ID}
        roomName={id}
        onAPILoad={onJitsiAPIReady}
        userInfo={{
          displayName: type === "p" ? "Patient" : "Doctor",
        }}
        interfaceConfig={{
          SHOW_JITSI_WATERMARK: false,
          SHOW_DEEP_LINKING_IMAGE: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
          SHOW_PROMOTIONAL_CLOSE_PAGE: false,
          SHOW_JITSI_PROMO: false,
        }}
        domain="meets.hcgconsulting.net"
        onIframeLoad={() => console.log("frame loaded")}
      />
    </div>
  ) : (
    <>
      <Loading color="#3788d8d4" />
    </>
  );
}
