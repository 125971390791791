import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";

export default function CardMain({title, children}) {
  return (
    <Card
      variant="outlined"
      className="m-2 w-100 card-higthlights rounded-md-custom"
    >
      <CardContent>
        <Typography
          className="text-md-left font-weight-bolder"
          color="textPrimary"
          gutterBottom
        >
          {title}
        </Typography>
        <div className="mt-2">{children}</div>
      </CardContent>
    </Card>
  );
}
