import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import LoadingSpin from "../utils/Loading";
import { useHistory, useParams } from "react-router-dom";
import PatientProfile from "./PatientProfile";
import "../../styles/customs.css";
import swal from "sweetalert";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { createMuiTheme } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

//Redux
import { setUserImageProfile } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import useUserState from "../hooks/useUserState";

function VerificationStep() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [identification, setIdentification] = useState("");
  const [identificationType, setIdentificationType] = useState("");
  const person_type = "PAT";
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(true);
  const [country, setCountry] = useState("");
  const [nationality, setNationality] = useState("");
  const [patientId, setPatientId] = useState("");
  const [password, setPassword] = useState("");
  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const [countries, setCountries] = useState([]);
  const [insuranceProvider, setInsuranceProvider] = useState("");
  const [insurancePolicyNumber, setInsurancePolicyNumber] = useState("");

  const [data, setData] = useState("");
  const [jwtExpired, setJwtExpired] = useState("");
  const [openAlertImg, setOpenAlertImg] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    visible: false,
    msgClass: "",
  });
  //jwt received in the email an placed on uri
  let { id } = useParams();

  //Distpach of Redux
  const distpach = useDispatch();

  //Extract the userState to Redux Glabal State
  const userState = useUserState[0];

  const newUserImageState = useSelector((state) => state.newUserImage);

  const clearForm = () => {
    document.getElementById("patient-form").reset();
    setDateOfBirth("");
    setEmail("");
    setGender("");
    setLastname("");
    setfirstName("");
    setPhone("");
  };

  // -------------------------PASSWORD----------------------------

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePaswword = (e) => {
    if (password != "") {
      Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/userPatient/changePassword`,
        {
          token: id,
          newPassword: password,
        },
        {
          headers: { Authorization: "Bearer " + id },
        }
      )
        .then((res) => {
          swal({
            title: "Success!",
            text: "Password changed, select ok to proceed",
            icon: "success",
            button: true,
          }).then((resOk) => {
            if (resOk) {
              handleClick();
            }
          });
          console.log("password changed");
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: "Failed!",
            text: "Error changing password",
            icon: "error",
            button: false,
          });
        });
    }
  };

  // -------------------------- ALERT -----------------------------

  const handleAlertImg = () => {
    setOpenAlertImg(true);
  };

  const handleCloseAlertImg = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlertImg(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  // -------------------------- IMAGE UPDATE CLOUDINARY --------------------

  const handleSaveProfileImg = (e) => {
    // console.log(userProfileImg.profileImg);
    if (newUserImageState.newProfileImg != undefined) {
      let formData = new FormData();
      formData.append("file_name", `${firstName}ProfileImg`);
      formData.append("created_by", firstName);
      formData.append("user_email", email);
      formData.append("file", newUserImageState.newProfileImg);
      console.log(formData);

      Axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL_BASE}/userImage/save`,
        data: formData,
        headers: {
          Authorization: "Bearer " + id,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          console.log(response);
          handleAlertImg();
          //Save response to Global State
          distpach(setUserImageProfile(response.data.dataSaved));
        })
        .catch(function (error) {
          //handle error
          console.log(error);
          swal(
            "Error!",
            "Something went wrong, please contact the Admin " + error,
            "error"
          );
        });
    } else {
      console.log("no hay imagen seleccionada");
    }
  };

  // ----------------------- PROFILE UPDATE -------------------------

  const handleClick = (e) => {
    // e.preventDefault();
    debugger;
    if (
      email &&
      phone &&
      firstName &&
      lastname &&
      dateOfBirth &&
      gender &&
      patientId &&
      identification &&
      insurancePolicyNumber &&
      insurancePolicyNumber
    ) {
      setLoading(true);
      Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/userPatient/updateAndConfirm`,
        {
          email,
          phone1: phone,
          firstName,
          lastname,
          dateOfBirth,
          gender,
          identificationType,
          person_type,
          identification,
          country,
          nationality,
          id: patientId,
          insurance_policy_number: insurancePolicyNumber,
          insurance_provider: insuranceProvider,
        },
        {
          headers: { Authorization: "Bearer " + id }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          setLoading(false);
          console.log("RESPONSE", response);
          handleSaveProfileImg();

          swal({
            tittle: "Success!",
            text: "Account Verified, Now you can Book Appointments",
            icon: "success",
            button: "Login",
          }).then((resOk) => {
            if (resOk) {
              history.push(`/patient/login`);
            }
          });
          setErrorMessage({
            error: "",
            visible: false,
            msgClass: "",
          });
          //clearForm();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          setErrorMessage({
            error: error,
            visible: true,
            msgClass: "alert alert-danger d-flex justify-content-center",
          });
        });
    } else {
      setErrorMessage({
        error: "Error, Please fill the Form",
        visible: true,
        msgClass: "alert alert-danger ",
      });
    }
  };

  // -------------------- USEEFFECT ----------------------------

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/userPatient/${id}`)
      .then((res) => {
        setLoading(false);
        setData(res.data);
        //Set Data to Controls
        loadDataToControls(res.data);
        findImgByEmail(res.data[0].email);
        findCountries();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  // ------------------ FIND IMAGE CLOUDINARY ------------------

  const findImgByEmail = (email) => {
    // if(email != ""){
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/userImage/findByEmail`, {
      headers: {
        Authorization: "Bearer " + id,
        "Content-Type": "application/json",
      },
      params: { user_email: email },
    })
      .then((res) => {
        setImgLoading(false);
        distpach(
          setUserImageProfile({
            profileImg: res.data[0].path_resource,
            exists: true,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        setImgLoading(false);
        // changeUserProfileImg({ profileImg: "", exists: false });
        distpach(setUserImageProfile({ profileImg: "", exists: false }));
        setJwtExpired(err.response.status);
      });
  };

  // ----------------------- STYLES CLASSES --------------------

  const useStyles = makeStyles((theme) => ({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(2),
    },
    textField: {
      width: "100%",
    },
  }));

  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: "#e33371",
      },
    },
  });
  const classes = useStyles();

  // ----------------------  FUNC SET STATES --------------------

  function loadDataToControls(data) {
    if (data) {
      const {
        email,
        firstName,
        lastname,
        identification,
        dateOfBirth,
        gender,
        country,
        nationality,
        id,
        phone1,
        identificationType,
        insurance_policy_number,
        insurance_provider,
      } = data[0];
      setEmail(email);
      setfirstName(firstName);
      setLastname(lastname);
      setIdentification(identification);
      setDateOfBirth(dateOfBirth && dateOfBirth.split("T")[0]);
      setGender(gender);
      setCountry(country);
      setNationality(nationality);
      setPatientId(id);
      setPhone(phone1);
      setIdentificationType(identificationType);
      setInsurancePolicyNumber(insurance_policy_number);
      setInsuranceProvider(insurance_provider);
    }
  }

  //-----------------JWT EXPIRED -----------------------

  const handleJwtExpired = (e) => {
    swal({
      tittle: "Failed!",
      text: "Link expired, please contact suport",
      icon: "error",
      button: "Suport",
      closeOnClickOutside: false,
    }).then((resOk) => {
      if (resOk) {
        history.push(`/patient/login`);
      }
    });
  };

  //------------------ HANDLE CONFIRM ------------------

  const handleConfirm = (e) => {
    handleClick(e);
    // if (password) {
    //   handlePaswword();
    // } else {
    // }
  };

  // ----------------- COUNTRIES ----------------------

  const findCountries = () => {
    // if(email != ""){
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/countries/findAll`)
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ----------------------  RETURN -------------------

  return (
    <>
      {jwtExpired == 401 ? (
        handleJwtExpired()
      ) : (
        <>
          <div className="shadow-lg p-3 mb-5 bg-white rounded">
            <div className="row d-flex justify-content-center">
              <div className="col-md-4 d-flex justify-content-center">
                <PatientProfile email={email} imgLoading={imgLoading} />
              </div>
              <div className="col-md-9">
                <form className="row" id="patient-form">
                  <div className="form-group col-md-6">
                    <TextField
                      margin="normal"
                      label="First Name"
                      variant="outlined"
                      id="firstName"
                      fullWidth
                      onChange={(e) => setfirstName(e.target.value)}
                      required
                      value={firstName || ""}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label="Last Name"
                      variant="outlined"
                      id="lastname"
                      onChange={(e) => setLastname(e.target.value)}
                      value={lastname || ""}
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <TextField
                      required
                      margin="normal"
                      label="Your Email"
                      variant="outlined"
                      id="email"
                      value={email || ""}
                      autoComplete="email"
                      fullWidth
                      placeholder="name@example.com"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-md-8">
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label="Phone Number"
                      variant="outlined"
                      id="phoneNumber"
                      value={phone || ""}
                      placeholder="888823456"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-md-4 ">
                    <TextField
                      id="country"
                      required
                      select
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      placeholder="Select"
                      label="Country"
                      value={country || "select"}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      {countries &&
                        countries.map((country, idx) => {
                          return (
                            <MenuItem key={idx} value={country.code}>
                              {country.name}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </div>
                  <div className="form-group col-md-3">
                    <TextField
                      id="identificationType"
                      required
                      select
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      placeholder="Select"
                      label="Identification Type"
                      onChange={(e) => setIdentificationType(e.target.value)}
                      value={identificationType || "select"}
                    >
                      <MenuItem value="DNI">DNI</MenuItem>
                      <MenuItem value="PASSPORT">Passport</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </TextField>
                  </div>

                  <div className="form-group col-md-9">
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label="Identification/DNI"
                      variant="outlined"
                      id="identification"
                      onChange={(e) => setIdentification(e.target.value)}
                      value={identification || ""}
                    />
                  </div>

                  <div className="form-group col-md-4">
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      label="Date Of Birth"
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      value={dateOfBirth || ""}
                    />
                  </div>
                  <div className="form-group col-md-8">
                    <FormControl variant="outlined" className={classes.form}>
                      <InputLabel required id="gender">
                        Gender
                      </InputLabel>
                      <Select
                        labelId="gender"
                        id="gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        label="Gender"
                      >
                        <MenuItem value={""}>None</MenuItem>
                        <MenuItem value={"MALE"}>Male</MenuItem>
                        <MenuItem value={"FEMALE"}>Female</MenuItem>
                        <MenuItem value={"OTHER"}>Other</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {/* Insurance Information */}
                  <div className="form-group col-md-6">
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label="Insurance Provider"
                      variant="outlined"
                      id="insurance-provider"
                      onChange={(e) => setInsuranceProvider(e.target.value)}
                      value={insuranceProvider || ""}
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label="Insurance Policy Number"
                      variant="outlined"
                      id="insurance-policy-number"
                      onChange={(e) => setInsurancePolicyNumber(e.target.value)}
                      value={insurancePolicyNumber || ""}
                    />
                  </div>

                  {/* End Insurance Information */}
                  {/* <div className="form-group col-md-12">
                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={values.showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        labelWidth={110}
                      />
                    </FormControl>
                  </div> */}
                  <div className="col-md-12 mt-3 d-flex justify-content-center ">
                    {errorMessage.visible && (
                      <div className={errorMessage.msgClass} role="alert">
                        {errorMessage.error}
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center ">
              <div className="p-3">
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<CheckCircleOutlineIcon />}
                  onClick={(e) => {
                    handleClick(e);
                    // handleConfirm(e);
                  }}
                >
                  Save
                </Button>
              </div>

              <div className="p-3">
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<CancelIcon />}
                  onClick={(e) => history.push(`/patient/dashboard`)}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <div className="col-md-12">
              {loading && (
                <div className="d-flex justify-content-center mt-3">
                  <LoadingSpin type="spokes" color="#1d3557" />
                </div>
              )}
            </div>
          </div>
          <Snackbar
            open={openAlertImg}
            autoHideDuration={5000}
            onClose={handleCloseAlertImg}
          >
            <Alert onClose={handleCloseAlertImg} severity="success">
              Success Profile Image Updated
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
}

export default VerificationStep;
